/**
 * I don't understand from MASTERDATA:TOGGLE_ which part of the UI reacts to
 * this.
 */
export function toggleItemAction(treeId, value) {
  return (dispatch) => {
    dispatch({
      type: 'MASTERDATA:TOGGLE',
      value: { key: treeId.toUpperCase(), value },
    })
  }
}
