;(() => {
  if (typeof window === 'undefined' || window.saveAs) {
    return
  }

  if (window.navigator.msSaveBlob) {
    window.saveAs = window.navigator.msSaveBlob
  } else if (window.webkitSaveAs) {
    window.saveAs = window.webkitSaveAs
  } else if (window.mozSaveAs) {
    window.saveAs = window.mozSaveAs
  } else if (window.msSaveAs) {
    window.saveAs = window.msSaveAs
  } else {
    window.saveAs = (() => {
      window.URL = window.URL || window.webkitURL

      if (!window.URL) {
        return false
      }

      return (blob, name) => {
        const url = URL.createObjectURL(blob)

        if ('download' in document.createElement('a')) {
          const a = document.createElement('a')
          a.setAttribute('href', url)
          a.setAttribute('download', name)

          const clickEvent = document.createEvent('MouseEvent')
          clickEvent.initMouseEvent(
            'click',
            true,
            true,
            window,
            0,
            clickEvent.screenX,
            clickEvent.screenY,
            clickEvent.clientX,
            clickEvent.clientY,
            clickEvent.ctrlKey,
            clickEvent.altKey,
            clickEvent.shiftKey,
            clickEvent.metaKey,
            0,
            null
          )

          a.dispatchEvent(clickEvent)
        } else {
          window.open(url, '_blank', '')
        }
      }
    })()
  }
})()
