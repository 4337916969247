/**
 * Uses the saved page to redirect to after a login.
 */
export function loginRedirectUseAction() {
  return (dispatch) => {
    dispatch({
      type: 'USER:LOGIN_REDIRECT_USE',
    })
  }
}
