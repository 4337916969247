import React from 'react'
import PropTypes from 'prop-types'
import registry from 'services/registry'
import ImmutablePropTypes from 'react-immutable-proptypes'

export class ButtonLabelList extends React.Component {
  render() {
    const getLabel = registry.get('helpers/masterdata/getLabel')
    const list = this.props.list

    if (list.size === 0 || (list.size === 1 && list.has('All'))) {
      return <span>{this.props.defaultLabel}</span>
    }
    const labels = list
      .map((item) => {
        return getLabel(this.props.type, item, this.props.lang || 'EN')
          .shortText
      })
      .toJS()

    return (
      <div style={{ fontSize: '70%' }}>
        <div>{this.props.defaultLabel}</div>
        <div
          style={{
            width: `${this.props.width}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <strong>{labels.join(', ')}</strong>
        </div>
      </div>
    )
  }
}

ButtonLabelList.propTypes = {
  defaultLabel: PropTypes.string,
  lang: PropTypes.string,
  list: ImmutablePropTypes.set.isRequired,
  maxLabel: PropTypes.number,
  width: PropTypes.number,
  type: PropTypes.string.isRequired,
}

ButtonLabelList.defaultProps = {
  defaultLabel: '',
  lang: 'EN',
  maxLabel: 4,
}
