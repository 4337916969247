import { store } from 'services/store'
import recursiveFilter from './recursiveFilter'
import nonRecursiveFilter from './nonRecursiveFilter'
import rangeFilter from './rangeFilter'

const mapper = {
  nonRecursive: nonRecursiveFilter,
  recursive: recursiveFilter,
}

const dateTypes = ['year', 'month', 'quarter']

// TODO This is where the filters are crated

export function createFilters(struct, global, local, allowedFilters = []) {
  const returnFilterStruct = {}

  struct.forEach((filter) => {
    if (allowedFilters.length === 0 || allowedFilters.includes(filter.name)) {
      returnFilterStruct[filter.name] = mapper[filter.type](
        filter.name,
        global,
        local
      )
    }
  })

  if (!local) {
    const state = store.getState()
    const facts = state.dashboard.fact.toJS()

    dateTypes.forEach((item) => {
      if (!facts.filters) return
      const foundFilter = Object.values(facts.filters).find(
        (el) => el.type === item
      )
      if (foundFilter && foundFilter.id) {
        returnFilterStruct[foundFilter.id] = foundFilter
      }
    })
  } else {
    dateTypes.forEach((item) => {
      const foundFilter = Object.values(global.toJS()).find(
        (el) => el.type === item
      )
      if (foundFilter && foundFilter.id) {
        returnFilterStruct[foundFilter.id] = foundFilter
      }
    })
  }
  returnFilterStruct.RANGE = rangeFilter(global, local)

  return returnFilterStruct
}
