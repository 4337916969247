import { connect } from 'react-redux'
import { store } from '../../../../store'

import {
  changeViewAction,
  editDetailsOpenAction,
  fetchAction,
  publishAction,
  removeAction,
  shareInfoCloseAction,
  shareInfoOpenAction,
  unpublishAction,
  updateAction,
} from '../../actions'

import {
  addCardAction,
  addTextCardAction,
  addCardCancelAction,
  exportExcelAction,
  fetchCardsAction,
  goToEdit,
  goToView,
  refreshAllCardsAction,
} from '../../actions/grid'

import _component from './component'

function mapStateToProps(state) {
  return {
    cards: state.dashboard.currentGrid.get('cards'),
    usedRows: state.dashboard.currentGrid.get('usedRows'),
    currentView: state.dashboard.current.get('currentView'),
    dashboard: state.dashboard.current.get('dashboard').toJS(),
    editModalFormErrors: state.dashboard.current
      .get('editModalFormErrors')
      .toJS(),
    forceCurrentView: state.dashboard.current.get('forceCurrentView'),
    grid: state.dashboard.currentGrid.get('grid').toJS(),
    isAddingCard: state.dashboard.currentGrid.get('isAddingCard'),
    isFetched: state.dashboard.current.get('isFetched'),
    isFetching: state.dashboard.current.get('isFetching'),
    isGridFetchingCards: state.dashboard.currentGrid.get('isFetchingCards'),
    isGridInit: state.dashboard.currentGrid.get('isInit'),
    isOnEdit: state.dashboard.currentGrid.get('isOnEdit'),
    isPublishing: state.dashboard.currentGrid.get('isPublishing'),
    isShareInfoOpened: state.dashboard.current.get('isShareInfoOpened'),
    isUnpublishing: state.dashboard.currentGrid.get('isUnpublishing'),
    prog: state.dashboard.currentGrid.get('prog'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onEditDetailsCancel: () => dispatch({ type: 'TEXT_EDITOR:CLOSE' }),
    onEditDetailsOpen: (dashboard) =>
      dispatch(editDetailsOpenAction(dashboard)),
    onEditDetailsSave: (id) => dispatch(updateAction(id)),
    fetchCards: (id) => dispatch(fetchCardsAction(id)),
    fetchItem: (id) => dispatch(fetchAction(id)),
    goToEdit: (idDashboard) => dispatch(goToEdit(idDashboard)),
    goToView: (idDashboard) => dispatch(goToView(idDashboard)),
    // navigateToDashboardsEdit: (idDashboard) =>
    //   dispatch(routeActions.push(`/dashboards/${idDashboard}/edit`, {})),
    navigateToDashboardsEdit: (idDashboard) =>
      store.history.push(`/dashboards/${idDashboard}/edit`),
    onAddCard: (idDashboard, idChart) =>
      dispatch(addCardAction(idDashboard, idChart)),
    onAddTextCard: (idDashboard) => dispatch(addTextCardAction(idDashboard)),
    onAddCardCancel: () => dispatch(addCardCancelAction()),
    onChangeView: (newView) => dispatch(changeViewAction(newView)),
    onExportExcel: (idDashboard) => dispatch(exportExcelAction(idDashboard)),
    onPublish: (id) => dispatch(publishAction(id)),
    onUnpublish: (id) => dispatch(unpublishAction(id)),
    refreshAllCards: () => dispatch(refreshAllCardsAction()),
    // removeEntry: (id) => dispatch(removeAction(id, routeActions.push('/dashboards'))),
    removeEntry: (id) =>
      dispatch(removeAction(id, null, () => store.history.push(`/dashboards`))),
    shareInfoClose: () => dispatch(shareInfoCloseAction()),
    shareInfoOpen: () => dispatch(shareInfoOpenAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
