import config from 'config'
import request from 'services/request'
import { store } from 'services/store'
import { refreshCardAction } from '../grid/refreshCard'
import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Closes the modal of the filters of the current dashboard.
 */
export function closeAndSaveAction(cardId, panelId, type) {
  return (dispatch) => {
    if (type === 'range') {
      dispatch({
        type: 'DASHBOARDS:CURRENT_GRID:UPDATE_CARD_RANGE_FILTERS',
        name: panelId,
        cardId,
        filters: store.getState().filters.range,
      })
    } else {
      dispatch({
        type: 'DASHBOARDS:CURRENT_GRID:UPDATE_CARD_FILTERS',
        name: panelId,
        cardId,
        filters: store.getState().filters.hierarchy,
      })
    }
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_FILTER_POPUP',
      cardId,
      show: null,
    })

    const { card } = getCardAndIndexFromCardList(
      store.getState().dashboard.currentGrid.get('cards'),
      cardId
    )
    if (!card) {
      console.log('Card not found, ', cardId)
    }

    const data = card.get('filters').toJS()
    const dashboardId = store
      .getState()
      .dashboard.current.getIn(['dashboard', 'id'])

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')
    const url = `${config.server.url}dashboard/${dashboardId}/card/${cardId}/filters?auth_token=${token}`

    request
      .put(url, data, { responseType: 'json', dataType: 'json' })
      .then(() => {})
      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:UPDATE:FAIL',
          ...result.response,
        })
      })

    dispatch(refreshCardAction(card))
  }
}
