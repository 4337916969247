import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { PencilIcon } from '@primer/octicons-react'

export class DropDownEdit extends React.Component {
  render() {
    if (!this.props.show) {
      return false
    }

    const editItems = [
      <Dropdown.Item key="2" eventKey="2" onSelect={this.props.onEditDashboard}>
        Edit dashboard
      </Dropdown.Item>,

      <Dropdown.Item key="3" divider />,

      <Dropdown.Item
        key="4"
        eventKey="1"
        onSelect={this.props.onRemoveDashboard}
      >
        Remove dashboard
      </Dropdown.Item>,
    ]

    return (
      <Dropdown id="edit" size="sm">
        <Dropdown.Toggle>
          <PencilIcon /> Edit
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          {editItems.filter((item) => item !== null)}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default DropDownEdit
