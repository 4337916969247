import React from 'react'
import registry from 'services/registry'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { serverConst } from 'services/server'

import FactShow from 'modules/dashboard/components/fact'
import { ArrowLeftIcon } from '@primer/octicons-react'
import Resizer from '../../widgets/resizer'
function mapStateToProps(state) {
  return {
    resizerWidth: state.app.getIn(['resizer', 'mainContainer']),
    resizeWindowProg: state.app.get('resizeWindowProg'),
  }
}

const FactsShowPage = (props) => {
  const { id } = useParams()
  if (serverConst.deviceType === 'phone') {
    const size = registry.get('helpers/metrics/getWindowSize')()
    const componentWidth = size.width - 30
    return (
      <Resizer resizeKey="mainContainer">
        <div style={{ width: componentWidth + 'px', margin: '0 auto' }}>
          <Link to="/facts">
            <Button variant="link" size="sm">
              {/*<Glyphicon glyph="arrow-left"/> */}
              Fact list
            </Button>
          </Link>
          <FactShow id={id} totalWidth={componentWidth} />
        </div>
      </Resizer>
    )
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md={12}>
            <Link to="/facts">
              <Button variant="link" size="sm">
                <ArrowLeftIcon />
                Fact list
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Resizer>
              <FactShow id={id} />
            </Resizer>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default connect(mapStateToProps, null)(FactsShowPage)
