import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

import {
  getUsedCellsByCard,
  getNewPositionForCard,
} from './../../helpers/cells'
import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Updates the position property of a card.
 */
export function setCardPositionAction(dashboardId, cardId, ui) {
  return (dispatch) => {
    const newX = ui.x
    const newY = ui.y
    const state = store.getState().dashboard.currentGrid

    const cardFound = getCardAndIndexFromCardList(state.get('cards'), cardId)
    if (cardFound.index < 0) {
      dispatch({ type: 'ERROR:CARD_NOT_FOUND', cardId })
      return
    }
    const { index, card } = cardFound
    const { newCellX, newCellY } = getNewPositionForCard(
      state.get('grid'),
      state.get('cards').get(index),
      newX,
      newY
    )

    const oldItemUsedCells = getUsedCellsByCard(card)
    const newItemUsedCells = getUsedCellsByCard(
      card.set('cellX', newCellX).set('cellY', newCellY)
    )

    if (
      state
        .get('usedCells')
        .subtract(oldItemUsedCells)
        .intersect(newItemUsedCells).size > 0
    ) {
      dispatch({ type: 'DASHBOARDS:CURRENT_GRID:REFRESH_GRID' })
      return
    }

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')
    const url = `${config.server.url}dashboard/${dashboardId}/card/${cardId}?auth_token=${token}`

    const newCardData = {
      cellX: newCellX,
      cellY: newCellY,
      spanX: card.get('spanX'),
      spanY: card.get('spanY'),
      customData: {
        chartId: card.getIn(['content', 'chartId']),
        filters: [],
      },
    }

    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_POSITION',
      cardId,
      newX: newCellX,
      newY: newCellY,
    })

    request
      .put(url, newCardData, { responseType: 'json', dataType: 'json' })
      .then(() => {
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_POSITION:SUCCESS',
          cardId,
          newX: newCellX,
          newY: newCellY,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
      })

      .catch((result) => {
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:ADD_CARD:FAIL',
          ...result.response,
          cardId,
          newX: card.get('cellX'),
          newY: card.get('cellY'),
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
      })
  }
}
