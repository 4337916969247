import React from 'react'
import { union } from 'lodash'
import registry from 'services/registry'
import { Button, ButtonGroup } from 'react-bootstrap'

import { serverConst } from 'services/server'

import CenteredSpinner from 'components/widgets/centeredSpinner'
import LineChart from 'components/widgets/charts/lineChart'
import MultiBarChart from 'components/widgets/charts/multiBarChart'
import HorizontalBarChart from 'components/widgets/charts/horizontalBarChart'
import TreemapChart from 'components/widgets/charts/treemapChart'
import TableChart from 'components/widgets/charts/tableChart'

import {
  getInformationItemById,
  getChartById,
} from 'modules/masterdata/helpers'
import ShareInfo from './shareInfo'
import Filters from './filters'

import useFactChartData from '../../../../hooks/useFactChartData'

const charts = {
  Line: LineChart,
  Table: TableChart,
  Column: MultiBarChart,
  Bar: HorizontalBarChart,
  Treemap: TreemapChart,
}

const FactShow = (props) => {
  const { isFetching, chartData } = useFactChartData(
    props.id,
    props.filtersStruct,
    props.factFilters
  )

  const handleSharedModalClose = (event) => {
    event.preventDefault()
    event.stopPropagation()

    props.shareInfoClose()
  }

  const handleSharedModalOpen = (event) => {
    event.preventDefault()
    event.stopPropagation()

    props.shareInfoOpen(props.id)
  }

  if (!props.totalWidth) {
    return false
  }

  const { id } = props

  const informationItem = getInformationItemById(id)

  if (!informationItem) {
    return false
  }

  const chart = Object.assign({}, getChartById(id), {
    chartType: informationItem.chartType,
  })
  const CurrentComponent = charts[informationItem.chartType]

  let content
  if (isFetching) {
    content = <CenteredSpinner show={true} />
  } else {
    const isPhone = serverConst.deviceType === 'phone'
    if (isPhone) {
      const size = registry.get('helpers/metrics/getWindowSize')()
      const cardHeight = size.height

      content = (
        <CurrentComponent
          id="singleFact"
          xTitle={informationItem.chartX.label}
          yTitle={informationItem.chartY.label}
          data={chartData}
          scalingFactor={informationItem.scalingFactor}
          height={cardHeight}
        />
      )
    } else {
      content = (
        <CurrentComponent
          id="singleFact"
          xTitle={informationItem.chartX.label}
          yTitle={informationItem.chartY.label}
          data={chartData}
          scalingFactor={informationItem.scalingFactor}
        />
      )
    }
  }

  const filters = union(
    informationItem.globalFilters,
    informationItem.localFilters
  )
  const params = [
    {
      type: 'FILTER',
      code: 'YEAR',
      value1: 'min',
      value2: '2000',
    },
    {
      type: 'FILTER',
      code: 'YEAR',
      value1: 'max',
      value2: '2025',
    },
  ]

  let shareInfo = false
  if (props.isSharedInfoOpened) {
    shareInfo = (
      <ShareInfo
        show={true}
        hash={props.shareKey}
        onClose={handleSharedModalClose}
      />
    )
  }

  return (
    <div>
      <ButtonGroup className="pull-right" size="sm">
        <Button onClick={handleSharedModalOpen}>Share this fact</Button>
      </ButtonGroup>
      <center>
        <h3>{chart.shortText}</h3>
      </center>
      {shareInfo}
      <Filters
        filtersStruct={props.filtersStruct}
        page={{ filters, params }}
        chartId={id}
        writeOnUpdate={false}
        width={props.totalWidth}
      />
      {content}
    </div>
  )
}

export default FactShow
