import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import LoginForm from 'modules/user/components/loginForm'

export class LoginPage extends React.Component {
  render() {
    return (
      <div className="mt-5">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={6} mdOffset={3}>
              <Card>
                <Card.Body>
                  <Card.Title>Login</Card.Title>
                  <Card.Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et.
                    <LoginForm />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
