import { union, remove, intersection } from 'lodash'
import { store } from 'services/store'
import { getChildIdList } from 'modules/masterdata/helpers'

/**
 * Returns the filter values.
 */
export default function recursiveFilter(filterName, global, local) {
  let globalFilters = []
  let localFilters = []

  if (global.getIn([filterName, 'selectedNodes']).count() > 0) {
    globalFilters = global.getIn([filterName, 'selectedNodes']).toJS()
    if (local) {
      localFilters = local.getIn([filterName, 'selectedNodes']).toJS()
    }
    if (local) {
      return intersection(globalFilters, localFilters)
    }
    return globalFilters
  }

  global
    .getIn([filterName, 'selectedChildren'])
    .toJS()
    .forEach((item) => {
      const startItem = store
        .getState()
        .masterdata.getIn(['hierarchiesFlat', filterName])
        .find((searchItem) => item === searchItem.id)
      if (startItem.childs > 0) {
        globalFilters = union(
          globalFilters,
          remove(
            getChildIdList(startItem),
            (element) => element === startItem.id
          )
        )
      } else {
        globalFilters = union(globalFilters, getChildIdList(startItem))
      }
    })

  if (local) {
    local
      .getIn([filterName, 'selectedNodes'])
      .toJS()
      .forEach((item) => {
        const startItem = store
          .getState()
          .masterdata.getIn(['hierarchiesFlat', filterName])
          .find((searchItem) => item === searchItem.id)
        if (startItem.childs > 0) {
          localFilters = union(
            localFilters,
            remove(
              getChildIdList(startItem),
              (element) => element === startItem.id
            )
          )
        } else {
          localFilters = union(localFilters, getChildIdList(startItem))
        }
      })
    return intersection(globalFilters, localFilters)
  }
  return globalFilters
}
