import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * Transforms the "master" data fetched from the server into a structure saved
 * in the store.
 */
export function initMasterdataAction() {
  return (dispatch) => {
    if (
      store.getState().masterdata.get('isInit') ||
      store.getState().masterdata.get('isFetching')
    ) {
      return
    }
    dispatch({ type: 'MASTERDATA:INIT_START' })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    const url = `${config.server.url}masterdata?auth_token=${token}`

    request
      .get(url, {}, { responseType: 'json' })
      .then((result) => {
        dispatch({
          type: 'MASTERDATA:INIT:SUCCESS',
          items: result.response,
        })
      })

      .catch((error) => {
        console.log(error)
        return dispatch({
          type: 'MASTERDATA:INIT:FAIL',
          ...error.response,
        })
      })
  }
}
