function Workbook() {
  if (!(this instanceof Workbook)) {
    return new Workbook()
  }
  this.SheetNames = []
  this.Sheets = {}
}

/**
 * Gets the workbook data for the XSLX export.
 */
export function getWorkbookForXslx() {
  return new Workbook()
}
