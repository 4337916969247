import request from 'services/request'
import config from 'config'
import cookie from 'react-cookie'
import { logoutAction } from 'modules/user/actions'

/**
 * Initializes the application.
 */
function initUser(dispatch) {
  const userToken = cookie.load('jwt_token')

  if (!userToken) {
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    dispatch({
      type: 'USER:VALIDATE_START',
    })

    const url = `${config.server.url}users/validate?auth_token=${userToken}`
    request
      .get(url)
      .then((result) => {
        dispatch({
          type: 'USER:VALIDATE:SUCCESS',
          email: result.response.user.email,
          firstName: result.response.user.firstName,
          lastName: result.response.user.lastName,
          username: result.response.user.username,
          roles: result.response.roles,
          token: userToken,
        })
        return resolve()
      })
      .catch((error) => {
        if (error.xhr && error.xhr.status === 401) {
          dispatch(logoutAction())
          resolve()
          return
        }
        // TODO Check the result and manage correctly the error;
        console.log(error.response)
        reject('Error')
      })
  })
}

export function initAppAction(dispatch) {
  return new Promise((resolve, reject) => {
    dispatch({
      type: 'APP:INIT_START',
    })

    Promise.all([initUser(dispatch)])
      .then(() => {
        dispatch({
          type: 'APP:INIT:SUCCESS',
        })
        resolve()
      })
      .catch((err) => {
        dispatch({
          type: 'APP:INIT:FAIL',
        })
        reject(err)
      })
  })
}
