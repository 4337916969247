import { store } from 'services/store'

/**
 * Gets one label by id.
 */
export function getLabel(type, id, lang) {
  const result = store
    .getState()
    .masterdata.get('labels')
    .get(`${type}_${id}_${lang}`.toLowerCase())

  return (
    result || {
      shortText: id,
      longText: id,
    }
  )
}
