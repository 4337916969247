import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import InformationItems from 'modules/masterdata/components/informationItems'
import { store } from '../../../store'

function mapStateToProps(state) {
  return {
    dashboardStore: state.dashboard,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    navigateToFactsShowPage: (id) => store.history.push(`/facts/${id}`),
  }
}

class FactsListPage extends React.Component {
  handleChartClick(id) {
    this.props.navigateToFactsShowPage(id)
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col md={12}>
              <InformationItems
                handleChartClick={this.handleChartClick.bind(this)}
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FactsListPage)
