import React from 'react'

import { serverConst } from 'services/server'

export default class DashboardListHeader extends React.Component {
  render() {
    let description = false
    if (!serverConst.deviceType === 'phone') {
      description = <th>Description</th>
    }
    return (
      <tr>
        <th>Title</th>
        {description}
        <th>Page</th>
        <th>Created</th>
      </tr>
    )
  }
}
