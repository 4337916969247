import React from 'react'
import PropTypes from 'prop-types'
import registry from 'services/registry'
import ImmutablePropTypes from 'react-immutable-proptypes'

import Range from 'components/widgets/range'
import getMetrics from './getMetrics'

import FilterModal from './filterModal'
import { ButtonHierarchyFilter } from './buttonHierarchyFilter'

const rangeTypes = [
  { id: 'YEAR', type: 'year' },
  { id: 'hum_last_engagement_year', type: 'year' },
  { id: 'MONTH', type: 'month' },
  { id: 'QUARTER', type: 'quarter' },
]

export class Filters extends React.Component {
  handleOpenHierarchy(idPanel) {
    this.props.showPanel(idPanel)
  }

  handleChangeRange(range) {
    this.props.changeRange(range)
  }

  handleOnClose(idPanel) {
    this.props.closeAndSave(idPanel)
  }

  handleOnCancel() {
    this.props.close()
  }

  render() {
    const filtersRangeStart = registry.get(
      'helpers/masterdata/filters/filtersRangeStart'
    )
    const filtersRangeStop = registry.get(
      'helpers/masterdata/filters/filtersRangeStop'
    )
    const page = this.props.page
    const filterBox = {
      clear: 'both',
      marginBottom: '0px',
      padding: '5px 0px 0px 0px',
      position: 'relative',
    }

    const requiredFilter = this.props.filtersStruct.find(
      (item) => item.name === this.props.show
    )

    let modal

    if (requiredFilter) {
      modal = (
        <FilterModal
          onClose={this.handleOnClose.bind(this, requiredFilter.name)}
          onCancel={this.handleOnCancel.bind(this)}
          enabledNodes={[]}
          type={requiredFilter.name}
          title={requiredFilter.modalTitle}
          isRecursive={['recursive'].includes(requiredFilter.type)}
        />
      )
    } else {
      modal = false
    }

    const filters = page.filters || []

    let countFilters = filters.filter((filterItem) =>
      this.props.filtersStruct.find(
        (structItem) =>
          structItem.name.toLowerCase() === filterItem.toLowerCase()
      )
    ).length
    let hasRangeFilter = false
    if (
      page.filters.includes('YEAR') ||
      page.filters.includes('QUARTER') ||
      page.filters.includes('MONTH')
    ) {
      hasRangeFilter = true
    }

    const metrics = getMetrics(this.props.width, countFilters, hasRangeFilter)

    const buttons = []
    filters.forEach((filterItem) => {
      const filterStruct = this.props.filtersStruct.find(
        (structItem) =>
          structItem.name.toLowerCase() === filterItem.toLowerCase()
      )
      if (!filterStruct) return

      buttons.push(
        <ButtonHierarchyFilter
          key={`${filterStruct.name}ButtonFilter`}
          onClick={this.handleOpenHierarchy.bind(this, filterStruct.name)}
          list={this.props.globalFilters.get(filterStruct.name)}
          type={filterStruct.name}
          width={metrics.buttonWidth}
          defaultLabel={filterStruct.shortTitle}
        />
      )
    })

    let separator = <div style={{ height: '5px', display: 'block' }}></div>
    if (!metrics.hasBreak) {
      separator = false
    }
    let ranges = []

    let rangeFiltersNum = 0
    for (const rangeType of rangeTypes) {
      if (page.filters.includes(rangeType.id)) {
        rangeFiltersNum++
      }
    }

    for (const rangeType of rangeTypes) {
      if (page.filters.includes(rangeType.id)) {
        ranges.push(
          <Range
            key={rangeType.id}
            id={rangeType.id}
            limitStart={filtersRangeStart(page.params, rangeType.id)}
            limitStop={filtersRangeStop(page.params, rangeType.id)}
            from={this.props.globalFilters.getIn(['range', 'from'])}
            to={this.props.globalFilters.getIn(['range', 'to'])}
            type={rangeType.type}
            onChange={this.handleChangeRange.bind(this)}
            width={Math.floor(this.props.width / rangeFiltersNum)}
          />
        )
      }
    }

    return (
      <div>
        {modal}
        <div style={filterBox} className="filter-box">
          {buttons.map((item) => item)}
          {separator}
          {ranges}
        </div>
      </div>
    )
  }
}

Filters.propTypes = {
  globalFilters: ImmutablePropTypes.map.isRequired,
  show: PropTypes.string,
  page: PropTypes.object.isRequired,
  showPanel: PropTypes.func.isRequired,
  changeRange: PropTypes.func.isRequired,
  closeAndSave: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default Filters
