import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

import { getUsedCellsByCard, getNewSizeForCard } from '../../helpers/cells'
import { getCardAndIndexFromCardList } from '../../helpers'

/**
 * Updates the size property of a card.
 */
export function setCardSizeAction(dashboardId, cardId, ui) {
  return (dispatch) => {
    const newWidth = ui.size.width
    const newHeight = ui.size.height
    const state = store.getState().dashboard.currentGrid

    const cardFound = getCardAndIndexFromCardList(state.get('cards'), cardId)
    if (cardFound.index < 0) {
      dispatch({ type: 'ERROR:CARD_NOT_FOUND', cardId })
      return
    }
    const { index, card } = cardFound

    const { newSpanX, newSpanY } = getNewSizeForCard(
      state.get('grid'),
      state.get('cards').get(index),
      newWidth,
      newHeight
    )

    const oldItemUsedCells = getUsedCellsByCard(card)
    const newItemUsedCells = getUsedCellsByCard(
      card.set('spanX', newSpanX).set('spanY', newSpanY)
    )

    if (
      state
        .get('usedCells')
        .subtract(oldItemUsedCells)
        .intersect(newItemUsedCells).size > 0
    ) {
      dispatch({ type: 'DASHBOARDS:CURRENT_GRID:REFRESH_GRID' })
      return
    }

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')
    const url = `${config.server.url}dashboard/${dashboardId}/card/${cardId}?auth_token=${token}`

    const newCardData = {
      cellX: card.get('cellX'),
      cellY: card.get('cellY'),
      spanX: newSpanX,
      spanY: newSpanY,
      customData: {
        chartId: card.getIn(['content', 'chartId']),
        filters: [],
      },
    }
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_SIZE',
      cardId,
      spanX: newSpanX,
      spanY: newSpanY,
    })
    request
      .put(url, newCardData, { responseType: 'json', dataType: 'json' })
      .then(() => {
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_SIZE:SUCCESS',
          cardId,
          spanX: newSpanX,
          spanY: newSpanY,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
      })

      .catch((error) => {
        console.log(error)
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_SIZE:FAIL',
          cardId,
          spanX: card.get('spanX'),
          spanY: card.get('spanY'),
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
      })
  }
}
