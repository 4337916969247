import { connect } from 'react-redux'
import './filters.scss'

import { getPageById } from 'modules/masterdata/helpers'
import {
  showPanelAction,
  closeAndSaveAction,
  changeRangeAction,
  closeAction,
} from '../../actions/globalFilters'
import _component from './component'

function mapStateToProps(state) {
  return {
    page: getPageById(state.dashboard.current.getIn(['dashboard', 'page'])),
    globalFilters: state.dashboard.currentGrid.get('filters'),
    show: state.dashboard.globalFilters.get('show'),
    filtersStruct: state.masterdata.get('filters'),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    showPanel: (idPanel) => dispatch(showPanelAction(idPanel)),
    closeAndSave: (idPanel, type) =>
      dispatch(closeAndSaveAction(idPanel, type, props.writeOnUpdate)),
    close: () => dispatch(closeAction()),
    changeRange: (range) =>
      dispatch(changeRangeAction(range, props.writeOnUpdate)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
