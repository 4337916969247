import Immutable from 'immutable'
import config from 'config'
import request from 'services/request'
import { store } from 'services/store'
import {
  getInformationItemById,
  getChartById,
} from 'modules/masterdata/helpers'
import { usedCellsFromCoords } from '../../helpers/metrics'
import { refreshCardAction } from './refreshCard'

/**
 * Adds a card to the dashboard.
 */
export function addCardAction(dashboardId, idChart) {
  return (dispatch) => {
    const state = store.getState().dashboard.currentGrid

    const informationItem = getInformationItemById(idChart)
    const chart = getChartById(idChart)

    const addToRow = state.get('addToRow')
    const addToCol = state.get('addToCol')
    const gridCols = state.getIn(['grid', 'cols'])
    const gridRows = state.getIn(['grid', 'rows'])

    let spanX = Math.min(2, gridCols - addToCol)
    let spanY = Math.min(2, gridRows - addToRow)
    const newItemUsedCells = Immutable.Set.fromKeys(
      usedCellsFromCoords(addToCol, addToRow, spanX, spanY).map((element) => {
        return [element[0] + ',' + element[1], true]
      })
    )

    if (state.get('usedCells').intersect(newItemUsedCells).size > 0) {
      spanX = 1
      spanY = 1
    }

    const filters = {
      range: state.getIn(['filters', 'range']).toJS(),
    }
    informationItem.localFilters.forEach((filter) => {
      filter = filter.toLowerCase()
      filters[filter] = { selectedNodes: [] }
      if (
        state.getIn(['filters', filter, 'selectedNodes']) &&
        state.getIn(['filters', filter, 'selectedNodes']).count() > 0
      ) {
        filters[filter].selectedNodes = state
          .getIn(['filters', filter, 'selectedNodes'])
          .toJS()
      } else {
        filters[filter].selectedNodes = state.getIn([
          'filters',
          filter,
          'selectedChildren',
        ])
          ? state.getIn(['filters', filter, 'selectedChildren']).toJS()
          : []
      }
    })

    const newCardData = {
      cellX: addToCol,
      cellY: addToRow,
      spanX,
      spanY,
      contentType: informationItem.chartType + 'Chart',
      customData: {
        chartId: idChart,
      },
      filters,
      title: chart.longText,
    }

    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:ADD_CARD',
      newCard: newCardData,
    })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')
    const url = `${config.server.url}dashboard/${dashboardId}/card?auth_token=${token}`

    request
      .post(url, newCardData, { responseType: 'json', dataType: 'json' })
      .then((result) => {
        newCardData.id = result.response.id
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:ADD_CARD:SUCCESS',
          newCard: newCardData,
        })

        const card = store
          .getState()
          .dashboard.currentGrid.get('cards')
          .find((item) => {
            return item.get('id') === newCardData.id
          })
        if (!card) {
          return
        }
        refreshCardAction(card)(dispatch)
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
      })

      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:ADD_CARD:FAIL',
          ...result.response,
        })
      })
  }
}
