import Immutable from 'immutable'

import DefaultGlobalFilter from '../../helpers/createFilters/defaultGlobalFilters'
import updateFilters from './updateFilters'
import updateRangeFilters from './updateRangeFilters'

const defaultState = Immutable.Map({
  isFetching: false,

  filters: DefaultGlobalFilter,

  isSharedInfoOpened: false,
  isRetrievingSharedKey: false,
  shareKey: null,

  chartData: Immutable.List(),
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DASHBOARDS:FACT:RESET_FILTERS':
      return state.set('filters', DefaultGlobalFilter)

    case 'DASHBOARDS:FACT:UPDATE_FILTERS':
      return updateFilters(state, action)

    case 'DASHBOARDS:FACT:UPDATE_RANGE_FILTERS':
      return updateRangeFilters(state, action)

    case 'DASHBOARDS:FACT:SHARE:OPEN':
      return state
        .set('isSharedInfoOpened', true)
        .set('shareKey', action.shareKey)
        .set('isRetrievingSharedKey', false)

    case 'DASHBOARDS:FACT:SHARE:CLOSE':
      return state
        .set('isSharedInfoOpened', false)
        .set('shareKey', null)
        .set('isRetrievingSharedKey', false)

    case 'DASHBOARDS:FACT:SHARE:START':
      return state.set('isRetrievingSharedKey', true)

    default:
      return state
  }
}
