import cookie from 'react-cookie'
// import { routeActions } from 'react-router-redux'
import { store } from '../../../store'

/**
 * Performs a logout.
 */
export function logoutAction() {
  return (dispatch) => {
    cookie.remove('jwt_token')

    dispatch({
      type: 'USER:LOGOUT',
    })

    // dispatch(routeActions.push('/', {}))
    store.history.push(`/`)
  }
}
