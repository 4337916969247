import config from 'config'
import cookie from 'react-cookie'
import request from 'services/request'
import { store } from '../../../store'
import { loginRedirectUseAction } from './loginRedirectUse'

function isPresent(value) {
  return value !== null && value !== undefined && value !== ''
}

function getFormErrors(username, password) {
  let usernameError
  let passwordError

  if (!isPresent(username)) {
    usernameError = 'Username may not be empty.'
  } else if (username.length > 50) {
    usernameError = 'Username may not be longer then 50 characters.'
  }

  if (!isPresent(password)) {
    passwordError = 'Password may not be empty.'
  } else if (password.length < 8) {
    passwordError = 'Password may not be shorter then 8 characters.'
  }

  if (usernameError || passwordError) {
    return {
      username: usernameError,
      password: passwordError,
    }
  }
}

/**
 * Attempts to log the user in using the provided username and password.
 */
export function loginAction(username, password) {
  return (dispatch) => {
    dispatch({
      type: 'USER:LOGIN_START',
    })

    const errors = getFormErrors(username, password)

    if (errors) {
      dispatch({
        type: 'USER:LOGIN:FAIL',
        username: errors.username,
        password: errors.password,
      })

      return
    }

    const url = `${config.server.url}users/authenticate`

    request
      .get(url, { username, password }, { responseType: 'json' })
      .then((result) => {
        cookie.save('jwt_token', result.response.token)

        dispatch({
          type: 'USER:LOGIN:SUCCESS',
          email: result.response.user.email,
          firstName: result.response.user.firstName,
          lastName: result.response.user.lastName,
          username: result.response.user.username,
          roles: result.response.roles,
          token: result.response.token,
        })

        const redirectAfterLogin = store
          .getState()
          .user.get('redirectAfterLogin')
        if (redirectAfterLogin) {
          dispatch(loginRedirectUseAction())
          store.history.push(redirectAfterLogin)
        } else {
          store.history.push(config.login.returnURL)
        }
      })
      .catch((result) => {
        if (result.xhr.status === 401) {
          return dispatch({
            type: 'USER:LOGIN:FAIL',
            ...result.response,
          })
        }
      })
  }
}
