import React from 'react'

export class WindowResize extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.props.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.props.onResize)
  }

  render() {
    return <span />
  }
}

export default WindowResize
