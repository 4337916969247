import React from 'react'
import PropTypes from 'prop-types'
import registry from 'services/registry'
import ImmutablePropTypes from 'react-immutable-proptypes'

export class ButtonLabelList extends React.Component {
  render() {
    const getLabel = registry.get('helpers/masterdata/getLabel')
    const list = this.props.list

    if (list.size === 0 || (list.size === 1 && list.has('All'))) {
      return (
        <div title={this.props.defaultLabel}>{this.props.defaultLabel}</div>
      )
    }
    const labels = list
      .map((item) => {
        return getLabel(this.props.type, item, this.props.lang || 'EN')
          .shortText
      })
      .toJS()
    return (
      <div title={this.props.defaultLabel}>
        {labels.slice(0, this.props.maxLabel).join(', ')}
        {labels.length > this.props.maxLabel ? ', ...' : ''}
      </div>
    )
  }
}

ButtonLabelList.propTypes = {
  defaultLabel: PropTypes.string,
  lang: PropTypes.string,
  list: ImmutablePropTypes.set.isRequired,
  maxLabel: PropTypes.number,
  type: PropTypes.string.isRequired,
}

ButtonLabelList.defaultProps = {
  defaultLabel: '',
  lang: 'EN',
  maxLabel: 4,
}
