import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Called when the set card text has failed.
 */
export default (state, action) => {
  const cardId = action.cardId

  const cardFound = getCardAndIndexFromCardList(state.get('cards'), cardId)
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state
    .setIn(['cards', index], card.set('isUpdating', false))
    .update('prog', (value) => value + 1)
    .set('isUpdating', false)
}
