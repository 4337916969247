import { store } from 'services/store'

/**
 * Shows the modal for the filters on the facts page.
 */
export function showPanelAction(panelId) {
  return (dispatch) => {
    dispatch({
      type: 'FILTERS:HIERARCHY:INIT',
      selectedNodes: store
        .getState()
        .dashboard.fact.getIn(['filters', panelId, 'selectedNodes']),
      selectedChildren: store
        .getState()
        .dashboard.fact.getIn(['filters', panelId, 'selectedChildren']),
    })
    dispatch({
      type: 'DASHBOARD:GLOBAL_FILTERS:SHOW',
      show: panelId,
    })
  }
}
