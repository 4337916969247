import React from 'react'
import registry from 'services/registry'
import { serverConst } from 'services/server'
import { Carousel, CarouselItem } from 'react-bootstrap'

import LineChart from 'components/widgets/charts/lineChart'
import TextCard from 'components/widgets/charts/text'
import MultiBarChart from 'components/widgets/charts/multiBarChart'
import HorizontalBarChart from 'components/widgets/charts/horizontalBarChart'
import TreemapChart from 'components/widgets/charts/treemapChart'
import TableChart from 'components/widgets/charts/tableChart'
import Card from './card'

const components = {
  LineChart,
  TextCard,
  ColumnChart: MultiBarChart,
  BarChart: HorizontalBarChart,
  TreemapChart,
  TableChart,
}

export class MobilePlayView extends React.Component {
  constructor(options) {
    super(options)

    this.state = {
      index: 0,
      direction: 'next',
      iconSize: 30,
    }
  }

  handleSelect(index, direction) {
    this.setState({
      index,
      direction,
    })
  }

  render() {
    const cardHeightRate = 0.5
    const componentWidth = (this.props.width || this.props.grid.width) - 5

    let cardHeight = Math.max(500, Math.round(componentWidth * cardHeightRate))
    const isPhone = serverConst.deviceType === 'phone'
    if (isPhone) {
      const size = registry.get('helpers/metrics/getWindowSize')()
      cardHeight = size.height - 20
    }

    const cards = this.props.cards
      .sort((a, b) => {
        if (a.get('cellY') > b.get('cellY')) {
          return 1
        } else if (a.get('cellY') < b.get('cellY')) {
          return -1
        }
        if (a.get('cellX') > b.get('cellX')) {
          return 1
        } else if (a.get('cellX') < b.get('cellX')) {
          return -1
        }
        return 0
      })
      .map((card, index) => {
        let paddingLeft = 2
        let paddingRight = 2
        if (componentWidth > 700) {
          paddingLeft = 1
          paddingRight = 1
        }
        const paddingTop = 5
        const paddingBottom = 5
        let headerHeight = 20
        const cellWidth = Math.round(
          this.props.grid.width / this.props.grid.cols
        )
        const cellHeight = Math.round(
          this.props.grid.height / this.props.grid.rows
        )

        const cardItemStyle = {
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          borderRadius: '4px',
          boxShadow: '0 1px 1px rgba(0,0,0,.05)',
          height:
            cardHeight -
            (paddingTop + paddingBottom + headerHeight) +
            40 +
            'px',
        }

        let showFilters = true
        if (card.getIn(['content', 'type']) === 'TextCard') {
          showFilters = false
        }

        const cardId = card.get('id')
        let content
        const marginTop =
          (card.get('spanY') * cellHeight -
            (paddingTop + paddingBottom + headerHeight)) /
            2 -
          20
        if (this.state.index !== index) {
          const loaderStyle = {
            width: card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
            textAlign: 'center',
            marginTop: `${marginTop}px`,
          }
          return (
            <CarouselItem>
              <div style={cardItemStyle}>
                <div style={loaderStyle}>...</div>
              </div>
            </CarouselItem>
          )
        }
        if (!card.get('isInit') || card.get('isFetching')) {
          const loaderStyle = {
            width: card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
            textAlign: 'center',
            marginTop: `${marginTop}px`,
          }
          content = <div style={loaderStyle}>Loading</div>
        } else if (card.get('hasErrored')) {
          const loaderStyle = {
            color: '#ff0000',
            width: card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
            textAlign: 'center',
            marginTop: `${marginTop}px`,
          }
          content = <div style={loaderStyle}>Error loading chart</div>
        } else {
          const CurrentComponent = components[card.getIn(['content', 'type'])]
          content = (
            <CurrentComponent
              id={`chart_${cardId}`}
              key={`card_component_${cardId}`}
              width={componentWidth - (paddingLeft + paddingRight) * 8}
              height={cardHeight - (paddingTop + paddingBottom + headerHeight)}
              data={card.getIn(['content', 'data'])}
              text={card.getIn(['content', 'text'])}
            />
          )
        }

        if (card.getIn(['content', 'type']) === 'TextCard') {
          headerHeight = 0
        }

        return (
          <CarouselItem>
            <div style={cardItemStyle}>
              <Card
                cardId={cardId}
                id={`card_${cardId}`}
                key={`card_${cardId}`}
                width={componentWidth}
                height={cardHeight}
                title={card.get('title')}
                showFilters={showFilters}
                chartId={card.getIn(['content', 'chartId'])}
                filters={card.get('filters')}
                showFilterPopup={card.get('showFilterPopup')}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                headerHeight={headerHeight}
                marginBottom={20}
              >
                {content}
              </Card>
            </div>
          </CarouselItem>
        )
      })

    return (
      <div className="mobile-play-view" ref="container">
        <Carousel
          activeIndex={this.state.index}
          direction={this.state.direction}
          onSelect={this.handleSelect.bind(this)}
        >
          {cards}
        </Carousel>
      </div>
    )
  }
}

export default MobilePlayView
