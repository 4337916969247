import { connect } from 'react-redux'

import { resizeWindowAction } from '../../../actions/resizeWindow'

import _component from './component'

function mapDispatchToProps() {
  return {
    onResize: (event) => resizeWindowAction(event),
  }
}

export default connect(null, mapDispatchToProps)(_component)
