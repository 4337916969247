import React from 'react'
import { Jumbotron, Button } from 'react-bootstrap'

export class NoCardsPanel extends React.Component {
  render() {
    return (
      <Jumbotron>
        <h2>The dashboard is still empty.</h2>
        <Button onClick={this.props.onClick}>Add a card</Button>
      </Jumbotron>
    )
  }
}
