import React from 'react'
import { serverConst } from 'services/server'
import { ButtonGroup, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import CenteredSpinner from 'components/widgets/centeredSpinner'
import GridView from '../gridView'
import ListView from '../listView'
import PlayView from '../playView'
import MobilePlayView from '../mobilePlayView'
import Filters from '../filters'

import DropDownDownload from '../dropDownMenu/dropDownDownload'
import DropDownView from '../dropDownMenu/dropDownView'
import { NoCardsPanel } from './noCardsPanel'

export class DashboardShow extends React.Component {
  componentWillMount() {
    this.props.fetchItem(this.props.id)
  }

  handleImportDashboardButtonClick(id, event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onImportDashboard(id)
  }

  handleViewChangeClick(newView, id, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onChangeView(newView)
  }

  handleExportExcelClick(dashboardId, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onExportExcel(dashboardId)
  }

  render() {
    const isPhone = serverConst.deviceType === 'phone'
    const dashboard = this.props.dashboard
    const componentWidth = this.props.totalWidth

    if (
      this.props.isFetching ||
      !this.props.isFetched ||
      this.props.isImporting
    ) {
      return (
        <div>
          <CenteredSpinner show={true} />
        </div>
      )
    }

    let grid = false
    const view = this.props.forceCurrentView || this.props.currentView
    let filters = false
    if (this.props.cards.count() === 0) {
      grid = <NoCardsPanel />
    } else {
      filters = <Filters writeOnUpdate={false} width={componentWidth} />
      if (view === 'list') {
        grid = (
          <ListView
            grid={this.props.grid}
            cards={this.props.cards}
            width={componentWidth}
          />
        )
      } else if (view === 'play') {
        if (isPhone) {
          grid = (
            <MobilePlayView
              grid={this.props.grid}
              cards={this.props.cards}
              width={componentWidth}
            />
          )
        } else {
          grid = (
            <PlayView
              grid={this.props.grid}
              cards={this.props.cards}
              width={componentWidth}
            />
          )
        }
      } else {
        grid = (
          <GridView
            grid={this.props.grid}
            cards={this.props.cards}
            usedRows={this.props.usedRows}
            width={componentWidth}
          />
        )
      }
    }

    return (
      <div>
        <ButtonGroup className="pull-right" size="sm">
          <DropDownView
            current={this.props.currentView}
            show={!this.props.forceCurrentView}
            onChangeClick={this.handleViewChangeClick.bind(this)}
          />

          <DropDownDownload
            show={true}
            onDownloadExcel={this.handleExportExcelClick.bind(
              this,
              dashboard.id
            )}
          />

          <Button
            noCaret
            size="sm"
            variant="success"
            onClick={this.handleImportDashboardButtonClick.bind(
              this,
              dashboard.hash
            )}
          >
            {/*<Glyphicon glyph="import"/>*/}
            Import Dashboard
          </Button>
        </ButtonGroup>

        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={
            <Tooltip
              id="dashboard-description"
              style={{
                position: 'absolute',
              }}
            >
              {dashboard.description}
            </Tooltip>
          }
        >
          <h2 style={{ display: 'inline-block' }}>{dashboard.title}</h2>
        </OverlayTrigger>
        {filters}
        <div style={{ clear: 'both' }}>{grid}</div>
      </div>
    )
  }
}

export default DashboardShow
