/**
 * Called when a Grid refresh has succeeded.
 */
export default (state, action) => {
  const cardId = action.cardId

  const result = state.get('cards').findEntry((item) => {
    return item.get('id') === cardId
  })
  if (!result || result[0] < 0) {
    return state
  }
  const [index, card] = result

  return state
    .set('cards', state.get('cards').set(index, card.set('isFetching', false)))
    .update('prog', (value) => value + 1)
}
