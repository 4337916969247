import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Called when the filter popup has changed on a card.
 */
export default (state, action) => {
  const cardFound = getCardAndIndexFromCardList(
    state.get('cards'),
    action.cardId
  )
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state.setIn(['cards', index], card.set('showFilterPopup', action.show))
}
