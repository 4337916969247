import Immutable from 'immutable'
import { getGridWidth } from '../../helpers'
import DefaultGlobalFilter from '../../helpers/createFilters/defaultGlobalFilters'

/**
 * Called when a Grid should be refreshed.
 */
export default (state, action) => {
  if (!action.grid) {
    return state.update('prog', (value) => value + 1)
  }

  let width = action.width
  if (!action.width) {
    width = state.get('currentContainerWidth')
  }
  const baseSize = getGridWidth(width, action.grid.cols)

  let filters = DefaultGlobalFilter

  if (action.filters) {
    if (action.filters.range) {
      filters = filters.set('range', Immutable.Map(action.filters.range))
    }
    Object.entries(action.filters)
      .filter(([key]) => key !== 'range')
      .forEach(([key, value]) => {
        filters = filters
          .setIn([key, 'selectedNodes'], Immutable.Set(value.selectedNodes))
          .setIn(
            [key, 'selectedChildren'],
            Immutable.Set(value.selectedChildren)
          )
      })
  }

  return state
    .set('filters', filters)
    .setIn(['grid', 'rows'], action.grid.rows)
    .setIn(['grid', 'cols'], action.grid.cols)
    .setIn(['grid', 'width'], baseSize)
    .setIn(
      ['grid', 'height'],
      Math.round(baseSize / action.grid.cols) * action.grid.rows
    )
    .update('prog', (value) => value + 1)
}
