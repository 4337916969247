import React from 'react'

import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { PencilIcon } from '@primer/octicons-react'

import { serverConst } from 'services/server'

import CenteredSpinner from 'components/widgets/centeredSpinner'
import { InformationItemsPopup } from 'modules/masterdata/components'
import DraggableGrid from '../grid'
import GridView from '../gridView'
import ListView from '../listView'
import PlayView from '../playView'
import MobilePlayView from '../mobilePlayView'
import Filters from '../filters'

import DropDownDownload from '../dropDownMenu/dropDownDownload'
import DropDownEdit from '../dropDownMenu/dropDownEdit'
import DropDownShare from '../dropDownMenu/dropDownShare'
import DropDownView from '../dropDownMenu/dropDownView'
import { NoCardsPanel } from './noCardsPanel'

import ShareInfo from './shareInfo'
import EditModal from './editModal'

export class DashboardShow extends React.Component {
  componentWillMount() {
    this.props.fetchItem(this.props.id)
  }

  handleEditDetailsButtonClick(dashboard, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onEditDetailsOpen(dashboard)
  }

  handleEditButtonClick(id, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.navigateToDashboardsEdit(id)
  }

  handleEditGridButtonClick(idDashboard, id, event) {
    event = event || id
    event.preventDefault()
    event.stopPropagation()

    this.props.goToEdit(idDashboard)
  }

  handleViewGridButtonClick(idDashboard, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.goToView(idDashboard)
  }

  handleRemoveButtonClick(id, _, event) {
    event.preventDefault()
    event.stopPropagation()

    /* eslint-disable no-alert */
    const answer = window.confirm(
      'Are you sure you want to remove this dashboard?'
    )

    if (!answer) {
      return
    }

    this.props.removeEntry(id)
  }

  handlePublishItemClick(id, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onPublish(id)
  }

  handleUnpublishItemClick(id, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onUnpublish(id)
  }

  handleSharedModalClose(event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.shareInfoClose()
  }

  handleSharedModalOpen(event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.shareInfoOpen()
  }

  handleViewChangeClick(newView, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onChangeView(newView)
  }

  handleExportExcelClick(dashboardId, id, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onExportExcel(dashboardId)
  }

  render() {
    if (!this.props.totalWidth || !this.props.dashboard) {
      return false
    }
    const componentWidth = this.props.totalWidth
    const isPhone = serverConst.deviceType === 'phone'
    const dashboard = this.props.dashboard
    const cards = this.props.cards

    if (
      this.props.isFetching ||
      !this.props.isFetched ||
      this.props.isPublishing ||
      this.props.isUnpublishing ||
      !this.props.isGridInit ||
      this.props.isGridFetchingCards
    ) {
      return (
        <div>
          <CenteredSpinner show={true} />
        </div>
      )
    }

    let grid = false
    let filters = false
    if (!isPhone && this.props.isOnEdit) {
      grid = <DraggableGrid id={this.props.dashboard.id} />
      filters = <Filters writeOnUpdate={true} width={componentWidth} />
    } else {
      if (cards.count() === 0) {
        grid = (
          <NoCardsPanel
            onClick={this.handleEditGridButtonClick.bind(this, dashboard.id)}
          />
        )
      } else {
        const view = this.props.forceCurrentView || this.props.currentView
        filters = <Filters writeOnUpdate={true} width={componentWidth} />
        if (view === 'list') {
          grid = (
            <ListView
              grid={this.props.grid}
              cards={cards}
              width={componentWidth}
            />
          )
        } else if (view === 'play') {
          if (isPhone) {
            grid = (
              <MobilePlayView
                grid={this.props.grid}
                cards={cards}
                width={componentWidth}
              />
            )
          } else {
            grid = (
              <PlayView
                grid={this.props.grid}
                cards={cards}
                width={componentWidth}
              />
            )
          }
        } else {
          grid = (
            <GridView
              grid={this.props.grid}
              cards={cards}
              usedRows={this.props.usedRows}
              width={componentWidth}
            />
          )
        }
      }
    }

    let actionMenu = false
    let editModal = false
    let editDetailsButton = false

    if (isPhone || !this.props.isOnEdit) {
      const buttons = []
      if (!isPhone && !this.props.isOnEdit && !this.props.forceCurrentView) {
        buttons.push(
          <DropDownView
            key="dropDownView"
            current={this.props.currentView}
            show={true}
            onChangeClick={this.handleViewChangeClick.bind(this)}
          />
        )
      }
      if (!isPhone && !this.props.isOnEdit) {
        buttons.push(
          <DropDownEdit
            key="dropDownEdit"
            show={true}
            onEditDetails={this.handleEditButtonClick.bind(this, dashboard.id)}
            onEditDashboard={this.handleEditGridButtonClick.bind(
              this,
              dashboard.id
            )}
            onRemoveDashboard={this.handleRemoveButtonClick.bind(
              this,
              dashboard.id
            )}
          />
        )
      }
      if (!isPhone && !this.props.isOnEdit) {
        buttons.push(
          <DropDownDownload
            key="dropDownDownload"
            show={true}
            onDownloadExcel={this.handleExportExcelClick.bind(
              this,
              dashboard.id
            )}
          />
        )
      }
      if (!isPhone && !this.props.isOnEdit) {
        buttons.push(
          <DropDownShare
            key="dropDownShare"
            show={!this.props.isOnEdit}
            dashboardId={this.props.dashboard.id}
            isShared={this.props.dashboard.share.isShared}
            onPublish={this.handlePublishItemClick.bind(this, dashboard.id)}
            onShowLink={this.handleSharedModalOpen.bind(this)}
            onUnpublish={this.handleUnpublishItemClick.bind(this, dashboard.id)}
          />
        )
      }
      actionMenu = (
        <ButtonGroup className="pull-right" size="sm">
          {buttons}
        </ButtonGroup>
      )
    } else {
      actionMenu = (
        <ButtonGroup className="pull-right" size="sm">
          <Button
            variant="default"
            onClick={this.handleViewGridButtonClick.bind(this, dashboard.id)}
          >
            Close dashboard editing
          </Button>
        </ButtonGroup>
      )
      editDetailsButton = (
        <div
          style={{
            fontSize: '18px',
            marginRight: '10px',
            opacity: 0.5,
            cursor: 'pointer',
          }}
          onClick={this.handleEditDetailsButtonClick.bind(this, dashboard)}
        >
          <PencilIcon
            onClick={this.handleEditDetailsButtonClick.bind(this, dashboard)}
          />
        </div>
      )
      editModal = (
        <EditModal
          id={this.props.id}
          onCancel={this.props.onEditDetailsCancel.bind(this)}
          onClose={this.props.onEditDetailsSave.bind(this, dashboard.id)}
          dashboard={this.props.dashboard}
        />
      )
    }

    const titleAndDescription = (
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={
          <Tooltip id="dashboardDescription">{dashboard.description}</Tooltip>
        }
      >
        <h2 style={{ display: 'flex', alignItems: 'center' }}>
          {editDetailsButton}
          {dashboard.title}
        </h2>
      </OverlayTrigger>
    )

    let shareInfo = false
    if (this.props.isShareInfoOpened) {
      shareInfo = (
        <ShareInfo
          show={true}
          hash={this.props.dashboard.share.hash}
          created={this.props.dashboard.share.timestamp}
          onClose={this.handleSharedModalClose.bind(this)}
        />
      )
    }

    let informationItemPopup = false
    if (this.props.isAddingCard) {
      informationItemPopup = (
        <InformationItemsPopup
          show={true}
          onChartClick={this.props.onAddCard.bind(
            this,
            this.props.dashboard.id
          )}
          onCancel={this.props.onAddCardCancel}
          onAddText={this.props.onAddTextCard.bind(
            this,
            this.props.dashboard.id
          )}
        />
      )
    }

    return (
      <div>
        {informationItemPopup}
        {shareInfo}
        {editModal}
        {actionMenu}
        {titleAndDescription}
        {filters}
        <div md={12} style={{ paddingTop: '20px', paddingBottom: '30px' }}>
          <div style={{ clear: 'both' }}>{grid}</div>
        </div>
      </div>
    )
  }
}

export default DashboardShow
