import React from 'react'

const backgroundImage = '/images/grid/plus.png'
const backgroundColor = '#E2EAE2'
const backgroundColorHover = '#c2cAc2'

function writeLine(rows, cols, cellWidth, cellHeight) {
  return rows.map((row, indexRow) => {
    const lineStyle = {
      borderBottom: '1px dashed grey',
    }

    return (
      <tr style={lineStyle} key={'row_' + indexRow}>
        {writeCell.call(this, indexRow, cols, cellWidth, cellHeight)}
      </tr>
    )
  }, this)
}

function writeCell(indexRow, cols, cellWidth, cellHeight) {
  return cols.map((col, indexCol) => {
    const cellStyle = {
      width: cellWidth + 'px',
      height: cellHeight + 'px',
      borderRight: '1px dashed grey',
      background: `${backgroundColor} url('${backgroundImage}') no-repeat center`,
      opacity: 0.1,
      cursor: 'pointer',
    }
    if (this.state.hoverGrid[`${indexRow}_${indexCol}`]) {
      cellStyle.backgroundColor = backgroundColorHover
    } else {
      cellStyle.backgroundColor = backgroundColor
    }
    return (
      <td
        ref={`cell_${indexRow}_${indexCol}`}
        key={`cell_${indexRow}_${indexCol}`}
        style={cellStyle}
        onClick={this.handleClick.bind(this, indexRow, indexCol)}
        onMouseOver={this.handleMouseOver.bind(this, indexRow, indexCol)}
        onMouseOut={this.handleMouseOut.bind(this, indexRow, indexCol)}
      />
    )
  }, this)
}

export class BackgroundPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hoverGrid: {} }
  }

  handleMouseOver(row, col) {
    this.setState({
      hoverGrid: { ...this.state.hoverGrid, [`${row}_${col}`]: true },
    })
  }

  handleMouseOut(row, col) {
    this.setState({
      hoverGrid: { ...this.state.hoverGrid, [`${row}_${col}`]: false },
    })
  }

  handleClick(row, col) {
    if (this.props.onAddClick) {
      this.props.onAddClick(row, col)
    } else {
      console.log(`Click not defined: ${row}_${col}`)
    }
  }

  render() {
    const rowsCount = Math.min(
      this.props.rows,
      Math.max(this.props.usedRows, 7) + 1
    )
    const rows = Array(rowsCount)
      .fill(0)
      .map((e, i) => i + 1)
    const cols = Array(this.props.cols)
      .fill(0)
      .map((e, i) => i + 1)

    const cellPixelX = Math.round(this.props.width / this.props.cols)
    const cellPixelY = Math.round(this.props.height / this.props.rows)

    const containerStyle = {
      width: this.props.width + 'px',
      height: cellPixelY * rowsCount + 'px',
      position: 'absolute',
      top: -1,
      left: 1,
      zIndex: 0,
      borderLeft: '1px dashed grey',
      borderTop: '1px dashed grey',
    }

    const tableStyle = {
      width: this.props.width - 2 + 'px',
      height: cellPixelY * rowsCount - 2 + 'px',
    }

    return (
      <div style={containerStyle}>
        <table style={tableStyle}>
          <tbody>
            {writeLine.call(this, rows, cols, cellPixelX, cellPixelY)}
          </tbody>
        </table>
      </div>
    )
  }
}

export default BackgroundPanel
