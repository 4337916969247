import config from 'config'
import request from 'services/request'
import { store } from 'services/store'
import { refreshAllCardsAction } from '../grid'

/**
 * Changes the value of the range filter.
 */
export function changeRangeAction(range, writeOnUpdate) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:UPDATE_RANGE_FILTERS',
      range,
    })

    const data = store.getState().dashboard.currentGrid.get('filters').toJS()
    const dashboardId = store
      .getState()
      .dashboard.current.getIn(['dashboard', 'id'])

    if (writeOnUpdate) {
      const user = store.getState().user
      const token = user.get('loggedInUser').get('token')
      const url = `${config.server.url}dashboard/${dashboardId}/filters?auth_token=${token}`

      request
        .put(url, data, { responseType: 'json', dataType: 'json' })
        .then(() => {})
        .catch((result) => {
          return dispatch({
            type: 'DASHBOARDS:UPDATE:FAIL',
            ...result.response,
          })
        })
    }
    dispatch(refreshAllCardsAction())
  }
}
