/**
 * Called when the unpublishing of a dashboard has succeeded.
 */
export default (state) => {
  return state
    .setIn(['dashboard', 'share'], {
      hash: null,
      isShared: false,
      timestamp: null,
    })
    .set('isUnpublishing', false)
}
