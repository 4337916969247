import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import elementResizeDetectorMaker from 'element-resize-detector'

export class Resizer extends React.Component {
  constructor(options) {
    super(options)
    this.state = {
      totalWidth: null,
    }

    this.erd = elementResizeDetectorMaker()
    this.resizeHandlerElement = null
  }

  componentWillUnmount() {
    if (this.resizeHandlerElement) {
      this.erd.removeAllListeners(this.resizeHandlerElement)
    }
  }

  containerElementResize(containerElement) {
    if (this.resizeHandlerElement) {
      return
    }

    this.resizeHandlerElement = ReactDOM.findDOMNode(containerElement)

    const that = this
    this.setState({
      totalWidth: this.resizeHandlerElement.offsetWidth,
    })

    this.props.dispatch({
      type: 'APP:CONTAINER:NEW_SIZE',
      width: this.resizeHandlerElement.offsetWidth,
      resizeKey: this.props.resizeKey || 'default',
    })

    let lastTimeout
    const timeout = 300

    this.erd.listenTo(this.resizeHandlerElement, function (element) {
      clearTimeout(lastTimeout)
      lastTimeout = setTimeout(() => {
        if (that.state.totalWidth !== element.offsetWidth) {
          that.setState({
            totalWidth: element.offsetWidth,
          })
          that.props.dispatch({
            type: 'APP:CONTAINER:NEW_SIZE',
            width: element.offsetWidth,
            resizeKey: that.props.resizeKey || 'default',
          })
        }
      }, timeout)
    })
  }

  renderChildren() {
    return React.Children.map(
      this.props.children,
      function (child) {
        return React.cloneElement(child, {
          totalWidth: this.state.totalWidth,
        })
      }.bind(this)
    )
  }

  render() {
    return (
      <div ref={(el) => this.containerElementResize(el)}>
        {this.renderChildren()}
      </div>
    )
  }
}

export default connect(null, null)(Resizer)
