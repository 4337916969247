import React from 'react'
import { getPageById } from 'modules/masterdata/helpers'

import { serverConst } from 'services/server'

export default class DashboardListItem extends React.Component {
  render() {
    const item = this.props.item

    const cellBasicStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }

    const descriptionStyle = {
      maxWidth: '180px',
      ...cellBasicStyle,
    }
    const descriptionInnerStyle = {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }

    let description = false
    const titleStyle = Object.assign({}, cellBasicStyle)
    if (serverConst.deviceType !== 'phone') {
      description = (
        <td style={descriptionStyle}>
          <div style={descriptionInnerStyle}>{item.description}</div>
        </td>
      )
    }
    titleStyle.whiteSpace = 'normal'

    return (
      <tr
        key={item.id}
        onClick={this.props.onRowClick}
        style={{ cursor: 'pointer' }}
      >
        <td style={titleStyle}>{item.title}</td>
        {description}
        <td style={cellBasicStyle}>{getPageById(item.page).title}</td>
        <td style={cellBasicStyle}>{item.createdAt.format('MMM Do YYYY')}</td>
      </tr>
    )
  }
}
