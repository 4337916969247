import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'

import { DashboardCreateNew } from 'modules/dashboard/components'

function mapStateToProps(state) {
  return {
    dashboardStore: state.dashboard,
  }
}

function mapDispatchToProps() {
  return {}
}

class DashboardNewPage extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <DashboardCreateNew />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNewPage)
