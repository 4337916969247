import React from 'react'
import { ListGroup, Modal, Button, ButtonGroup } from 'react-bootstrap'
import registry from 'services/registry'
import CenteredSpinner from 'components/widgets/centeredSpinner'
import { getFlatStruct } from '../informationItems/getFlatStruct'

import InformationItem from './../informationItem'

export class InformationItemsPopup extends React.Component {
  render() {
    const isFetching = this.props.isFetching

    if (isFetching) {
      return <CenteredSpinner show={true} />
    }

    const getLabel = registry.get('helpers/masterdata/getLabel')
    const page = this.props.pages[this.props.currentPage - 1]
    const result = getFlatStruct(
      page.id,
      page.infitems,
      getLabel,
      this.props.itemOpened,
      1
    )

    let listGroup = false
    if (this.props.isInit) {
      listGroup = (
        <div>
          <ListGroup as="ul">
            {result.map((item) => {
              return (
                <InformationItem
                  key={item.id}
                  item={item}
                  handleItemClick={this.props.handleItemClick}
                  handleChartClick={this.props.onChartClick}
                />
              )
            })}
          </ListGroup>
        </div>
      )
    }

    return (
      <div>
        <Modal show={this.props.show}>
          <Modal.Header closeButton onClick={this.props.onCancel}>
            <Modal.Title>{page.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{listGroup}</Modal.Body>
          <Modal.Footer>
            <ButtonGroup>
              <Button
                size="sm"
                variant="success"
                onClick={this.props.onAddText}
              >
                Add Text
              </Button>
              <Button size="sm" variant="primary" onClick={this.props.onCancel}>
                Close
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default InformationItemsPopup
