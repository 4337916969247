import React from 'react'

import LineChart from 'components/widgets/charts/lineChart'
import TextCard from 'components/widgets/charts/text'
import MultiBarChart from 'components/widgets/charts/multiBarChart'
import HorizontalBarChart from 'components/widgets/charts/horizontalBarChart'
import TreemapChart from 'components/widgets/charts/treemapChart'
import TableChart from 'components/widgets/charts/tableChart'
import Card from './card'

const components = {
  LineChart,
  TextCard,
  ColumnChart: MultiBarChart,
  BarChart: HorizontalBarChart,
  TreemapChart,
  TableChart,
}

export class GridView extends React.Component {
  render() {
    const rowsCount = Math.min(this.props.grid.rows, this.props.usedRows)
    const containerStyle = {
      width: this.props.grid.width + 'px',
      height: `${Math.round(
        (this.props.grid.height / this.props.grid.rows) * rowsCount
      )}px`,
      position: 'relative',
      boxSizing: 'border-box',
      margin: '0 auto',
      backgroundColor: '#fafafa',
    }

    return (
      <div style={containerStyle}>
        {this.props.cards.map((card) => {
          const paddingLeft = 5
          const paddingRight = 5
          const paddingTop = 5
          const paddingBottom = 5
          let headerHeight = 50
          const cellWidth = Math.round(
            this.props.grid.width / this.props.grid.cols
          )
          const cellHeight = Math.round(
            this.props.grid.height / this.props.grid.rows
          )

          let showFilters = true
          if (card.getIn(['content', 'type']) === 'TextCard') {
            showFilters = false
          }
          const cardId = card.get('id')
          let content
          const marginTop =
            (card.get('spanY') * cellHeight -
              (paddingTop + paddingBottom + headerHeight)) /
              2 -
            20
          if (!card.get('isInit') || card.get('isFetching')) {
            const loaderStyle = {
              width:
                card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
              textAlign: 'center',
              marginTop: `${marginTop}px`,
            }
            content = <div style={loaderStyle}>Loading</div>
          } else if (card.get('hasErrored')) {
            const loaderStyle = {
              color: '#ff0000',
              width:
                card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
              textAlign: 'center',
              marginTop: `${marginTop}px`,
            }
            content = <div style={loaderStyle}>Error loading chart</div>
          } else {
            const CurrentComponent = components[card.getIn(['content', 'type'])]
            content = (
              <CurrentComponent
                id={`chart_${cardId}`}
                key={`card_component_${cardId}`}
                width={
                  card.get('spanX') * cellWidth - (paddingLeft + paddingRight)
                }
                height={
                  card.get('spanY') * cellHeight -
                  (paddingTop + paddingBottom + headerHeight)
                }
                data={card.getIn(['content', 'data'])}
                text={card.getIn(['content', 'text'])}
              />
            )
          }

          if (card.getIn(['content', 'type']) === 'TextCard') {
            headerHeight = 0
          }

          return (
            <Card
              cardId={cardId}
              id={`card_${cardId}`}
              key={`card_${cardId}`}
              positionX={card.get('cellX') * cellWidth}
              positionY={card.get('cellY') * cellHeight}
              width={card.get('spanX') * cellWidth}
              height={card.get('spanY') * cellHeight}
              title={card.get('title')}
              showFilters={showFilters}
              chartId={card.getIn(['content', 'chartId'])}
              filters={card.get('filters')}
              showFilterPopup={card.get('showFilterPopup')}
              paddingLeft={paddingLeft}
              paddingRight={paddingRight}
              paddingTop={paddingTop}
              paddingBottom={paddingBottom}
              headerHeight={headerHeight}
            >
              {content}
            </Card>
          )
        })}
      </div>
    )
  }
}

export default GridView
