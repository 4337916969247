export function getYear(start, stop) {
  const count = stop - start + 1
  const returnStruct = []
  for (let i = 0; i < count; i++) {
    returnStruct[i] = {
      key: i,
      value: `${start + i}`,
      label: `${start + i}`,
      tooltip: `${start + i}`,
    }
  }
  return returnStruct
}

export function getYearKeyFromValue(struct, value) {
  const searchValue = `${value}`.substring(0, 4)
  const found = struct.find(
    (item) => Number(item.value) === Number(searchValue)
  )
  return found ? found.key : -1
}

export function getYearValueFromKey(struct, key) {
  const found = struct.find((item) => item.key === key)
  return found ? found.value : -1
}
