import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'

import ErrorBlock from 'components/widgets/errorBlock'
import Spinner from 'components/widgets/spinner'
import { initFormAction, loginAction, loginCancelAction } from '../actions'

function mapStateToProps(state) {
  return {
    userStore: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    initForm: () => dispatch(initFormAction()),
    loginAction: (username, password) =>
      dispatch(loginAction(username, password)),
    loginCancelAction: () => dispatch(loginCancelAction()),
  }
}

class LoginForm extends React.Component {
  componentWillMount() {
    this.props.initForm()
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.loginAction(this.refs.username.value, this.refs.password.value)
  }

  handleCancel(event) {
    event.preventDefault()

    this.props.loginCancelAction()
  }

  render() {
    const isLoggingIn = this.props.userStore.get('isLoggingIn')

    let usernameAttributes
    let passwordAttributes
    let errorMessage

    if (!isLoggingIn) {
      usernameAttributes = {
        variant: this.props.userStore.get('loginErrors').get('username')
          ? 'error'
          : null,
        help: this.props.userStore.get('loginErrors').get('username'),
        autoFocus: true,
      }
      passwordAttributes = {
        variant: this.props.userStore.get('loginErrors').get('password')
          ? 'error'
          : null,
        help: this.props.userStore.get('loginErrors').get('password'),
      }

      errorMessage = this.props.userStore.get('loginErrors').get('message')
    }

    return (
      <Form onSubmit={this.handleSubmit.bind(this)}>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            ref="username"
            type="text"
            placeholder="Username"
            {...usernameAttributes}
            disabled={isLoggingIn}
            autoCapitalize="off"
            autoCorrect="off"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            ref="password"
            type="password"
            placeholder="Password"
            {...passwordAttributes}
            disabled={isLoggingIn}
            autoCapitalize="off"
            autoCorrect="off"
          />
        </Form.Group>

        <ErrorBlock message={errorMessage} />

        <Button
          size="sm"
          variant="warning"
          onClick={this.handleCancel.bind(this)}
          disabled={isLoggingIn}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          variant="primary"
          type="submit"
          disabled={isLoggingIn}
          className="pull-right"
        >
          Login
        </Button>
        <Spinner show={isLoggingIn} />
      </Form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
