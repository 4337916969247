import React from 'react'
import { Jumbotron, Container, Row, Col, Card, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    userStore: state.user,
  }
}

class ProfilePage extends React.Component {
  render() {
    const user = this.props.userStore.get('loggedInUser')

    return (
      <div>
        <Container>
          <Row>
            <Col>
              <Jumbotron>
                <h1>Hello {user.get('firstName')}.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.{' '}
                </p>
              </Jumbotron>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card variant="success">
                <Card.Body>
                  <Card.Title>Browse Facts</Card.Title>
                  <Card.Text>
                    Donec vestibulum sit amet purus sed lacinia. Maecenas nec
                    nisl nisi. Donec a porttitor tortor. Pellentesque dapibus
                    posuere tristique. Ut placerat orci sed lectus accumsan.
                  </Card.Text>
                  <Link to="/facts">
                    <Button size="sm" variant="success">
                      Browse
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card variant="info">
                <Card.Body>
                  <Card.Title>Browse Dashboards</Card.Title>
                  <Card.Text>
                    Proin suscipit, arcu ut consequat condimentum, neque nisl
                    blandit magna, vel luctus velit mi id nulla. Nullam rutrum
                    feugiat orci eget finibus. Vivamus iaculis ante nisi.
                  </Card.Text>
                  <Link to="/dashboards">
                    <Button size="sm" variant="info">
                      Browse
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card variant="warning">
                <Card.Body>
                  <Card.Title>Create a Dashboard</Card.Title>
                  <Card.Text>
                    Nunc sit amet arcu purus. Mauris auctor sem sit amet rhoncus
                    hendrerit. Duis nec efficitur turpis, vitae scelerisque
                    dolor. Vestibulum aliquet consequat ligula id venenatis.
                  </Card.Text>
                  <Link to="/dashboards-new">
                    <Button size="sm" variant="warning">
                      Create
                    </Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ProfilePage)
