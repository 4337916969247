import { union } from 'lodash'
import { store } from 'services/store'
import { getChildIdList } from 'modules/masterdata/helpers'

/**
 * Gets the enabled values from the local hierarchy
 */
export default function localHierarchyEnabledValues(filters, type, side) {
  let globalFilters = []
  if (!side || side === 'right' || side === 'both') {
    filters
      .getIn([type, 'selectedChildren'])
      .toJS()
      .forEach((item) => {
        const startItem = store
          .getState()
          .masterdata.getIn(['hierarchiesFlat', type])
          .find((searchItem) => item === searchItem.id)
        globalFilters = union(globalFilters, getChildIdList(startItem))
      })
  }

  if (!side || side === 'left' || side === 'both') {
    return union(globalFilters, filters.getIn([type, 'selectedNodes']).toJS())
  }

  return globalFilters
}
