import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { EyeIcon } from '@primer/octicons-react'

export class DropDownView extends React.Component {
  handleViewChangeClick(type, id, event) {
    this.props.onChangeClick(type, event)
  }

  render() {
    if (!this.props.show) {
      return false
    }

    const viewItems = []
    viewItems.push(
      <Dropdown.Item
        key="1"
        eventKey="1"
        onSelect={this.handleViewChangeClick.bind(this, 'grid')}
      >
        {/*<Glyphicon glyph="th"/> */}
        Grid
      </Dropdown.Item>
    )
    viewItems.push(
      <Dropdown.Item
        key="2"
        eventKey="2"
        onSelect={this.handleViewChangeClick.bind(this, 'list')}
      >
        {/*<Glyphicon glyph="align-justify"/> */}
        List
      </Dropdown.Item>
    )
    viewItems.push(
      <Dropdown.Item
        key="3"
        eventKey="3"
        onSelect={this.handleViewChangeClick.bind(this, 'play')}
      >
        {/*<Glyphicon glyph="play"/> */}
        Play
      </Dropdown.Item>
    )

    // const icon = this.props.current === 'list' ? 'align-justify' : 'th'

    return (
      <Dropdown id="view" size="sm">
        <Dropdown.Toggle>
          <EyeIcon /> View
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          {viewItems.filter((item) => item !== null)}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default DropDownView
