/**
 * Fetches the data from a chart object.
 */
export function getDataForXlsx(chartObject) {
  const titles = ['', ...chartObject.keys]

  const finalRows = chartObject.data.map((dataObj) => {
    return [dataObj.name, ...chartObject.keys.map((key) => dataObj[key])]
  })

  return [titles, ...finalRows]
}
