import { getGridWidth } from '../../helpers'

/**
 * Called when the grid should be resized.
 */
export default (state, action) => {
  const cols = state.getIn(['grid', 'cols'])
  const rows = state.getIn(['grid', 'rows'])
  const baseSize = getGridWidth(action.width, cols)
  return state
    .set('currentContainerWidth', baseSize)
    .setIn(['grid', 'width'], baseSize)
    .setIn(['grid', 'height'], Math.round(baseSize / cols) * rows)
}
