import React from 'react'
import PropTypes from 'prop-types'
import { DraggableCore } from 'react-draggable'
// import { PencilIcon } from '@primer/octicons-react'
import Glyphicon from '@strongdm/glyphicon'
import cloneElement from './cloneElement'

const resizeHandlerStyle = {
  width: `20px`,
  height: `20px`,
  position: 'absolute',
  right: 5,
  bottom: 5,
  transition: 'opacity linear 0.3s',
  cursor: 'nwse-resize',
}

export default class Resizable extends React.Component {
  constructor(options) {
    super(options)
    this.state = {
      resizing: false,
      width: this.props.width,
      height: this.props.height,
      slackW: 0,
      slackH: 0,
    }
  }

  componentWillReceiveProps(nextProps) {
    // If parent changes height/width, set that in our state.
    if (
      !this.state.resizing &&
      (nextProps.width !== this.props.width ||
        nextProps.height !== this.props.height)
    ) {
      this.setState({
        width: nextProps.width,
        height: nextProps.height,
      })
    }
  }

  lockAspectRatio(width, height, aspectRatio) {
    height = width / aspectRatio
    width = height * aspectRatio
    return [width, height]
  }

  // If you do this, be careful of constraints
  runConstraints(width, height) {
    const [min, max] = [this.props.minConstraints, this.props.maxConstraints]

    if (this.props.lockAspectRatio) {
      const ratio = this.state.width / this.state.height
      height = width / ratio
      width = height * ratio
    }

    if (!min && !max) return [width, height]

    const [oldW, oldH] = [width, height]

    // Add slack to the values used to calculate bound position. This will ensure that if
    // we start removing slack, the element won't react to it right away until it's been
    // completely removed.
    let { slackW, slackH } = this.state
    width += slackW
    height += slackH

    if (min) {
      width = Math.max(min[0], width)
      height = Math.max(min[1], height)
    }
    if (max) {
      width = Math.min(max[0], width)
      height = Math.min(max[1], height)
    }

    // If the numbers changed, we must have introduced some slack. Record it for the next iteration.
    slackW += oldW - width
    slackH += oldH - height
    if (slackW !== this.state.slackW || slackH !== this.state.slackH) {
      this.setState({ slackW, slackH })
    }

    return [width, height]
  }

  /**
   * Wrapper around drag events to provide more useful data.
   *
   * @param  {String} handlerName Handler name to wrap.
   * @return {Function}           Handler function.
   */
  resizeHandler(handlerName) {
    return (e, ui) => {
      const { node, deltaX, deltaY } = ui
      let width = this.state.width + deltaX
      let height = this.state.height + deltaY

      // Early return if no change
      const widthChanged = width !== this.state.width
      const heightChanged = height !== this.state.height
      if (handlerName === 'onResize' && !widthChanged && !heightChanged) {
        return
      }

      ;[width, height] = this.runConstraints(width, height)

      // Set the appropriate state for this handler.
      const newState = {}
      if (handlerName === 'onResizeStart') {
        newState.resizing = true
      } else if (handlerName === 'onResizeStop') {
        newState.resizing = false
      } else {
        // Early return if no change after constraints
        if (width === this.state.width && height === this.state.height) return
        newState.width = width
        newState.height = height
      }

      this.setState(newState, () => {
        /* eslint-disable no-unused-expressions */
        this.props[handlerName] &&
          this.props[handlerName](e, { node, size: { width, height } })
      })
    }
  }

  render() {
    const props = this.props
    const className = props.className
      ? `${props.className} react-resizable`
      : 'react-resizable'

    // What we're doing here is getting the child of this element, and cloning it with this element's props.
    // We are then defining its children as:
    // Its original children (resizable's child's children), and
    // A draggable handle.
    return cloneElement(props.children, {
      ...props,
      className,
      children: [
        props.children.props.children,
        <DraggableCore
          {...props.draggableOpts}
          ref="draggable"
          onStop={this.resizeHandler('onResizeStop')}
          onStart={this.resizeHandler('onResizeStart')}
          onDrag={this.resizeHandler('onResize')}
        >
          <div className="resize-handler" style={resizeHandlerStyle}>
            {/*<PencilIcon />*/}
            <Glyphicon glyph="resize-full" style={{ fontSize: `20px` }} />
          </div>
        </DraggableCore>,
      ],
    })
  }
}

Resizable.propTypes = {
  //
  // Required Props
  //

  // Require that one and only one child be present.
  children: PropTypes.element.isRequired,

  // Initial w/h
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,

  //
  // Optional props
  //

  // If you change this, be sure to update your css
  handleSize: PropTypes.array,

  // If true, will only allow width/height to move in lockstep
  lockAspectRatio: PropTypes.bool,

  // Min/max size
  minConstraints: PropTypes.arrayOf(PropTypes.number),
  maxConstraints: PropTypes.arrayOf(PropTypes.number),

  // Callbacks
  onResizeStop: PropTypes.func,
  onResizeStart: PropTypes.func,
  onResize: PropTypes.func,

  // These will be passed wholesale to react-draggable's DraggableCore
  draggableOpts: PropTypes.object,
}

Resizable.defaultProps = {
  handleSize: [20, 20],
  lockAspectRatio: false,
  minConstraints: [20, 20],
  maxConstraints: [Infinity, Infinity],
}
