/**
 * TODO: Document.
 */
export const positionToPixel = (position, cellLength) => {
  return position * cellLength
}

/**
 * TODO: Document.
 */
export const pixelToPosition = (pixel, cellLength, maxCellNum) => {
  if (pixel === 0) {
    return 0
  }
  const tmpResult = Math.round(pixel / cellLength)
  if (tmpResult >= maxCellNum) {
    return maxCellNum - 1
  }
  if (tmpResult < 0) {
    return 0
  }
  return tmpResult
}

/**
 * TODO: Document.
 */
export const spanToPixel = (span, cellLength) => {
  return span * cellLength
}

/**
 * TODO: Document.
 */
export const pixelToSpan = (pixel, cellLength, maxCellNum) => {
  if (pixel <= cellLength) {
    return 1
  }
  const tmpResult = Math.round(pixel / cellLength)
  if (tmpResult > maxCellNum) {
    return maxCellNum
  }
  return tmpResult
}

/**
 * TODO: Document.
 */
export const usedCellsFromCoords = (x, y, w, h) => {
  const list = []
  for (let i = x; i < w + x; i++) {
    for (let j = y; j < h + y; j++) {
      list.push([i, j])
    }
  }
  return list
}
