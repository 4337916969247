import Immutable from 'immutable'

/**
 * Gets called when the dashboard fetch failed.
 */
export default (state) => {
  return state
    .set('isFetching', false)
    .set('isFetched', false)
    .set(
      'dashboard',
      Immutable.Map({
        title: null,
        description: null,
        share: { hash: null, isShared: false, timestamp: null },
      })
    )
}
