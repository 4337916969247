import Immutable from 'immutable'

const defaultState = Immutable.Map({
  isLoggingIn: false,
  loggedInUser: null,
  loginErrors: Immutable.Map(),
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'USER:INIT_FORM':
      return state.set('isLoggingIn', false).set('loginErrors', Immutable.Map())

    case 'USER:LOGOUT':
      return state.set('loggedInUser', null)

    case 'USER:LOGIN_START':
      return state.set('isLoggingIn', true).set('loginErrors', Immutable.Map())

    case 'USER:LOGIN:SUCCESS':
      return state.set('isLoggingIn', false).set(
        'loggedInUser',
        Immutable.Map({
          email: action.email,
          firstName: action.firstName,
          lastName: action.lastName,
          username: action.username,
          token: action.token,
          roles: Immutable.List(action.roles),
        })
      )

    case 'USER:LOGIN:FAIL':
      return state.set('isLoggingIn', false).set(
        'loginErrors',
        Immutable.Map({
          username: action.username,
          password: action.password,
          message: action.message,
        })
      )

    case 'USER:VALIDATE_START':
      return state.set('isLoggingIn', true).set('loginErrors', Immutable.Map())

    case 'USER:VALIDATE:SUCCESS':
      return state.set('isLoggingIn', false).set(
        'loggedInUser',
        Immutable.Map({
          email: action.email,
          firstName: action.firstName,
          lastName: action.lastName,
          username: action.username,
          token: action.token,
          roles: Immutable.List(action.roles),
        })
      )

    case 'USER:VALIDATE:FAIL':
      return state.set('isLoggingIn', false)

    case 'USER:LOGIN_REDIRECT_SET':
      return state.set('redirectAfterLogin', action.redirect)

    case 'USER:LOGIN_REDIRECT_USE':
      return state.set('redirectAfterLogin', null)

    default:
      return state
  }
}
