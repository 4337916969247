/**
 * Selects a new menu entry.
 */
export function changeMenuItemCategory(menuItemCategory) {
  return (dispatch) => {
    dispatch({
      type: 'APP:CHANGE_MENU_ITEM_CATEGORY',
      menuItemCategory,
    })
  }
}
