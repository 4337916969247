import Immutable from 'immutable'
import { pixelToPosition, pixelToSpan, usedCellsFromCoords } from './metrics'

export function getUsedCellsByCard(card) {
  return Immutable.Set.fromKeys(
    usedCellsFromCoords(
      card.get('cellX'),
      card.get('cellY'),
      card.get('spanX'),
      card.get('spanY')
    ).map((element) => {
      return [element[0] + ',' + element[1], true]
    })
  )
}

export function getNewPositionForCard(grid, card, newPixelX, newPixelY) {
  const cellWidth = Math.round(grid.get('width') / grid.get('cols'))
  const cellHeight = Math.round(grid.get('height') / grid.get('rows'))

  const newCellX = pixelToPosition(
    newPixelX,
    cellWidth,
    grid.get('cols') - card.get('spanX') + 1
  )
  const newCellY = pixelToPosition(
    newPixelY,
    cellHeight,
    grid.get('rows') - card.get('spanY') + 1
  )

  return { newCellX, newCellY }
}

export function getNewSizeForCard(grid, card, newPixelWidth, newPixeHight) {
  const cellWidth = Math.round(grid.get('width') / grid.get('cols'))
  const cellHeight = Math.round(grid.get('height') / grid.get('rows'))

  const newSpanX = pixelToSpan(
    newPixelWidth,
    cellWidth,
    grid.get('cols') - card.get('cellX')
  )
  const newSpanY = pixelToSpan(
    newPixeHight,
    cellHeight,
    grid.get('rows') - card.get('cellY')
  )

  return { newSpanX, newSpanY }
}
