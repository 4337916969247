import { store } from 'services/store'
import { getCardAndIndexFromCardList } from '../../helpers'

/**
 * Shows a panel.
 */
export function showPanelAction(cardId, panelId) {
  return (dispatch) => {
    const { card } = getCardAndIndexFromCardList(
      store.getState().dashboard.currentGrid.get('cards'),
      cardId
    )
    if (!card) {
      console.log('Card not found, ', cardId)
    }

    dispatch({
      type: 'FILTERS:HIERARCHY:INIT',
      selectedNodes: card.getIn(['filters', panelId, 'selectedNodes']),
      selectedChildren: card.getIn(['filters', panelId, 'selectedChildren']),
    })
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_FILTER_POPUP',
      show: panelId,
      cardId,
    })
  }
}
