import Immutable from 'immutable'
const defaultState = Immutable.Map({
  menuItemCategory: null,
  isOnInit: false,
  resizer: Immutable.Map({
    default: null,
    mainContainer: null,
  }),
  resizeWindowProg: 0,
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'APP:INIT_START':
      return state.set('isOnInit', true)

    case 'APP:INIT:SUCCESS':
      return state.set('isOnInit', false)

    case 'APP:INIT:FAIL':
      return state.set('isOnInit', false)

    case 'APP:CHANGE_MENU_ITEM_CATEGORY':
      return state.set('menuItemCategory', action.menuItemCategory)

    case 'APP:CONTAINER:NEW_SIZE':
      return state.setIn(['resizer', action.resizeKey], action.width)

    case 'APP:RESIZE_WINDOW':
      return state.set('resizeWindowProg', state.get('resizeWindowProg') + 1)

    default:
      return state
  }
}
