import { combineReducers } from 'redux'
import create from './create'
import current from './current'
import currentGrid from './currentGrid'
import fact from './fact'
import globalFilters from './globalFilters'
import list from './list'

const reducer = combineReducers({
  create,
  current,
  currentGrid,
  fact,
  globalFilters,
  list,
})

export default reducer
