import React from 'react'

class ErrorBlock extends React.Component {
  render() {
    if (this.props.message) {
      return (
        <div className="has-error">
          <span className="help-block">{this.props.message}</span>
        </div>
      )
    }
    return false
  }
}

export default ErrorBlock
