import { connect } from 'react-redux'
import {
  toggleNodeAction,
  toggleChildAction,
  toggleItemAction,
} from '../../../actions/filters/hierarchy'
import _component from './component'

import './hierarchy.scss'

function mapStateToProps(state) {
  return {
    selectedNodes: state.filters.hierarchy.get('selectedNodes'),
    selectedChildren: state.filters.hierarchy.get('selectedChildren'),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    toggleNode: (id) => dispatch(toggleNodeAction(id, props.side)),
    toggleChild: (id) =>
      dispatch(toggleChildAction(props.type, id, props.side)),
    toggleItem: (id) => dispatch(toggleItemAction(props.type, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
