const buttonPadding = 5

function getMetrics(containerWidth, filtersCount) {
  let buttonWidth = 0
  if (filtersCount) {
    buttonWidth = Math.floor(containerWidth / filtersCount) - buttonPadding * 2
  }
  return { buttonWidth: Math.min(buttonWidth, 170), buttonPadding }
}

export default getMetrics
