import React from 'react'
import distinctColors from 'distinct-colors'

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  LineChart,
  ResponsiveContainer,
} from 'recharts'

const palette = distinctColors({ count: 100 })

const LeverixLineChart = (props) => {
  return (
    <div style={{ width: '100%', height: props.height }}>
      <ResponsiveContainer>
        <LineChart
          data={props.data.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: '0.8em' }} />
          <YAxis tick={{ fontSize: '0.8em' }} />
          <Tooltip />
          {props.data.keys.map((key, i) => (
            <Line key={key} dataKey={key} stroke={palette[i % 100].hex()} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

LeverixLineChart.defaultProps = {
  id: 'chart',
  height: 600,
  useInteractiveGuideline: true,
  showLegend: false,
  data: [],
}

export default LeverixLineChart
