import { store } from 'services/store'

/**
 * Gets an information item by id.
 */
export function getInformationItemById(chartId) {
  return store
    .getState()
    .masterdata.get('informationItems')
    .find((item) => item.chartId === chartId)
}
