import { getChildIdList } from 'modules/masterdata/helpers'
import { store } from 'services/store'
import { union, intersection } from 'lodash'

/**
 * Returns the nonRecursiveFilter data from the backend.
 */
export default function nonRecursiveFilter(filterName, global, local) {
  let globalFilters = []

  try {
    global
      .getIn([filterName, 'selectedChildren'])
      .toJS()
      .forEach((item) => {
        const startItem = store
          .getState()
          .masterdata.getIn(['hierarchiesFlat', filterName])
          .find((searchItem) => item === searchItem.id)
        globalFilters = union(globalFilters, getChildIdList(startItem, true))
      })

    let localFilter = []
    if (local) {
      local
        .getIn([filterName, 'selectedNodes'])
        .toJS()
        .forEach((item) => {
          const startItem = store
            .getState()
            .masterdata.getIn(['hierarchiesFlat', filterName])
            .find((searchItem) => item === searchItem.id)
          localFilter = union(localFilter, getChildIdList(startItem, true))
        })

      if (globalFilters.length === 0) {
        return localFilter
      }
      return localFilter.length
        ? intersection(globalFilters, localFilter)
        : globalFilters
    }
  } catch (e) {
    console.log(e)
  }
  return globalFilters
}
