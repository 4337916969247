import { getInformationItemById } from 'modules/masterdata/helpers'

import registry from 'services/registry'
import { groupBy, uniq } from 'lodash'

/**
 * Gets the data for a chart in the required format for the chart library.
 */
export function transformDataForChart(chartId, chartData, currentLang) {
  const getLabel = registry.get('helpers/masterdata/getLabel')
  const informationItem = getInformationItemById(chartId)
  const groupByValue = informationItem.chartX.group.toLowerCase()
  if (informationItem.scalingFactor) {
    chartData = chartData.map((item) => {
      return {
        ...item,
        amount:
          Math.round((item.amount / informationItem.scalingFactor) * 100) / 100,
      }
    })
  }
  // TODO This is where the data ara modified after the download
  let data = []
  let keys = []
  if (groupByValue) {
    const groupKey = informationItem.chartY.group.toLowerCase()
    const groupedData = groupBy(chartData, groupByValue)
    data = Object.keys(groupedData).map((k) => {
      return {
        name: getLabel(groupByValue, k, currentLang).shortText,
        ...groupedData[k].reduce((acc, w) => {
          acc[w[groupKey]] = w.amount
          keys.push(w[groupKey])
          return acc
        }, {}),
      }
    })
  } else if (chartData.length > 0) {
    const key = Object.keys(chartData[0]).find((item) => item !== 'amount')
    keys.push('amount')
    data = chartData.map((item) => {
      return {
        name: getLabel(key, item[key], currentLang).shortText,
        amount: item['amount'],
      }
    })
  }

  return { data, keys: uniq(keys) }
}

// /**
//  * Gets the data for a chart in the required format for the chart library.
//  */
// export function transformDataForChart(chartId, chartData, currentLang) {
//   const getLabel = registry.get('helpers/masterdata/getLabel');
//   const informationItem = getInformationItemById(chartId);
//   const groupByValue = informationItem.chartY.group.toLowerCase();
//   if (informationItem.scalingFactor) {
//     chartData = chartData.map(
//       item => {
//         return {
//           ...item,
//           amount: Math.round(item.amount / informationItem.scalingFactor * 100) / 100
//         };
//       }
//     );
//   }
//   const groupKey = informationItem.chartX.group.toLowerCase();
//   const groupedData = groupBy(chartData, groupByValue);
//   return Object.keys(groupedData).map((k) => {
//     return {
//       key: getLabel(groupByValue, k, currentLang).shortText,
//       shortLabel: k,
//       values: groupedData[k].map((w) => {
//         return {
//           x: w[groupKey],
//           y: w.amount
//         };
//       })
//     };
//   });
// }
