/**
 * Gets the labels of a node in the list.
 */
export function getLabelsFromList(originalList, list, type) {
  originalList.forEach((item) => {
    list[`${type}_${item.key}_${item.lang}`.toLowerCase()] = {
      longText: item.longText,
      shortText: item.shortText,
    }
  })
}
