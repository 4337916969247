import Immutable from 'immutable'

const defaultState = Immutable.Map({
  show: null,
})

/**
 * Called when the global filters are shown.
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DASHBOARD:GLOBAL_FILTERS:SHOW':
      return state.set('show', action.show)

    default:
      return state
  }
}
