import { store } from 'services/store'
import { refreshCardAction } from './refreshCard'

/**
 * Refreshes all cards with new data from the API.
 */
export function refreshAllCardsAction() {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:REFRESH_ALL_CARDS',
    })

    store
      .getState()
      .dashboard.currentGrid.get('cards')
      .forEach((card) => {
        refreshCardAction(card)(dispatch)
      })
  }
}
