import Immutable from 'immutable'
import { usedCellsFromCoords } from './../../helpers/metrics'

/**
 * Called when cards are moved in the grid. Sets the new grid data.
 */
export default (state) => {
  let newUsedCell = Immutable.Set()

  let usedRows = 0
  state.get('cards').forEach((item) => {
    newUsedCell = newUsedCell.union(
      Immutable.Set.fromKeys(
        usedCellsFromCoords(
          item.get('cellX'),
          item.get('cellY'),
          item.get('spanX'),
          item.get('spanY')
        ).map((element) => {
          if (element[1] > usedRows) {
            usedRows = element[1]
          }
          return [element[0] + ',' + element[1], true]
        })
      )
    )
  })
  return state.set('usedRows', usedRows).set('usedCells', newUsedCell)
}
