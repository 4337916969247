import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

import { refreshAllCardsAction } from './grid'

/**
 * Fetches all data required for the Shared Dashboard Show page from the API.
 */
export function fetchSharedAction(id) {
  return (dispatch) => {
    dispatch({ type: 'DASHBOARDS:FETCH_START' })
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS',
    })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    request
      .get(
        `${config.server.url}dashboard/hash/${id}?auth_token=${token}`,
        {},
        { responseType: 'json' }
      )

      .then((result) => {
        result.response.share = {
          hash: null,
          isShared: false,
          timestamp: null,
        }
        dispatch({
          type: 'DASHBOARDS:FETCH:SUCCESS',
          dashboard: result.response,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:REFRESH_GRID',
          grid: result.response.grid || { cols: 6, rows: 6 },
          filters: result.response.filters,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS:SUCCESS',
          cards: result.response.cards,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
        refreshAllCardsAction()(dispatch)
      })

      .catch((error) => {
        console.log(error)
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS:FAIL',
          ...error.response,
        })
        return dispatch({
          type: 'DASHBOARDS:FETCH:FAIL',
          ...error.response,
        })
      })
  }
}
