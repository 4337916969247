import { put } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga'
// import { routeActions } from 'react-router-redux'

import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * When a shared dashboard gets loaded, resets and updates the filters.
 */
export function* loadSharedFact() {
  yield* takeEvery('DASHBOARDS:FACT:SHARED:LOAD', function* (action) {
    try {
      const user = store.getState().user
      const token = user.get('loggedInUser').get('token')
      const url = `${config.server.url}fact/${action.hash}?auth_token=${token}`

      const result = yield request.get(url, {}, { responseType: 'json' })

      yield put({
        type: 'DASHBOARDS:FACT:RESET_FILTERS',
      })
      yield put({
        type: 'DASHBOARDS:FACT:UPDATE_RANGE_FILTERS',
        range: result.response.data.filters.range,
      })
      // yield put({
      //   type: 'DASHBOARDS:FACT:UPDATE_FILTERS',
      //   name: 'constrd',
      //   filters: Immutable.fromJS(result.response.data.filters.constrd)
      // });
      // yield put({
      //   type: 'DASHBOARDS:FACT:UPDATE_FILTERS',
      //   name: 'geoloc',
      // });
      //   filters: Immutable.fromJS(result.response.data.filters.geoloc)

      // yield put(routeActions.push('/facts/' + result.response.data.chartId, {}))
      yield store.history.push('/facts/' + result.response.data.chartId)
    } catch (e) {
      yield put({
        type: 'DASHBOARDS:FACT:FETCH_SHARED:FAIL',
        error: e,
      })
    }
  })
}
