import Immutable from 'immutable'

import { serverConst } from '../../../../services/server'

import changeView from './changeView'
import forceView from './forceView'

import fetch from './fetch'
import fetchSuccess from './fetchSuccess'
import fetchFail from './fetchFail'

import publish from './publish'
import publishSuccess from './publishSuccess'
import publishFail from './publishFail'

import shareInfoOpen from './shareInfoOpen'
import shareInfoClose from './shareInfoClose'

import updateDetailsSuccess from './updateDetailsSuccess'

import unpublish from './unpublish'
import unpublishSuccess from './unpublishSuccess'
import unpublishFail from './unpublishFail'

import dashboardImport from './import'
import dashboardImportSuccess from './importSuccess'
import dashboardImportFail from './importFail'

const defaultState = Immutable.Map({
  isFetching: false,
  isFetched: false,

  isPublishing: false,
  isUnpublishing: false,
  isImporting: false,

  isShareInfoOpened: false,

  editModalFormErrors: Immutable.Map(),

  forceCurrentView: serverConst.deviceType === 'phone' ? 'play' : null,

  currentView: 'grid',

  dashboard: Immutable.Map({
    id: null,
    title: null,
    description: null,
    share: { hash: null, isShared: false, timestamp: null },
  }),
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DASHBOARDS:CHANGE_VIEW':
      return changeView(state, action)

    case 'DASHBOARDS:FORCE_VIEW':
      return forceView(state, action)

    case 'DASHBOARDS:FETCH_START':
      return fetch(state, action)

    case 'DASHBOARDS:FETCH:SUCCESS':
      return fetchSuccess(state, action)

    case 'DASHBOARDS:FETCH:FAIL':
      return fetchFail(state, action)

    case 'DASHBOARDS:PUBLISH':
      return publish(state, action)

    case 'DASHBOARDS:PUBLISH:SUCCESS':
      return publishSuccess(state, action)

    case 'DASHBOARDS:PUBLISH:FAIL':
      return publishFail(state, action)

    case 'DASHBOARDS:UNPUBLISH':
      return unpublish(state, action)

    case 'DASHBOARDS:UNPUBLISH:SUCCESS':
      return unpublishSuccess(state, action)

    case 'DASHBOARDS:UNPUBLISH:FAIL':
      return unpublishFail(state, action)

    case 'DASHBOARDS:SHARE_INFO:OPEN':
      return shareInfoOpen(state, action)

    case 'DASHBOARDS:SHARE_INFO:CLOSE':
      return shareInfoClose(state, action)

    case 'DASHBOARDS:IMPORT':
      return dashboardImport(state, action)

    case 'DASHBOARDS:IMPORT:SUCCESS':
      return dashboardImportSuccess(state, action)

    case 'DASHBOARDS:IMPORT:FAIL':
      return dashboardImportFail(state, action)

    case 'DASHBOARDS:UPDATE_DETAILS:SUCCESS':
      return updateDetailsSuccess(state, action)

    case 'DASHBOARDS:UPDATE:FAIL':
      console.log('DASHBOARDS:UPDATE:FAIL - TO IMPLEMENT')
      return state

    default:
      return state
  }
}
