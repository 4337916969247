import config from 'config'
import { loginRedirectUseAction } from './loginRedirectUse'
import { store } from '../../../store'

/**
 * Cancels the login process.
 */
export function loginCancelAction() {
  return (dispatch) => {
    dispatch(loginRedirectUseAction())
    // dispatch(routeActions.push(config.login.cancelReturnURL, {}))
    store.history.push(config.login.cancelReturnURL)
  }
}
