import { put } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga'

/**
 * When the window gets resized, refreshes the grid.
 */
export function* resizeGridSaga() {
  yield* takeEvery('APP:CONTAINER:NEW_SIZE', function* (action) {
    yield put({
      type: 'DASHBOARDS:CURRENT_GRID:RESIZE_GRID',
      width: action.width,
    })
    yield put({
      type: 'DASHBOARDS:CURRENT_GRID:REFRESH_GRID',
      width: action.width,
    })
  })
}
