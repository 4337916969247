import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const spinnerStyle = {
  width: '34px',
  height: '34px',
  marginLeft: 'auto',
  marginRight: 'auto',
  background: 'url(/images/spinner.gif) center no-repeat',
}

class MasterDataLoader extends React.Component {
  componentWillMount() {
    this.props.initData()
  }

  render() {
    if (!this.props.isInit) {
      return (
        <Container>
          <Row>
            <Col md={12}>
              <div style={spinnerStyle} />
            </Col>
          </Row>
        </Container>
      )
    }
    return <div>{this.props.children}</div>
  }
}

export default MasterDataLoader
