import React from 'react'

import Container from './container'

export class DraggableGrid extends React.Component {
  render() {
    return (
      <Container
        id={this.props.id}
        grid={this.props.grid}
        cards={this.props.cards}
        usedRows={this.props.usedRows}
        onAddClick={this.props.onAddClick}
        onDragStop={this.props.onDragStop.bind(this, this.props.id)}
        onResizeStop={this.props.onResizeStop.bind(this, this.props.id)}
        onRemoveCard={this.props.onRemoveCard.bind(this, this.props.id)}
        onEditCard={this.props.onEditCard.bind(this, this.props.id)}
        onEditCardOk={this.props.onEditCardOk.bind(this, this.props.id)}
        onEditCardTitleOk={this.props.onEditCardTitleOk.bind(
          this,
          this.props.id
        )}
        onEditCardCancel={this.props.onEditCardCancel.bind(this, this.props.id)}
        onEditCardTextChange={this.props.onEditCardTextChange.bind(this)}
        cardFilterComponent={this.props.cardFilterComponent}
      />
    )
  }
}

export default DraggableGrid
