import React from 'react'
import { Card as BSCard } from 'react-bootstrap'
import CardFilter from '../cardFilters'

export class Card extends React.Component {
  render() {
    const iconSize = 25
    const padding = iconSize + 10

    const containerHeaderStyle = {
      position: 'absolute',
      height: `${this.props.headerHeight}px`,
    }
    const titleStyle = {
      height: `${this.props.headerHeight}px`,
      paddingLeft: padding + 'px',
      width: this.props.width - padding * 2,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const filterHeight = 30

    const header = (
      <div style={containerHeaderStyle}>
        <div style={titleStyle}>{this.props.title}</div>
        <CardFilter
          chartId={this.props.chartId}
          cardId={this.props.cardId}
          localFilters={this.props.filters}
          showFilterPopup={this.props.showFilterPopup}
          width={this.props.width - 15}
          height={filterHeight}
        />
      </div>
    )
    const containerStyle = {
      width: this.props.width + 'px',
      height: this.props.height + 'px',
      marginBottom: this.props.marginBottom + 'px',
    }

    return (
      <div key={`box_${this.props.id}`} style={containerStyle} {...this.props}>
        <BSCard
          style={{ height: '100%', width: '100%', overflow: 'hidden' }}
          className="grid-component-panel"
        >
          {header}
          <div style={{ marginTop: `${this.props.headerHeight + 22}px` }}>
            {this.props.children}
          </div>
        </BSCard>
      </div>
    )
  }
}

export default Card
