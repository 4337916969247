/**
 * Transform the hierarchy structure in a flat structure
 * with al the parameter set to correctly manage the selection in the hierarchy struct
 * @param tree
 * @param labelResolver
 * @param selectedNodes
 * @param selectedChildren
 * @param enabledNodes
 * @param {Immutable.Set} openedNodes
 * @return {Array}
 */
export function getFlatStruct(
  tree,
  labelResolver,
  selectedNodes,
  selectedChildren,
  enabledNodes,
  openedNodes
) {
  const returnStruct = []
  parse(
    tree,
    0,
    returnStruct,
    labelResolver,
    selectedNodes,
    selectedChildren,
    enabledNodes,
    openedNodes,
    false,
    true
  )
  return returnStruct
}

function parse(
  item,
  level,
  returnStruct,
  labelResolver,
  selectedNodes,
  selectedChildren,
  enabledNodes,
  openedNodes,
  isParentChecked,
  isParentOpen
) {
  const currentNode = {
    id: item.id,
    level: level + 1,
    isNodeChecked: false,
    isChildrenForceChecked: isParentChecked,
    isChildrenChecked: false,
    isChildrenDisabled: isParentChecked,
    idDisabled: false,
    isOpen: false,
    hasChild: false,
    hasChildsChecked: false,
    isParentOpen,
  }

  currentNode.isOpen =
    currentNode.level <= 1 || openedNodes.has(item.id) ? true : false
  returnStruct.push(currentNode)

  if (enabledNodes.count() > 0 && !enabledNodes.has(item.id)) {
    currentNode.idDisabled = true
  }

  if (item.data.infoObject === 'NODE') {
    currentNode.title = item.data.nodeName
  } else {
    currentNode.title = labelResolver(item.id, 'en').longText
  }

  if (!currentNode.idDisabled) {
    if (selectedNodes && selectedNodes.has(item.id)) {
      currentNode.isNodeChecked = true
    }

    if (selectedChildren && selectedChildren.has(item.id)) {
      currentNode.isChildrenChecked = true
    }
  }

  if (selectedNodes && selectedNodes.size > 0) {
    currentNode.isChildrenChecked = false
    currentNode.isChildrenForceChecked = false
    currentNode.isChildrenDisabled = true
  }

  if (item.childs && item.childs.length) {
    currentNode.hasChild = true
    const forceCheckOnChilds =
      !currentNode.idDisabled &&
      (isParentChecked ||
        currentNode.isChildrenChecked ||
        currentNode.isChildrenForceChecked)

    item.childs.forEach((childItem) => {
      const hasChildsChecked = parse(
        childItem,
        level + 1,
        returnStruct,
        labelResolver,
        selectedNodes,
        selectedChildren,
        enabledNodes,
        openedNodes,
        forceCheckOnChilds,
        currentNode.isOpen && isParentOpen
      )

      if (hasChildsChecked) {
        currentNode.hasChildsChecked = true
      }
    })
  } else {
    currentNode.title = labelResolver(item.id, 'en').shortText
  }

  return (
    currentNode.hasChildsChecked ||
    currentNode.isNodeChecked ||
    currentNode.isChildrenChecked
  )
}
