import React from 'react'
import distinctColors from 'distinct-colors'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

const palette = distinctColors({ count: 100 })

const MultiBarChart = (props) => {
  let max = 0
  let min = 0

  props.data.data.forEach((item) =>
    Object.entries(item).forEach(([key, value]) => {
      if (key === 'name') return
      if (Number(value) > max) max = Number(value)
      if (Number(value) < min) min = Number(value)
    })
  )

  return (
    <div style={{ width: '100%', height: props.height }}>
      <ResponsiveContainer>
        <BarChart
          data={props.data.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: '0.8em' }} />
          <YAxis
            type="number"
            domain={[min, max]}
            tick={{ fontSize: '0.8em' }}
          />
          <Tooltip />
          {props.data.keys.map((key, i) => (
            <Bar key={key} dataKey={key} fill={palette[i % 100].hex()} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

MultiBarChart.defaultProps = {
  id: 'chart',
  height: 600,
  useInteractiveGuideline: true,
  showLegend: false,
  data: [],
}

export default MultiBarChart
