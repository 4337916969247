// add  page column

import React from 'react'
import { Table } from 'react-bootstrap'

import CenteredSpinner from 'components/widgets/centeredSpinner'
import ListItem from './ListItem'
import ListHeader from './ListHeader'

export class DashboardList extends React.Component {
  componentWillMount() {
    this.props.fetchListData()
  }

  handleRowButtonClick(id, event) {
    event.preventDefault()

    this.props.navigateToDashboardsShow(id)
  }

  render() {
    let content

    const loadingRowStyle = {
      textAlign: 'center',
      verticalAlign: 'middle',
    }

    if (this.props.isFetching) {
      content = (
        <tr>
          <td colSpan="4" style={loadingRowStyle}>
            <CenteredSpinner show={true} />
          </td>
        </tr>
      )
    } else {
      if (this.props.dashboards.count()) {
        content = this.props.dashboards.map((item, i) => {
          const id = item.get('id')

          return (
            <ListItem
              key={i}
              item={item.toJS()}
              onRowClick={this.handleRowButtonClick.bind(this, id)}
            />
          )
        })
      } else {
        content = (
          <tr>
            <td colSpan="4" style={loadingRowStyle}>
              Sorry, no dashboard available
            </td>
          </tr>
        )
      }
    }

    return (
      <Table hover>
        <thead>
          <ListHeader />
        </thead>
        <tbody>{content}</tbody>
      </Table>
    )
  }
}

export default DashboardList
