import './saveAs.polyfill'

function s2ab(s) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

/**
 * Presents a file as file download.
 */
export function downloadFileInBrowser(text, name, type) {
  const file = new Blob([s2ab(text)], { type })

  window.saveAs(file, name)
}
