import { connect } from 'react-redux'

import { getInformationItemById } from 'modules/masterdata/helpers'

import {
  showPanelAction,
  closeAndSaveAction,
  closeAction,
  changeRangeAction,
  showRangePanelAction,
} from '../../actions/localFilters'
import _component from './component'

function mapStateToProps(state, props) {
  const informationItem = getInformationItemById(props.chartId)

  return {
    enabledCardFilters: informationItem ? informationItem.localFilters : [],
    globalFilters: state.dashboard.currentGrid.get('filters'),
    hierarchy: state.filters.hierarchy,
    filtersStruct: state.masterdata.get('filters'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showPanel: (idCard, idPanel) => dispatch(showPanelAction(idCard, idPanel)),
    showRangePanel: (idCard) => dispatch(showRangePanelAction(idCard)),
    closeAndSave: (idCard, idPanel, type) =>
      dispatch(closeAndSaveAction(idCard, idPanel, type)),
    close: (idCard) => dispatch(closeAction(idCard)),
    changeRange: (idCard, range) => dispatch(changeRangeAction(idCard, range)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
