import React from 'react'
import registry from 'services/registry'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { serverConst } from 'services/server'

import { DashboardShow } from 'modules/dashboard/components'
import { ArrowLeftIcon } from '@primer/octicons-react'
import Resizer from '../../widgets/resizer'

function mapStateToProps(state) {
  return {
    dashboardStore: state.dashboard,
    resizerWidth: state.app.getIn(['resizer', 'mainContainer']),
  }
}

function mapDispatchToProps() {
  return {}
}

const DashboardShowPage = (props) => {
  const { id } = useParams()
  if (serverConst.deviceType === 'phone') {
    const size = registry.get('helpers/metrics/getWindowSize')()
    const componentWidth = size.width - 30

    return (
      <Resizer resizeKey="mainContainer">
        <div style={{ width: componentWidth + 'px', margin: '0 auto' }}>
          <Link to="/dashboards">
            <Button variant="link" size="sm">
              <ArrowLeftIcon />
              Dashboard list
            </Button>
          </Link>
          <DashboardShow id={id} totalWidth={componentWidth} />
        </div>
      </Resizer>
    )
  }
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Link to="/dashboards">
            <Button variant="link" size="sm">
              <ArrowLeftIcon />
              Dashboard list
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Resizer>
            <DashboardShow id={id} />
          </Resizer>
        </Col>
      </Row>
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardShowPage)
