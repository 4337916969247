import React from 'react'

import { ResponsiveContainer } from 'recharts'
import { Treemap } from 'recharts'

const TreemapChart = (props) => {
  const data = props.data.data.map((item) => {
    return {
      name: item.name,
      size: Number(item['amount']),
    }
  })
  return (
    <div style={{ width: '100%', height: props.height }}>
      <ResponsiveContainer>
        <Treemap
          // width={props.height * 1.5}
          // height={props.height}
          data={data}
          dataKey="size"
          ratio={4 / 3}
          stroke="#fff"
          fill="#8884d8"
          isAnimationActive={false}
        />
      </ResponsiveContainer>
    </div>
  )
}

TreemapChart.defaultProps = {
  id: 'chart',
  height: 600,
  useInteractiveGuideline: true,
  showLegend: false,
  data: [],
}

export default TreemapChart
