const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]

export function getMonth(start, stop) {
  const count = (stop - start + 1) * 12
  const returnStruct = []
  for (let i = 0; i < count; i++) {
    returnStruct[i] = {
      key: i,
      value: `${start + Math.floor(i / 12)}${i % 12}`,
      label: i % 12 ? '' : `${start + Math.floor(i / 12)}`,
      tooltip: `${months[i % 12]} ${start + Math.floor(i / 12)}`,
    }
  }
  return returnStruct
}

export function getMonthKeyFromValue(struct, value) {
  const found = struct.find(
    (item) => item.value === value || item.value === value + '01'
  )
  return found ? found.key : -1
}

export function getMonthValueFromKey(struct, key) {
  const found = struct.find((item) => item.key === key)
  return found ? found.value : -1
}
