import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { DownloadIcon } from '@primer/octicons-react'

export class DropDownDownload extends React.Component {
  handleViewChangeClick(type, id, event) {
    this.props.onChangeClick(type, event)
  }

  render() {
    if (!this.props.show) {
      return false
    }
    const downloadItems = []
    downloadItems.push(
      <Dropdown.Item key="1" eventKey="1" onSelect={this.props.onDownloadExcel}>
        Excel
      </Dropdown.Item>
    )

    return (
      <Dropdown id="download" size="sm">
        <Dropdown.Toggle>
          <DownloadIcon /> Download
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          {downloadItems.filter((item) => item !== null)}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default DropDownDownload
