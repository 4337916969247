export function getQuarter(start, stop) {
  const count = (stop - start + 1) * 4
  const returnStruct = []
  for (let i = 0; i < count; i++) {
    returnStruct[i] = {
      key: i,
      value: `${start + Math.floor(i / 4)}Q${(i % 4) + 1}`,
      label: i % 4 ? '' : `${start + Math.floor(i / 4)}`,
      tooltip: `${start + Math.floor(i / 4)} Q${(i % 4) + 1}`,
    }
  }
  return returnStruct
}

export function getQuarterKeyFromValue(struct, value) {
  const found = struct.find(
    (item) => item.value === value || item.value === value + 'Q1'
  )
  return found ? found.key : -1
}

export function getQuarterValueFromKey(struct, key) {
  const found = struct.find((item) => item.key === key)
  return found ? found.value : -1
}
