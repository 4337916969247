import Immutable from 'immutable'
import DefaultGlobalFilter from '../../helpers/createFilters/defaultGlobalFilters'

import { getGridWidth } from '../../helpers'
import { getWindowSize } from '../../../../services/metrics'
import incrementNextCardId from './incrementNextCardId'
import resizeGrid from './resizeGrid'
import refreshGrid from './refreshGrid'
import goToEdit from './goToEdit'
import goToView from './goToView'

import setCardPosition from './setCardPosition'
import setCardPositionSuccess from './setCardPositionSuccess'
import setCardPositionFailed from './setCardPositionFailed'

import setCardSize from './setCardSize'
import setCardSizeSuccess from './setCardSizeSuccess'
import setCardSizeFailed from './setCardSizeFailed'

import setCardText from './setCardText'
import setCardTextSuccess from './setCardTextSuccess'
import setCardTextFailed from './setCardTextFailed'

import setCardTitle from './setCardTitle'
import setCardTitleSuccess from './setCardTitleSuccess'
import setCardTitleFailed from './setCardTitleFailed'

import removeCard from './removeCard'
import removeCardSuccess from './removeCardSuccess'
import removeCardFailed from './removeCardFailed'
import recalcUsedCells from './recalcUsedCells'
import addCardRequest from './addCardRequest'
import addCardCancel from './addCardCancel'
import addCard from './addCard'
import addCardSuccess from './addCardSuccess'
import addCardFailed from './addCardFailed'
import addTextCardSuccess from './addTextCardSuccess'

import fetchCards from './fetchCards'
import fetchCardsSuccess from './fetchCardsSuccess'
import fetchCardsFailed from './fetchCardsFailed'

import refreshAllCards from './refreshAllCards'
import refreshCard from './refreshCard'
import refreshCardSuccess from './refreshCardSuccess'
import refreshTextCardSuccess from './refreshTextCardSuccess'
import refreshCardFailed from './refreshCardFailed'

/** Filters */
import updateFilters from './updateFilters'
import updateRangeFilters from './updateRangeFilters'
import updateCardFilters from './updateCardFilters'
import updateCardRangeFilters from './updateCardRangeFilters'
import setCardFilterPopup from './setCardFilterPopup'

const defaultRows = 6
const defaultCols = 6

function getDefaultState() {
  return Immutable.Map({
    prog: 0,

    isOnEdit: false,

    isInit: false,
    isFetchingCards: false,
    isUpdating: false,
    currentContainerWidth: getWindowSize().width,
    grid: Immutable.Map({
      rows: defaultRows,
      cols: defaultCols,
      width: getGridWidth(getWindowSize().width, defaultCols),
      height:
        Math.round(getGridWidth(getWindowSize().width) / defaultCols) *
        defaultRows,
    }),

    nextCardId: 1,

    usedRows: 0,
    usedCells: Immutable.Set(),

    addToRow: -1,
    addToCol: -1,
    isAddingCard: false,

    filters: DefaultGlobalFilter,

    cards: Immutable.List([]),
  })
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case 'DASHBOARDS:CURRENT_GRID:RESIZE_GRID':
      return resizeGrid(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REFRESH_GRID':
      return refreshGrid(state, action)

    case 'DASHBOARDS:CURRENT_GRID:GO_TO_EDIT':
      return goToEdit(state, action)

    case 'DASHBOARDS:CURRENT_GRID:GO_TO_VIEW':
      return goToView(state, action)

    case 'DASHBOARDS:CURRENT_GRID:INCREMENT_NEXT_CARD_ID':
      return incrementNextCardId(state)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_POSITION':
      return setCardPosition(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_POSITION:SUCCESS':
      return setCardPositionSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_POSITION:FAIL':
      return setCardPositionFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_SIZE':
      return setCardSize(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_SIZE:SUCCESS':
      return setCardSizeSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_SIZE:FAIL':
      return setCardSizeFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_TEXT':
      return setCardText(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_TEXT:SUCCESS':
      return setCardTextSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_TEXT:FAIL':
      return setCardTextFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_TITLE':
      return setCardTitle(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_TITLE:SUCCESS':
      return setCardTitleSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_TITLE:FAIL':
      return setCardTitleFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REMOVE_CARD':
      return removeCard(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REMOVE_CARD:SUCCESS':
      return removeCardSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REMOVE_CARD:FAIL':
      return removeCardFailed(state, action)

    case 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS':
      return recalcUsedCells(state)

    case 'DASHBOARDS:CURRENT_GRID:ADD_CARD_REQUEST':
      return addCardRequest(state, action)

    case 'DASHBOARDS:CURRENT_GRID:ADD_CARD_CANCEL':
      return addCardCancel(state)

    case 'DASHBOARDS:CURRENT_GRID:ADD_CARD':
      return addCard(state, action)

    case 'DASHBOARDS:CURRENT_GRID:ADD_CARD:SUCCESS':
      return addCardSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:ADD_TEXT_CARD:SUCCESS':
      return addTextCardSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:ADD_CARD:FAIL':
      return addCardFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REFRESH_ALL_CARDS':
      return refreshAllCards(state)

    case 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD':
      return refreshCard(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD:SUCCESS':
      return refreshCardSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REFRESH_TEXT_CARD:SUCCESS':
      return refreshTextCardSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD:FAIL':
      return refreshCardFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS':
      return fetchCards(state, action)

    case 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS:SUCCESS':
      return fetchCardsSuccess(state, action)

    case 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS:FAIL':
      return fetchCardsFailed(state, action)

    case 'DASHBOARDS:CURRENT_GRID:UPDATE_FILTERS':
      return updateFilters(state, action)

    case 'DASHBOARDS:CURRENT_GRID:UPDATE_RANGE_FILTERS':
      return updateRangeFilters(state, action)

    case 'DASHBOARDS:CURRENT_GRID:UPDATE_CARD_FILTERS':
      return updateCardFilters(state, action)

    case 'DASHBOARDS:CURRENT_GRID:UPDATE_CARD_RANGE_FILTERS':
      return updateCardRangeFilters(state, action)

    case 'DASHBOARDS:CURRENT_GRID:SET_CARD_FILTER_POPUP':
      return setCardFilterPopup(state, action)

    default:
      return state
  }
}
