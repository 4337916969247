import Immutable from 'immutable'
import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Gets called when the card range filter is updated.
 */
export default (state, action) => {
  const cardFound = getCardAndIndexFromCardList(
    state.get('cards'),
    action.cardId
  )
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state.setIn(
    ['cards', index],
    card.setIn(['filters', 'range'], Immutable.Map(action.filters))
  )
}
