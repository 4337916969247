/**
 * Called when the filters are updated.
 */
export default (state, action) => {
  const ret = state
    .setIn(
      ['filters', action.name, 'selectedNodes'],
      action.filters.get('selectedNodes')
    )
    .setIn(
      ['filters', action.name, 'selectedChildren'],
      action.filters.get('selectedChildren')
    )
  return ret
}
