/**
 * Updates the range filter on the facts page.
 */
export function changeRangeAction(chartId, range) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:FACT:UPDATE_RANGE_FILTERS',
      range,
    })
  }
}
