import userReducer from 'modules/user/reducers'
import dashboardReducer from 'modules/dashboard/reducers'
import masterdataReducer from 'modules/masterdata/reducers'
import appReducer from './reducers/appReducers'
import filtersReducer from './reducers/filters'
import textEditorReducer from './reducers/textEditor'

export default {
  app: appReducer,
  dashboard: dashboardReducer,
  filters: filtersReducer,
  textEditor: textEditorReducer,
  masterdata: masterdataReducer,
  user: userReducer,
}
