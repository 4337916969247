import React from 'react'
import { ListGroup, Form } from 'react-bootstrap'
import registry from 'services/registry'
import CenteredSpinner from 'components/widgets/centeredSpinner'
import InformationItem from './../informationItem'
import { getFlatStruct } from './getFlatStruct'

export class InformationItems extends React.Component {
  constructor(options) {
    super(options)
    this.state = {
      searchQuery: '',
    }
  }

  handleSearchQueryChange(event) {
    event.preventDefault()
    const searchQuery = this.refs.search.value
    this.setState({ searchQuery })
  }

  render() {
    const isFetching = this.props.isFetching

    if (isFetching) {
      return <CenteredSpinner show={true} />
    }

    const getLabel = registry.get('helpers/masterdata/getLabel')

    let result = []
    this.props.pages.forEach((page) => {
      result = [
        ...result,
        ...getFlatStruct(
          page.key,
          page.infitems,
          getLabel,
          this.props.itemOpened,
          0,
          this.state.searchQuery
        ),
      ]
    })

    let listGroup = false
    if (this.props.isInit) {
      listGroup = (
        <div>
          <h3 className="page-title">Fact list</h3>
          <Form.Control
            addonBefore="Search"
            value={this.state.searchQuery}
            ref="search"
            type="text"
            placeholder="Start to write here the search query"
            disabled={this.props.isSaving}
            onChange={this.handleSearchQueryChange.bind(this)}
          />
          <ListGroup as="ul">
            {result.map((item) => {
              return (
                <InformationItem
                  item={item}
                  handleItemClick={this.props.handleItemClick}
                  handleChartClick={this.props.handleChartClick}
                />
              )
            })}
          </ListGroup>
        </div>
      )
    }

    return <div>{listGroup}</div>
  }
}

export default InformationItems
