// import { put } from 'redux-saga/effects'
// import { routeActions } from 'react-router-redux'
import { takeEvery } from 'redux-saga'

import { store } from 'services/store'
import { resizeGridSaga } from './resizeGrid'
import { shareFactSaga } from './shareFact'
import { loadSharedFact } from './loadSharedFact'

function* dashboardsNotFoundRedirectSaga() {
  yield* takeEvery('DASHBOARDS:FETCH:FAIL', function* () {
    // yield put(routeActions.push('/dashboards-not-found', {}))
    yield store.history.push('/dashboards-not-found')
  })
}

function* factsNotFoundRedirectSaga() {
  yield* takeEvery('DASHBOARD:FACT:FETCH:FAIL', function* () {
    // yield put(routeActions.push('/facts-not-found', {}))
    yield store.history.push('/facts-not-found')
  })
}

export default [
  resizeGridSaga,
  shareFactSaga,
  loadSharedFact,
  dashboardsNotFoundRedirectSaga,
  factsNotFoundRedirectSaga,
]
