import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Gets called when the card filters are updated.
 */
export default (state, action) => {
  const cardFound = getCardAndIndexFromCardList(
    state.get('cards'),
    action.cardId
  )
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state.setIn(
    ['cards', index],
    card.setIn(
      ['filters', action.name, 'selectedNodes'],
      action.filters.get('selectedNodes')
    )
  )
}
