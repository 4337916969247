/**
 * Closes the filter popup on the card page.
 */
export function closeAction(cardId) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_FILTER_POPUP',
      cardId,
      show: null,
    })
  }
}
