/**
 * Opens a modal for editing the text of a text card.
 */
export function editDetailsOpenAction(dashboard) {
  return (dispatch) => {
    dispatch({
      type: 'TEXT_EDITOR:INIT',
      content: { title: dashboard.title, description: dashboard.description },
      id: dashboard.id,
    })
    dispatch({
      type: 'TEXT_EDITOR:OPEN',
      popUpType: 'dashboardDetails',
    })
    return
  }
}
