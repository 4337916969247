import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import ResizableBox from './react-resizable/ResizableBox'

export default class ResizableAndMovable extends Component {
  constructor(props) {
    super(props)
    this.state = { isDraggable: true }
    this.isResizing = false
    this.lastPositionLeft = null
    this.lastPositionTop = null
    this.lastWidth = null
    this.lastHeight = null
  }

  componentDidUpdate() {
    this.updatePosition(this.props.positionX, this.props.positionY)
    this.updateSize(this.props.width, this.props.height)
  }

  onResizeStart(e) {
    if (e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.returnValue = false
    }
    this.setState({ isDraggable: false })
    this.isResizing = true
    this.props.onResizeStart(e)
  }

  onResize(e, ui) {
    if (e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.returnValue = false
    }
    this.lastWidth = ui.size.width
    this.lastHeight = ui.size.height
    this.props.onResize(e)
  }

  onResizeStop(e, ui) {
    if (e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.returnValue = false
    }
    this.setState({ isDraggable: true })

    if (isNaN(ui.size.width)) {
      ui.size.width = this.lastWidth
    }
    if (isNaN(ui.size.height)) {
      ui.size.height = this.lastHeight
    }
    this.isResizing = false
    this.props.onResizeStop(e, ui)
  }

  onDragStart(e, ui) {
    if (e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.returnValue = false
    }

    if (this.isResizing) {
      return
    }
    this.props.onDragStart(e, ui)
  }

  onDrag(e, ui) {
    this.lastPositionLeft = ui.x
    this.lastPositionTop = ui.y
    // this.lastPositionLeft = ui.position.left;
    // this.lastPositionTop = ui.position.top;
    if (e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.returnValue = false
    }

    if (this.isResizing) {
      return
    }
    this.props.onDrag(e, ui)
  }

  onDragStop(e, ui) {
    if (e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      e.returnValue = false
    }

    if (this.isResizing) {
      return
    }

    if (isNaN(ui.x)) {
      ui.x = this.lastPositionLeft
    }
    if (isNaN(ui.y)) {
      ui.y = this.lastPositionTop
    }
    this.props.onDragStop(e, ui)
  }

  updatePosition(x, y) {
    this.refs.draggableComponent.setState({ clientX: x, clientY: y })
  }

  updateSize(w, h) {
    this.refs.resizableComponent.setState({ width: w, height: h })
  }

  render() {
    const {
      handleMove,
      grid,
      bounds,
      start,
      positionX,
      positionY,
      width,
      height,
      zIndex,
    } = this.props

    const containerStyle = {
      width: `${start.width}px`,
      height: `${start.height}px`,
      position: 'absolute',
    }

    return (
      <Draggable
        ref="draggableComponent"
        axis="both"
        zIndex={zIndex}
        bounds={bounds}
        defaultPosition={{ x: positionX, y: positionY }}
        position={{ x: positionX, y: positionY }}
        disabled={!this.state.isDraggable}
        onStart={this.onDragStart.bind(this)}
        onDrag={this.onDrag.bind(this)}
        onStop={this.onDragStop.bind(this)}
        handle={handleMove}
        grid={grid}
      >
        <div style={containerStyle}>
          <ResizableBox
            ref="resizableComponent"
            onResizeStart={this.onResizeStart.bind(this)}
            onResize={this.onResize.bind(this)}
            onResizeStop={this.onResizeStop.bind(this)}
            width={width}
            height={height}
          >
            {this.props.children}
          </ResizableBox>
        </div>
      </Draggable>
    )
  }
}

ResizableAndMovable.propTypes = {
  onClick: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
  zIndex: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

ResizableAndMovable.defaultProps = {
  width: 100,
  height: 100,
  x: 0,
  y: 0,
  start: { x: 0, y: 0 },
  zIndex: 100,
  onClick: () => {},
  onTouchStartP: () => {},
  onDragStart: () => {},
  onDrag: () => {},
  onDragStop: () => {},
  onResizeStart: () => {},
  onResize: () => {},
  onResizeStop: () => {},
}
