/**
 * Sets the stops for the range component.
 */
export function filtersRangeStop(params, rangeElement) {
  const rangeFilters = params.filter(
    (item) => item.type === 'FILTER' && item.value1 === 'max'
  )
  const searchList = ['year']
  let filterComponent

  if (rangeElement.toLowerCase() === 'quarter') {
    searchList.unshift('quarter')
  }

  if (rangeElement.toLowerCase() === 'month') {
    searchList.unshift('month')
  }

  while (searchList.length > 0 && !filterComponent) {
    const searchFor = searchList.shift()

    filterComponent = rangeFilters.find(
      (item) => item.code.toLowerCase() === searchFor
    )
  }

  if (!filterComponent) {
    return 2025
  }

  return Number(filterComponent.value2)
}
