import React from 'react'
import { Jumbotron, Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { store } from '../../store'

export const _HomePage = (props) => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Jumbotron>
              <h1>Leverix</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    dashboardStore: state.dashboard,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    navigateToDashboardsNew: () => store.history.push(`/profile`),
  }
}

export const HomePage = connect(mapStateToProps, mapDispatchToProps)(_HomePage)
