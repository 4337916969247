export default {
  dev: {
    logActions: true,
    history: true,
    devTools: false,
  },
  login: {
    returnURL: '/profile',
    cancelReturnURL: '/',
  },
  baseUrl: 'https://dwq874sk22meo.cloudfront.net/',
  server: {
    url: '/api/v1/',
  },
}
