import { getChildIdList } from 'modules/masterdata/helpers'
import { store } from '../../../services/store'

/**
 * Toggles an item in the filters.
 */
export function toggleItemAction(type, itemId) {
  const startItem = store
    .getState()
    .masterdata.getIn(['hierarchiesFlat', type])
    .find((item) => itemId === item.id)

  return (dispatch) => {
    dispatch({
      type: 'FILTERS:HIERARCHY:ITEM:TOGGLE',
      itemId,
      listType: type,
      children: getChildIdList(startItem),
    })
  }
}
