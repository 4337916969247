import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Called when the title of a card is set.
 */
export default (state, action) => {
  const cardFound = getCardAndIndexFromCardList(
    state.get('cards'),
    action.cardId
  )
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state
    .setIn(['cards', index], card.set('isUpdating', true))
    .update('prog', (value) => value + 1)
    .set('isUpdating', true)
}
