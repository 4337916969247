import { put } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga'

import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * When a fact is shared, opens the share modal.
 */
export function* shareFactSaga() {
  yield* takeEvery('DASHBOARDS:FACT:SHARE:START', function* (action) {
    try {
      const user = store.getState().user
      const token = user.get('loggedInUser').get('token')

      const url = `${config.server.url}fact/publish?auth_token=${token}`

      const publishData = {
        chartId: action.chartId,
        filters: store.getState().dashboard.fact.get('filters').toJS(),
      }

      const result = yield request.post(url, publishData, {
        responseType: 'json',
        dataType: 'json',
      })
      yield put({
        type: 'DASHBOARDS:FACT:SHARE:OPEN',
        shareKey: result.response.hash,
      })
    } catch (e) {
      yield put({
        type: 'DASHBOARDS:FACT:SHARE:FAIL',
        error: e,
      })
    }
  })
}
