import Immutable from 'immutable'

const defaultState = Immutable.Map({
  selectedNodes: Immutable.Set(),
  selectedChildren: Immutable.Set(),
  geoloc: Immutable.Set(),
  constrd: Immutable.Set(),
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'FILTERS:HIERARCHY:INIT':
      return state
        .set('selectedNodes', action.selectedNodes)
        .set('selectedChildren', action.selectedChildren)

    case 'FILTERS:HIERARCHY:ITEM:TOGGLE':
      const currentList = state.get(action.listType)
      if (currentList.has(action.itemId)) {
        return state.set(
          action.listType,
          currentList.delete(action.itemId).subtract(action.children)
        )
      }
      return state.set(action.listType, currentList.add(action.itemId))

    case 'FILTERS:HIERARCHY:NODES:TOGGLE':
      if (state.get('selectedNodes').has(action.itemId)) {
        return state.set(
          'selectedNodes',
          state.get('selectedNodes').remove(action.itemId)
        )
      }
      return state.set(
        'selectedNodes',
        state.get('selectedNodes').add(action.itemId)
      )

    case 'FILTERS:HIERARCHY:CHILDREN:TOGGLE':
      if (action.side === 'right') {
        if (state.get('selectedNodes').has(action.itemId)) {
          return state.set(
            'selectedNodes',
            state.get('selectedNodes').subtract(action.children)
          )
        }
        return state.set(
          'selectedNodes',
          state
            .get('selectedNodes')
            .subtract(action.children)
            .add(action.itemId)
        )
      }
      if (state.get('selectedChildren').has(action.itemId)) {
        return state.set(
          'selectedChildren',
          state.get('selectedChildren').subtract(action.children)
        )
      }
      return state.set(
        'selectedChildren',
        state
          .get('selectedChildren')
          .subtract(action.children)
          .add(action.itemId)
      )

    default:
      return state
  }
}
