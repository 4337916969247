function parse(item, returnStruct) {
  returnStruct.push(item)
  if (item.childs && item.childs.length) {
    item.childs.forEach((childItem) => {
      parse(childItem, returnStruct)
    })
  }
}

/**
 * Turns te list of items in a flat structure.
 */
export function getFlatHierarchy(itemList) {
  const returnStruct = []
  parse(itemList, returnStruct)
  return returnStruct
}
