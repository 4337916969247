import Immutable from 'immutable'

/**
 * Gets called when the dashboard fetch was successful.
 */
export default (state, action) => {
  return state
    .set('isFetching', false)
    .set('isFetched', true)
    .set('dashboard', Immutable.fromJS(action.dashboard))
}
