import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Called when a card's size set has been requested.
 */
export default (state, action) => {
  const cardFound = getCardAndIndexFromCardList(
    state.get('cards'),
    action.cardId
  )
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state
    .setIn(
      ['cards', index],
      card.set('spanX', action.spanX).set('spanY', action.spanY)
    )
    .update('prog', (value) => value + 1)
    .set('isUpdating', true)
}
