import { store } from 'services/store'

import { getChartById } from 'modules/masterdata/helpers'
import {
  getDataForXlsx,
  getSheetForXslx,
  getWorkbookForXslx,
  downloadFileInBrowser,
} from '../../helpers'

function getSheetName(replacedTitle, prog, index) {
  return (
    `${replacedTitle}_${prog++}`.substr(0, 26) +
    `_${String(index + 1).padStart(3, '0')}`
  )
}

function getData() {
  let prog = 0
  const wb = getWorkbookForXslx()
  store
    .getState()
    .dashboard.currentGrid.get('cards')
    .toJS()
    .filter((card) => card.content.type !== 'TextCard')
    .forEach((card, i) => {
      const data = getDataForXlsx(card.content.data)
      const chart = getChartById(card.content.chartId)
      const title = chart ? chart.longText.substr(0, 31) : ''
      const replacedTitle = title
        .replace(/[\s-]+/g, '_')
        .replace(/[^a-zA-Z0-9_]+/g, '')
      const sheetName = getSheetName(replacedTitle, prog, i)
      wb.SheetNames.push(sheetName)
      wb.Sheets[sheetName] = getSheetForXslx([[title], [], ...data])
    })

  if (!wb.SheetNames.length) {
    wb.SheetNames.push('No data')
  }
  return wb
}

/**
 * Cancel the dashboard export procedure.
 */
export function exportExcelAction() {
  return (dispatch) => {
    const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' }
    /* eslint-disable no-undef */
    if (!XLSX) {
      dispatch({
        type: 'DASHBOARDS:CURRENT_GRID:NO_XSLT_EXPORT',
      })
      return
    }
    const wbout = XLSX.write(getData(), wopts)
    /* eslint-enable no-undef */
    const title = store
      .getState()
      .dashboard.current.getIn(['dashboard', 'title'])
      .trim()
    downloadFileInBrowser(wbout, title + '.xlsx', '')

    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:NO_CALL',
    })
  }
}
