import React from 'react'
import PropTypes from 'prop-types'

import registry from 'services/registry'
import ImmutablePropTypes from 'react-immutable-proptypes'
import RangeModal from '../filters/rangeModal'

import FilterModal from '../filters/filterModal'
import getMetrics from './getMetrics'

import { ButtonHierarchyFilter } from './buttonHierarchyFilter'
import { ButtonRangeFilter } from './buttonRangeFilter'

export class CardFilters extends React.Component {
  handleOpenHierarchy(idPanel) {
    this.props.showPanel(this.props.cardId, idPanel)
  }

  handleOpenRange() {
    this.props.showRangePanel(this.props.cardId)
  }

  handleOnClose(idPanel, type) {
    this.props.closeAndSave(this.props.cardId, idPanel, type)
  }

  handleOnCancel() {
    this.props.close(this.props.cardId)
  }

  render() {
    const localHierarchyEnabledValues = registry.get(
      'helpers/dashboard/filters/localHierarchyEnabledValues'
    )
    const getFilterListForCardFilterButton = registry.get(
      'helpers/dashboard/filters/getFilterListForCardFilterButton'
    )
    const filterBox = {}
    let modal

    let countFilters = this.props.enabledCardFilters.filter((filterItem) =>
      this.props.filtersStruct.find(
        (structItem) =>
          structItem.name.toLowerCase() === filterItem.toLowerCase()
      )
    ).length
    if (
      this.props.enabledCardFilters.includes('YEAR') ||
      this.props.enabledCardFilters.includes('QUARTER') ||
      this.props.enabledCardFilters.includes('MONTH')
    ) {
      countFilters++
    }

    const metrics = getMetrics(this.props.width, countFilters)

    const buttons = []
    this.props.enabledCardFilters.forEach((filterItem) => {
      const filterStruct = this.props.filtersStruct.find(
        (structItem) =>
          structItem.name.toLowerCase() === filterItem.toLowerCase()
      )
      if (!filterStruct) return

      const list = getFilterListForCardFilterButton(
        this.props.globalFilters.get(filterStruct.name),
        this.props.localFilters.get(filterStruct.name)
      )
      buttons.push(
        <ButtonHierarchyFilter
          key={`${filterStruct.name}ButtonFilter${this.props.cardId}`}
          onClick={this.handleOpenHierarchy.bind(this, filterStruct.name)}
          icon={false}
          list={list}
          type={filterStruct.name}
          width={metrics.buttonWidth}
          defaultLabel={filterStruct.shortTitle}
        />
      )
    })

    if (this.props.enabledCardFilters.includes('YEAR')) {
      buttons.push(
        <ButtonRangeFilter
          key="rangeButtonFilter"
          onClick={this.handleOpenRange.bind(this)}
          icon={false}
          range={this.props.globalFilters.get('range')}
          width={metrics.buttonWidth}
          defaultLabel="Time selection"
        />
      )
    }

    const requiredFilter = this.props.filtersStruct.find(
      (item) => item.name === this.props.showFilterPopup
    )
    if (this.props.showFilterPopup === 'range') {
      modal = (
        <RangeModal
          onClose={this.handleOnClose.bind(this, 'range', 'range')}
          onCancel={this.handleOnCancel.bind(this)}
        />
      )
    } else if (requiredFilter) {
      let side = 'right'
      if (
        this.props.globalFilters.getIn([requiredFilter.name, 'selectedNodes'])
          .size
      ) {
        side = 'left'
      }
      modal = (
        <FilterModal
          onClose={this.handleOnClose.bind(
            this,
            this.props.showFilterPopup,
            'hierarchy'
          )}
          onCancel={this.handleOnCancel.bind(this)}
          enabledNodes={localHierarchyEnabledValues(
            this.props.globalFilters,
            this.props.showFilterPopup,
            side
          )}
          openedNodes={this.props.hierarchy.get(this.props.showFilterPopup)}
          side={side}
          type={this.props.showFilterPopup}
          title={requiredFilter.modalTitle}
          isRecursive={['recursive'].includes(requiredFilter.type)}
        />
      )
    } else {
      modal = false
    }

    const buttonContainerStyle = {
      display: 'inline-block',
      marginLeft: '5px',
      marginRight: '5px',
    }
    return (
      <div style={{ width: this.props.width + 'px' }}>
        {modal}
        <div style={filterBox} className="card-filter-box">
          {buttons.map((item, index) => {
            return (
              <div style={buttonContainerStyle} key={index}>
                {item}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

CardFilters.propTypes = {
  cardId: PropTypes.string.isRequired,
  globalFilters: ImmutablePropTypes.map.isRequired,
  localFilters: ImmutablePropTypes.map.isRequired,
  showFilterPopup: PropTypes.string,
  enabledCardFilters: PropTypes.array.isRequired,
  hierarchy: ImmutablePropTypes.map.isRequired,
  showPanel: PropTypes.func.isRequired,
  showRangePanel: PropTypes.func.isRequired,
  closeAndSave: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  filtersStruct: PropTypes.array.isRequired,
}

export default CardFilters
