import config from 'config'
import request from 'services/request'
import { store } from 'services/store'
import objectHash from 'object-hash'
import NodeCache from 'node-cache'
import { transformDataForChart } from './transformDataForChart'

const dataCache = new NodeCache({ stdTTL: 60 })

/**
 * Fetches (filtered) chart data by id.
 */
export function fetchChartData(chartId, filters) {
  const state = store.getState()
  const user = state.user
  const token = user.get('loggedInUser').get('token')
  const encodedFilters = encodeURIComponent(JSON.stringify(filters))
  const url = `${config.server.url}masterdata/query?auth_token=${token}&chartId=${chartId}&filters=${encodedFilters}`

  const hash = objectHash({ chartId, filters }, { unorderedArrays: true })

  const cachedData = dataCache.get(hash)
  if (cachedData) {
    return Promise.resolve(cachedData)
  }

  return new Promise((resolve, reject) => {
    request
      .get(url, {}, { responseType: 'json' })
      .then((result) => {
        const transformedData = transformDataForChart(
          chartId,
          result.response.data,
          'en'
        )
        dataCache.set(hash, transformedData)
        resolve(transformedData)
      })
      .catch(reject)
  })
}
