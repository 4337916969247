import { connect } from 'react-redux'

import { initMasterdataAction } from 'modules/masterdata/actions'

import _component from './component'

function mapStateToProps(state) {
  return {
    isFetching: state.masterdata.get('isFetching'),
    isInit: state.masterdata.get('isInit'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    initData: () => dispatch(initMasterdataAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
