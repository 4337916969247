import React from 'react'
import PropTypes from 'prop-types'
import Resizable from './Resizable'

// An example use of Resizable.
export default class ResizableBox extends React.Component {
  constructor(options) {
    super(options)
    this.state = {
      width: this.props.width,
      height: this.props.height,
    }
  }

  onResize(event, ui) {
    const { element, size } = ui
    this.setState(size, () => {
      /* eslint-disable no-unused-expressions */
      this.props.onResize && this.props.onResize(event, { element, size })
    })
  }

  render() {
    // Basic wrapper around a Resizable instance.
    // If you use Resizable directly, you are responsible for updating the child component
    // with a new width and height.
    const {
      handleSize,
      onResizeStart,
      onResizeStop,
      draggableOpts,
      minConstraints,
      maxConstraints,
      lockAspectRatio,
      ...props
    } = this.props
    return (
      <Resizable
        handleSize={handleSize}
        width={this.state.width}
        height={this.state.height}
        onResizeStart={onResizeStart}
        onResize={this.onResize.bind(this)}
        onResizeStop={onResizeStop}
        draggableOpts={draggableOpts}
        minConstraints={minConstraints}
        maxConstraints={maxConstraints}
        lockAspectRatio={lockAspectRatio}
      >
        <div
          style={{
            width: this.state.width + 'px',
            height: this.state.height + 'px',
          }}
          {...props}
        />
      </Resizable>
    )
  }
}

ResizableBox.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

ResizableBox.defaultProps = {
  handleSize: [20, 20],
}
