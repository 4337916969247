import registry from 'services/registry'

import { getLabel } from './getLabel'
import { filtersRangeStart, filtersRangeStop } from './filters'

export { fetchChartData } from './fetchChartData'
export { getChartById } from './getChartById'
export { getChildIdList } from './getChildIdList'
export { getFlatHierarchy } from './getFlatHierarchy'
export { getInformationItemsByKey } from './getInformationItemsByKey'
export { getInformationItemById } from './getInformationItemById'
export { getLabelsFromList } from './getLabelsFromList'
export { getNodeLabelsFromTree } from './getNodeLabelsFromTree'
export { getPageById } from './getPageById'
export { transformDataForChart } from './transformDataForChart'

registry.register('helpers/masterdata/getLabel', getLabel)
registry.register(
  'helpers/masterdata/filters/filtersRangeStart',
  filtersRangeStart
)
registry.register(
  'helpers/masterdata/filters/filtersRangeStop',
  filtersRangeStop
)
