import React from 'react'
import { ChevronRightIcon } from '@primer/octicons-react'
import { getChartById, getInformationItemsByKey } from '../../helpers'
import Chart from './chart'

export default class InformationItem extends React.Component {
  render() {
    if (!this.props.item.isParentOpen) {
      return false
    }
    const currLevel = parseInt(this.props.item.level, 10)
    const paddingLeft = (currLevel - 1) * 20 + 10

    const triangleStyle = {
      display: 'inline-block',
      transition: 'all 0.25s ease-out',
      marginRight: '10px',
    }

    if (this.props.item.isOpen) {
      triangleStyle.transition = 'all 0.25s ease-in'
      triangleStyle.transform = 'rotate(90deg)'
    }

    let child = false
    if (!this.props.item.hasChild && this.props.item.isOpen) {
      const charts = getInformationItemsByKey(this.props.item.id).map(
        (item) => {
          return Object.assign({}, getChartById(item.chartId), {
            chartType: item.chartType,
          })
        }
      )

      child = (
        <div style={{ marginTop: '10px' }}>
          {charts.map((item) => {
            return (
              <Chart
                key={`chart_${item.id}`}
                handleChartClick={this.props.handleChartClick}
                item={item}
              />
            )
          })}
        </div>
      )
    }

    const rowStyle = {
      paddingLeft: `${paddingLeft}px`,
      cursor: 'pointer',
    }

    if (this.props.item.level === 1) {
      rowStyle.backgroundColor = '#f8f5f0'
    }
    return (
      <li
        className="list-group-item"
        onClick={this.props.handleItemClick.bind(
          this,
          'infitem',
          this.props.item.toggleId
        )}
        style={rowStyle}
      >
        <div style={triangleStyle}>
          <ChevronRightIcon />
        </div>
        {this.props.item.title}
        {child}
      </li>
    )
  }
}
