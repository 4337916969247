const minFilterWidth = 100
const maxFilterWidth = 200
const minRangeFilterWidth = 450
const buttonPadding = 5

function getMetrics(containerWidth, filtersCount, hasRangeFilter) {
  let rangeWidth = minRangeFilterWidth
  let buttonWidth = 0
  let hasBreak = false

  if (!hasRangeFilter) {
    buttonWidth = Math.min(
      Math.floor((containerWidth - minRangeFilterWidth) / filtersCount) -
        filtersCount * buttonPadding,
      maxFilterWidth
    )
    rangeWidth = 0
  } else if (!filtersCount) {
    rangeWidth = containerWidth
  } else if (
    minRangeFilterWidth + minFilterWidth * filtersCount >
    containerWidth
  ) {
    hasBreak = true
    rangeWidth = containerWidth - filtersCount * buttonPadding
    buttonWidth = Math.floor(containerWidth / filtersCount) - buttonPadding * 2
  } else {
    buttonWidth =
      Math.min(
        Math.floor((containerWidth - minRangeFilterWidth) / filtersCount),
        maxFilterWidth
      ) -
      buttonPadding * 2
    rangeWidth =
      containerWidth -
      buttonWidth * filtersCount -
      (filtersCount + 1) * buttonPadding * 2
  }
  return { buttonWidth, rangeWidth, hasBreak, buttonPadding }
}

export default getMetrics
