/**
 * Requests the addition of a new card to the current dashboard.
 */
export function addCardRequestAction(row, col) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:ADD_CARD_REQUEST',
      row,
      col,
    })
  }
}
