import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * Removes a dashboard.
 */
export function removeAction(id, cb, cbNoDispatch) {
  return (dispatch) => {
    dispatch({ type: 'DASHBOARDS:REMOVE_START' })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    const url = `${config.server.url}dashboard/${id}?auth_token=${token}`

    request
      .delete(url)
      .then(() => {
        dispatch({
          type: 'DASHBOARDS:REMOVE:SUCCESS',
        })

        if (cb) {
          dispatch(cb)
        }
        if (cbNoDispatch) {
          cbNoDispatch()
        }
      })

      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:REMOVE:FAIL',
          ...result.response,
        })
      })
  }
}

export default removeAction
