import Immutable from 'immutable'

/**
 * The default global filter is set to show global data.
 */
// TODO FILTERS moce this in the reducers
export const DefaultGlobalFilter = Immutable.fromJS({
  geoloc: {
    selectedNodes: Immutable.Set(),
    selectedChildren: Immutable.Set().add('WORLD'),
  },
  constrd: {
    selectedNodes: Immutable.Set(),
    selectedChildren: Immutable.Set(['NODE_1']),
  },
  hum_promo_relevant: {
    selectedNodes: Immutable.Set(),
    selectedChildren: Immutable.Set(['All']),
  },
  hum_brand_preference: {
    selectedNodes: Immutable.Set(),
    selectedChildren: Immutable.Set(['All']),
  },
  hum_color_relevant: {
    selectedNodes: Immutable.Set(),
    selectedChildren: Immutable.Set(['All']),
  },
  hum_color_preference: {
    selectedNodes: Immutable.Set(),
    selectedChildren: Immutable.Set(['All']),
  },
  hum_last_engagement_year: {
    type: 'year',
    from: 2000,
    to: 2025,
  },
  range: {
    from: 2000,
    to: 2025,
    type: 'year',
  },
})

export default DefaultGlobalFilter
