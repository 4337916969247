/**
 * TODO: Document properly.
 * I just have no clue what this is for.
 */
export function createInternalTextCardStructFromCard(card) {
  return {
    id: card.id,
    cellX: card.cellX,
    cellY: card.cellY,
    spanX: card.spanX,
    spanY: card.spanY,
    isInit: false,
    isFetching: false,
    title: '',
    content: {
      type: 'TextCard',
      text: card.customData.text,
    },
    filters: null,
    showFilterPopup: null,
  }
}
