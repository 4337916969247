import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

function formatRange(range) {
  return range.get('from') + '-' + range.get('to')
}

const filtersButtonStyle = {
  border: '1px solid #C2DEDC',
  borderRadius: '5px',
  backgroundColor: 'rgb(228, 234, 233)',
  marginBottom: '0px',
  padding: '3px',
  cursor: 'pointer',
  height: '1.2em',
}

const filtersReviewStyle = {
  marginLeft: '0px',
  display: 'inline-block',
  fontSize: '0.7em',
  verticalAlign: 'top',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '110%',
}

export class ButtonRangeFilter extends React.Component {
  handleOnClick() {
    this.props.onClick()
  }

  render() {
    if (!this.props.width) {
      return false
    }

    const reviewStyle = Object.assign({}, filtersReviewStyle, {
      width: this.props.width !== null ? this.props.width + 'px' : null,
    })

    let label = this.props.defaultLabel
    if (this.props.range) {
      label = formatRange(this.props.range)
    }

    return (
      <div style={filtersButtonStyle} onClick={this.handleOnClick.bind(this)}>
        <div style={reviewStyle}>{label}</div>
      </div>
    )
  }
}

ButtonRangeFilter.propTypes = {
  defaultLabel: PropTypes.string,
  range: ImmutablePropTypes.contains({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}
