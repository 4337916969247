import React from 'react'

import Immutable from 'immutable'
import { getFlatStruct } from './getFlatStruct'
import Item from './item'

export class Hierarchy extends React.Component {
  render() {
    let className = 'hierarchy-selector'
    if (this.props.className) {
      className += ' ' + this.props.className
    }

    let selectedNodes
    let selectedChildren
    let showLeft = false
    let showRight = false
    if (this.props.side === 'left') {
      showLeft = true
      selectedNodes = this.props.selectedNodes
    } else if (this.props.side === 'right') {
      showRight = true
      selectedChildren = this.props.selectedNodes
    } else {
      showLeft = true
      showRight = true
      selectedNodes = this.props.selectedNodes
      selectedChildren = this.props.selectedChildren
    }
    const enabledNodes = Immutable.Set(this.props.enabledNodes)

    const returnStruct = getFlatStruct(
      this.props.items,
      this.props.label,
      selectedNodes,
      selectedChildren,
      enabledNodes,
      this.props.openedNodes
    )

    return (
      <form action="">
        <table className={className}>
          <tbody>
            {returnStruct.map((item) => {
              return (
                <Item
                  key={item.id}
                  {...item}
                  onClickItemToggle={this.props.toggleItem}
                  onClickNodeToggle={this.props.toggleNode}
                  onClickChildToggle={this.props.toggleChild}
                  isRecursive={this.props.isRecursive}
                  showLeft={showLeft}
                  showRight={showRight}
                />
              )
            })}
          </tbody>
        </table>
      </form>
    )
  }
}

export default Hierarchy
