import Immutable from 'immutable'

/**
 * Gets called when the range filters are updated.
 */
export default (state, action) => {
  return state
    .setIn(['filters', 'range'], Immutable.Map(action.range))
    .setIn(['filters', action.range.id], Immutable.Map(action.range))
}
