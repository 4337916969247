import Immutable from 'immutable'

/**
 * Called when a text card has been added.
 */
export default (state, action) => {
  return state
    .set(
      'cards',
      state.get('cards').push(
        Immutable.fromJS({
          id: action.card.id,
          cellX: action.card.cellX,
          cellY: action.card.cellY,
          spanX: action.card.spanX,
          spanY: action.card.spanY,
          isInit: false,
          isFetching: false,
          title: '',
          content: {
            type: 'TextCard',
            text: '<div>Edit your text</div>',
          },
          filters: null,
          showFilterPopup: null,
        })
      )
    )
    .set('addToRow', -1)
    .set('addToCol', -1)
    .set('isAddingCard', false)
    .update('prog', (value) => value + 1)
}
