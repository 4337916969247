import { Range as RcRange } from 'rc-slider'
import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import {
  getMonth,
  getMonthValueFromKey,
  getMonthKeyFromValue,
} from './helpers/month'
import {
  getYear,
  getYearValueFromKey,
  getYearKeyFromValue,
} from './helpers/year'
import {
  getQuarter,
  getQuarterValueFromKey,
  getQuarterKeyFromValue,
} from './helpers/quarter'

function getTooltip(id) {
  return this.struct[id].tooltip
}

function reduceMarks(old, current) {
  old[current.key] = current.label
  return old
}

export class Range extends React.Component {
  constructor(options) {
    super(options)
    this.struct = []
  }

  handleOnChange(val) {
    let value1
    let value2

    switch (this.props.type) {
      case 'year':
        value1 = getYearValueFromKey(this.struct, val[0])
        value2 = getYearValueFromKey(this.struct, val[1])
        break
      case 'quarter':
        value1 = getQuarterValueFromKey(this.struct, val[0])
        value2 = getQuarterValueFromKey(this.struct, val[1])
        break
      case 'month':
        value1 = getMonthValueFromKey(this.struct, val[0])
        value2 = getMonthValueFromKey(this.struct, val[1])
        break
      default:
        value1 = val[0]
        value2 = val[1]
    }
    this.props.onChange({
      id: this.props.id,
      type: this.props.type,
      from: value1,
      to: value2,
    })
  }

  render() {
    const { type } = this.props
    let defaultValueFrom
    let defaultValueTo

    if (type === 'month') {
      this.struct = getMonth(this.props.limitStart, this.props.limitStop)
      defaultValueFrom = getMonthKeyFromValue(this.struct, this.props.from)
      defaultValueTo = getMonthKeyFromValue(this.struct, this.props.to)
    } else if (type === 'quarter') {
      this.struct = getQuarter(this.props.limitStart, this.props.limitStop)
      defaultValueFrom = getQuarterKeyFromValue(this.struct, this.props.from)
      defaultValueTo = getQuarterKeyFromValue(this.struct, this.props.to)
    } else {
      this.struct = getYear(this.props.limitStart, this.props.limitStop)
      defaultValueFrom = getYearKeyFromValue(this.struct, this.props.from)
      defaultValueTo = getYearKeyFromValue(this.struct, this.props.to)
    }

    const containerStyle = {
      display: 'inline-block',
      boxSizing: 'border-box',
      height: '50px',
      padding: '7px 20px 0',
      borderRadius: '6px',
      margin: '10px 5px 0',
      color: '#3e3f3a',
      backgroundColor: '#f8f5f0',
      border: '1px solid #dfd7ca',
    }
    if (this.props.width) {
      containerStyle.width = this.props.width + 'px'
    }

    return (
      <div style={containerStyle}>
        <RcRange
          min={0}
          max={this.struct.length - 1}
          marks={this.struct.reduce(reduceMarks, {})}
          tipFormatter={getTooltip.bind(this)}
          range
          defaultValue={[defaultValueFrom, defaultValueTo]}
          onAfterChange={this.handleOnChange.bind(this)}
        />
      </div>
    )
  }
}

Range.propTypes = {
  type: PropTypes.oneOf(['year', 'quarter', 'month']),
  onChange: PropTypes.func.isRequired,
}

export default Range
