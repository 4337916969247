import { store } from 'services/store'
import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Opens the edit for editing a text card.
 */
export function editCardAction(dashboardId, cardId) {
  return (dispatch) => {
    const state = store.getState().dashboard.currentGrid
    const cardFound = getCardAndIndexFromCardList(state.get('cards'), cardId)
    const { index, card } = cardFound
    if (index < 0) {
      dispatch({ type: 'ERROR:CARD_NOT_FOUND', cardId })
      return
    }
    if (card.getIn(['content', 'type']) === 'TextCard') {
      dispatch({
        type: 'TEXT_EDITOR:INIT',
        content: card.getIn(['content', 'text']),
        id: card.get('id'),
      })
      dispatch({
        type: 'TEXT_EDITOR:OPEN',
        popUpType: 'html',
      })
      return
    }
    dispatch({
      type: 'TEXT_EDITOR:INIT',
      content: card.get('title'),
      id: card.get('id'),
    })
    dispatch({
      type: 'TEXT_EDITOR:OPEN',
      popUpType: 'text',
    })
  }
}
