import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Spinner from 'components/widgets/spinner'
import ErrorBlock from 'components/widgets/errorBlock'

import './styles.scss'

const modules = {
  toolbar: [
    [{ size: ['small', 'normal', 'large', 'huge'] }, { align: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['link'],
  ],
}

export class HtmlTextEdit extends React.Component {
  handleOnClose() {
    this.props.onClose(this.props.id)
  }

  handleOnCancel() {
    this.props.onCancel(this.props.id)
  }

  handleTextChange(value) {
    this.props.onTextChange(value)
  }

  render() {
    if (this.props.show !== 'html') {
      return false
    }

    let errorMessage = ''
    if (this.props.errors) {
      errorMessage = this.props.errors.generic
    }

    return (
      <Modal dialogClassName="htmltextdit-panel" show={true}>
        <Modal.Header>
          <Modal.Title>Edit card content</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ReactQuill
            readOnly={this.props.isSaving}
            theme="snow"
            value={this.props.content}
            onChange={this.handleTextChange.bind(this)}
            modules={modules}
          />

          <ErrorBlock message={errorMessage} />
        </Modal.Body>

        <Modal.Footer>
          <ButtonGroup>
            <Button
              onClick={this.handleOnCancel.bind(this)}
              size="sm"
              variant="warning"
              disabled={this.props.isSaving}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleOnClose.bind(this)}
              size="sm"
              variant="primary"
              disabled={this.props.isSaving}
            >
              Update
            </Button>
          </ButtonGroup>
          <Spinner show={this.props.isSaving} />
        </Modal.Footer>
      </Modal>
    )
  }
}

HtmlTextEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    show: state.textEditor.get('isOpened'),
    content: state.textEditor.get('content'),
    errors: state.textEditor.get('errors'),
    isSaving: state.textEditor.get('isSaving'),
    id: state.textEditor.get('id'),
  }
}

export default connect(mapStateToProps, null)(HtmlTextEdit)
