function parse(item, returnList, removeStructNodes) {
  if (!item) return
  if (!(item.data.infoObject === 'NODE' && removeStructNodes)) {
    returnList.push(item.id)
  }

  if (item.childs && item.childs.length) {
    item.childs.forEach((childItem) => {
      parse(childItem, returnList, removeStructNodes)
    })
  }
}

/**
 * TODO: Document.
 */
export function getChildIdList(node, removeStructNodes) {
  const returnList = []
  parse(node, returnList, removeStructNodes)
  return returnList
}
