import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { ChevronRightIcon } from '@primer/octicons-react'

export default class Item extends React.Component {
  handleToggleItem(id) {
    this.props.onClickItemToggle(id)
  }

  handleToggleNode(id) {
    this.props.onClickNodeToggle(id)
  }

  handleToggleChild(id) {
    this.props.onClickChildToggle(id)
  }

  render() {
    const currLevel = parseInt(this.props.level, 10)
    const paddingLeft = (currLevel - 1) * 12

    const toggleElementStyle = {
      textAlign: 'right',
      width: '50px',
    }

    const textStyle = {
      overflow: 'hidden',
    }

    if (this.props.isDisabled) {
      textStyle.opacity = 0.2
    }

    let toggleNode = false
    let toggleChild = false

    if (!this.props.isParentOpen) {
      return <tr style={{ display: 'none' }} />
    }

    if (this.props.isRecursive && this.props.showLeft) {
      toggleNode = (
        <td style={toggleElementStyle}>
          <Form.Control
            type="checkbox"
            label=""
            checked={this.props.isNodeChecked}
            onChange={this.handleToggleNode.bind(this, this.props.id)}
          />
        </td>
      )
    } else {
      toggleNode = <td style={toggleElementStyle} />
    }
    if (this.props.showRight) {
      toggleChild = (
        <td style={toggleElementStyle}>
          <Form.Control
            type="checkbox"
            label=""
            checked={
              this.props.isChildrenForceChecked || this.props.isChildrenChecked
            }
            disabled={this.props.isChildrenDisabled}
            onChange={this.handleToggleChild.bind(this, this.props.id)}
          />
        </td>
      )
    }

    const triangleStyle = {
      display: 'none',
      transition: 'all 0.25s ease-out',
      marginRight: '10px',
    }

    const rowStyle = { cursor: 'pointer' }

    if (this.props.hasChild) {
      triangleStyle.display = 'inline-block'
      if (this.props.isOpen) {
        triangleStyle.transition = 'all 0.25s ease-in'
        triangleStyle.transform = 'rotate(90deg)'
      }
    }

    if (
      (!this.props.hasChild || this.props.hasChildsChecked) &&
      !this.props.isOpen
    ) {
      triangleStyle.color = 'red'
    }

    if (this.props.idDisabled) {
      textStyle.opacity = 0.4
    }
    return (
      <tr style={rowStyle}>
        <td style={{ width: '250px', paddingLeft: `${paddingLeft}px` }}>
          <div
            style={textStyle}
            onClick={this.handleToggleItem.bind(this, this.props.id)}
          >
            <div style={triangleStyle}>
              <ChevronRightIcon />
            </div>
            {this.props.title}
          </div>
        </td>
        {toggleNode}
        {toggleChild}
      </tr>
    )
  }
}

Item.propTypes = {
  id: PropTypes.any.isRequired,
  level: PropTypes.number,
  title: PropTypes.string,
  hasChild: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isChildrenDisabled: PropTypes.bool,
  isChildrenChecked: PropTypes.bool,
  isRecursive: PropTypes.bool,
  showLeft: PropTypes.bool,
  showRight: PropTypes.bool,
  onClickNodeToggle: PropTypes.func.isRequired,
  onClickChildToggle: PropTypes.func.isRequired,
  onClickItemToggle: PropTypes.func.isRequired,
}

Item.defaultProps = {
  level: 1,
  title: '',
  showLeft: false,
  showRight: false,
  isRecursive: false,
}
