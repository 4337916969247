import React from 'react'
import { Card } from 'react-bootstrap'

export default class Chart extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hover: false }
  }

  handleMouseOver() {
    this.setState({ hover: true })
  }

  handleMouseOut() {
    this.setState({ hover: false })
  }

  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.handleChartClick(this.props.item.id)
  }

  render() {
    const imageName = this.props.item.chartType.toLowerCase()

    const containerStyle = {
      display: 'inline-block',
      marginRight: '20px',
      cursor: 'pointer',
    }

    const panelStyle = {
      transition: 'all 0.4s ease',
    }

    const rate = 208 / 134
    const imageStyle = {
      height: '80px',
      width: 80 * rate + 'px',
    }

    if (this.state.hover) {
      panelStyle.border = '1px solid green'
    }
    return (
      <div
        style={containerStyle}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseOut={this.handleMouseOut.bind(this)}
        onClick={this.handleClick.bind(this)}
      >
        <Card style={panelStyle}>
          <img
            src={`/images/charts/${imageName}.png`}
            alt=""
            style={imageStyle}
          />{' '}
          <br />
          <center>{this.props.item.chartType}</center>
        </Card>
      </div>
    )
  }
}
