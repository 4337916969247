import config from 'config'
import Immutable from 'immutable'
import { createBrowserHistory } from 'history'
import createLogger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
// import { persistState } from 'redux-devtools';
import { syncHistory, routeReducer } from 'react-router-redux'

import createSagaMiddleware from 'redux-saga'

import reducers from './reducers'
import sagas from './sagas'

// Form the root reducer from the app reducers and the router reducer.
const rootReducer = combineReducers(
  Object.assign({}, reducers, {
    routing: routeReducer,
  })
)

// Will be transformed into a middleware function with applyMiddleware later.
let middleware = [thunkMiddleware, createSagaMiddleware(...sagas)]

const logger = createLogger({
  // // Transform Immutable objects into JSON
  stateTransformer: (state) => {
    const newState = {}
    for (let i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS()
      } else {
        newState[i] = {}
        for (let j of Object.keys(state[i])) {
          if (Immutable.Iterable.isIterable(state[i][j])) {
            newState[i][j] = state[i][j].toJS()
          } else {
            newState[i][j] = state[i][j]
          }
        }
      }
    }
    return newState
  },
  collapsed: true,
})

if (config.dev.logActions) {
  middleware.push(logger)
}

let _history
if (config.dev.history) {
  _history = createBrowserHistory({
    basename: '/',
  })

  const reduxRouterMiddleware = syncHistory(_history)

  middleware.push(reduxRouterMiddleware)
}
export const history = _history

// if (config.dev.devTools) {
//   // Development
//   if (window.devToolsExtension) {
//     middleware = compose(
//       applyMiddleware.apply(null, middleware),
//       window.devToolsExtension(),
//       persistState(getDebugSessionKey())
//     );
//   } else {
//     middleware = compose(
//       applyMiddleware.apply(null, middleware),
//       devTools.instrument(),
//       persistState(getDebugSessionKey())
//     );
//   }
// } else {
// Production
middleware = compose(applyMiddleware.apply(null, middleware))
// }

export const store = createStore(rootReducer, middleware)
