import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import CenteredSpinner from 'components/widgets/centeredSpinner'
import { useParams } from 'react-router-dom'

function mapDispatchToProps(dispatch) {
  return {
    loadSharedFact: (hash) =>
      dispatch({ type: 'DASHBOARDS:FACT:SHARED:LOAD', hash }),
  }
}

const FactsShowPage = ({ loadSharedFact }) => {
  const { hash } = useParams()
  useEffect(() => {
    loadSharedFact(hash)
  }, [hash, loadSharedFact])

  return <CenteredSpinner show={true} />
}

export default connect(null, mapDispatchToProps)(FactsShowPage)
