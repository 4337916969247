/**
 * Cancels the addition of a card to the dashboard.
 */
export function addCardCancelAction(row, col) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:ADD_CARD_CANCEL',
      row,
      col,
    })
  }
}
