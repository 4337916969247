/**
 * Sets the page to be redirected to after a login.
 */
export function loginRedirectSetAction(redirect) {
  return (dispatch) => {
    dispatch({
      type: 'USER:LOGIN_REDIRECT_SET',
      redirect,
    })
  }
}
