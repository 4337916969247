import { store } from '../services/store'

let width = 0
let height = 0

let lastTimeout
const timeout = 300

/**
 * Reports the dimensions of the window when the size changes.
 */
export function resizeWindowAction(event) {
  event.preventDefault()
  event.stopPropagation()
  width = window.innerWidth
  height = window.innerHeight
  clearTimeout(lastTimeout)
  lastTimeout = setTimeout(() => {
    width = window.innerWidth
    height = window.innerHeight
    store.dispatch({ type: 'APP:RESIZE_WINDOW', width, height })
  }, timeout)
}
