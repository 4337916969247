import Immutable from 'immutable'
import moment from 'moment'

const defaultState = Immutable.Map({
  isFetching: false,
  dashboards: Immutable.List(),
})

/**
 * Called when the dashboard list has been create.
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DASHBOARDS:LIST_START':
      return state.set('isFetching', true)

    case 'DASHBOARDS:LIST:SUCCESS':
      return state.set('isFetching', false).set(
        'dashboards',
        Immutable.List(
          action.dashboards.map((item) => {
            return Immutable.fromJS(item).set(
              'createdAt',
              moment(item.createdAt)
            )
          })
        )
      )

    case 'DASHBOARDS:LIST:FAIL':
      return state.set('isFetching', false).set('dashboards', Immutable.List())

    default:
      return state
  }
}
