import React from 'react'
import { Card as BSCard } from 'react-bootstrap'
import CardFilter from '../cardFilters'

export class Card extends React.Component {
  render() {
    const iconSize = 25
    const padding = iconSize + 10
    const containerHeaderStyle = {
      position: 'absolute',
      height: `${this.props.headerHeight}px`,
    }

    const titleStyle = {
      height: `${this.props.headerHeight - 25}px`,
      paddingLeft: padding + 'px',
      width: this.props.width - padding * 2,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    const filterHeight = 30

    let cardFilter = false
    if (this.props.showFilters) {
      cardFilter = (
        <CardFilter
          chartId={this.props.chartId}
          cardId={this.props.cardId}
          localFilters={this.props.filters}
          showFilterPopup={this.props.showFilterPopup}
          width={this.props.width - 15}
          height={filterHeight}
        />
      )
    }
    const header = (
      <div style={containerHeaderStyle}>
        <div style={titleStyle}>{this.props.title}</div>
        {cardFilter}
      </div>
    )

    const containerStyle = {
      position: 'absolute',
      left: this.props.positionX + 'px',
      top: this.props.positionY + 'px',
      width: this.props.width + 'px',
      height: this.props.height + 'px',
    }

    const {
      cardId,
      headerHeight,
      paddingBottom,
      paddingTop,
      paddingRight,
      paddingLeft,
      showFilterPopup,
      chartId,
      showFilters,
      positionY,
      positionX,
      ...rest
    } = this.props

    return (
      <div key={`box_${this.props.id}`} style={containerStyle} {...rest}>
        <BSCard
          style={{ height: '100%', width: '100%', overflow: 'hidden' }}
          className="grid-component-panel"
        >
          {header}
          <div style={{ marginTop: `${this.props.headerHeight}px` }}>
            {this.props.children}
          </div>
        </BSCard>
      </div>
    )
  }
}

export default Card
