import React from 'react'

const spinnerStyle = {
  marginLeft: '5px',
  float: 'left',
  width: '34px',
  height: '34px',
  background: 'url(/images/spinner.gif) center no-repeat',
}

class Spinner extends React.Component {
  render() {
    if (this.props.show) {
      return <div style={spinnerStyle} />
    }
    return false
  }
}

export default Spinner
