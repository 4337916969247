import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, ButtonGroup, Form, Modal } from 'react-bootstrap'

import Spinner from 'components/widgets/spinner'
import ErrorBlock from 'components/widgets/errorBlock'

class CardTitleEdit extends React.Component {
  handleOnClose() {
    this.props.onClose(this.props.id)
  }

  handleOnCancel() {
    this.props.onCancel(this.props.id)
  }

  handleTextChange(event) {
    event.preventDefault()
    const title = this.refs.title.value
    this.props.onTextChange(title)
  }

  render() {
    if (this.props.show !== 'text') {
      return false
    }

    const titleAttributes = {
      value: this.props.title,
    }

    if (!this.props.isSaving) {
      if (this.props.errors && this.props.errors.title) {
        titleAttributes.variant = 'error'
        titleAttributes.help = this.props.errors.title
        titleAttributes.autoFocus = true
      }
    }

    let errorMessage = ''
    if (this.props.errors) {
      errorMessage = this.props.errors.generic
    }

    return (
      <Modal dialogClassName="card-edit-modal" show={true}>
        <Modal.Header>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Control
            ref="title"
            type="text"
            label="Title"
            placeholder="Title"
            {...titleAttributes}
            disabled={this.props.isSaving}
            onChange={this.handleTextChange.bind(this)}
          />

          <ErrorBlock message={errorMessage} />
        </Modal.Body>

        <Modal.Footer>
          <ButtonGroup>
            <Button
              onClick={this.handleOnCancel.bind(this)}
              size="sm"
              variant="warning"
              disabled={this.props.isSaving}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleOnClose.bind(this)}
              size="sm"
              variant="primary"
              disabled={this.props.isSaving}
            >
              Update
            </Button>
          </ButtonGroup>
          <Spinner show={this.props.isSaving} />
        </Modal.Footer>
      </Modal>
    )
  }
}

CardTitleEdit.propTypes = {
  errors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    show: state.textEditor.get('isOpened'),
    title: state.textEditor.get('content'),
    errors: state.textEditor.get('errors'),
    isSaving: state.textEditor.get('isSaving'),
    id: state.textEditor.get('id'),
  }
}

export default connect(mapStateToProps, null)(CardTitleEdit)
