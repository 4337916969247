/**
 * Called when a card add was sucessful.
 */
import Immutable from 'immutable'

import {
  createInternalTextCardStructFromCard,
  createInternalCardStructFromCard,
} from '../../helpers'

export default (state, action) => {
  let card = null
  if (action.newCard.contentType === 'TextCard') {
    card = createInternalTextCardStructFromCard(action.newCard)
  } else {
    card = createInternalCardStructFromCard(action.newCard)
  }

  return state
    .set('cards', state.get('cards').push(Immutable.fromJS(card)))
    .set('addToRow', -1)
    .set('addToCol', -1)
    .set('isAddingCard', false)
    .update('prog', (value) => value + 1)
}
