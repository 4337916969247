import { store } from 'services/store'

/**
 * Gets a chart by id.
 */
export function getChartById(chartId) {
  return store
    .getState()
    .masterdata.get('chart')
    .find((item) => item.id === chartId)
}
