import Clipboard from 'clipboard'
import React from 'react'
import config from 'config'
import { Button, Form } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

export class ShareInfo extends React.Component {
  constructor(option) {
    super(option)
    this.state = {
      showCopiedMessage: false,
    }
    this.copyButtonRef = React.createRef()
  }

  componentDidMount() {
    const link = `${config.baseUrl}dashboards/shared/${this.props.hash}`

    const element = this.copyButtonRef.current

    const clipboard = new Clipboard(element, {
      text: () => link,
    })

    const that = this
    clipboard.on('success', function () {
      that.setState({ showCopiedMessage: true })

      setTimeout(() => {
        that.setState({ showCopiedMessage: false })
      }, 1000)
    })

    clipboard.on('error', function () {
      /* eslint-disable no-alert */
      alert('The copy function is not enabled on this device')
    })
  }

  render() {
    const link = `${config.baseUrl}dashboards/shared/${this.props.hash}`

    const copiedMessageStyle = {
      opacity: 1,
      transition: 'opacity .5s ease-in-out',
      color: '#339604',
      fontSize: '12px',
      textAlign: 'right',
      marginRight: '5px',
      marginTop: '-10px',
    }
    if (!this.state.showCopiedMessage) {
      copiedMessageStyle.opacity = 0
      copiedMessageStyle.transition = 'opacity 4s ease-in-out'
    }

    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>Dashboard share info</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>Share this dashboard:</h4>
          <Form.Control type="text" readOnly value={link} />
          <Button ref={this.copyButtonRef}>
            {/*<Glyphicon glyph="copy"/> */}
            Copy
          </Button>
          <div style={copiedMessageStyle}>Link copied</div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.props.onClose} variant="primary" size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ShareInfo
