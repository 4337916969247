import React from 'react'

import LineChart from 'components/widgets/charts/lineChart'
import TextCard from 'components/widgets/charts/text'
import MultiBarChart from 'components/widgets/charts/multiBarChart'
import HorizontalBarChart from 'components/widgets/charts/horizontalBarChart'
import TreemapChart from 'components/widgets/charts/treemapChart'
import TableChart from 'components/widgets/charts/tableChart'
import Card from './card'

const components = {
  LineChart,
  TextCard,
  ColumnChart: MultiBarChart,
  BarChart: HorizontalBarChart,
  TreemapChart,
  TableChart,
}

export class ListView extends React.Component {
  render() {
    const containerStyle = {
      width: this.props.width + 'px',
      height: this.props.grid.height + 'px',
      position: 'relative',
      boxSizing: 'border-box',
      margin: '0 auto',
    }

    const cardHeightRate = 0.5
    return (
      <div style={containerStyle}>
        {this.props.cards
          .sort((a, b) => {
            if (a.get('cellY') > b.get('cellY')) {
              return 1
            } else if (a.get('cellY') < b.get('cellY')) {
              return -1
            }
            if (a.get('cellX') > b.get('cellX')) {
              return 1
            } else if (a.get('cellX') < b.get('cellX')) {
              return -1
            }
            return 0
          })
          .map((card) => {
            const paddingLeft = 5
            const paddingRight = 5
            const paddingTop = 5
            const paddingBottom = 5
            let headerHeight = 20
            const cellWidth = Math.round(
              this.props.grid.width / this.props.grid.cols
            )
            const cellHeight = Math.round(
              this.props.grid.height / this.props.grid.rows
            )

            let showFilters = true
            if (card.getIn(['content', 'type']) === 'TextCard') {
              showFilters = false
            }
            const cardId = card.get('id')
            let content
            const marginTop =
              (card.get('spanY') * cellHeight -
                (paddingTop + paddingBottom + headerHeight)) /
                2 -
              20
            if (!card.get('isInit') || card.get('isFetching')) {
              const loaderStyle = {
                width:
                  card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
                textAlign: 'center',
                marginTop: `${marginTop}px`,
              }
              content = <div style={loaderStyle}>Loading</div>
            } else if (card.get('hasErrored')) {
              const loaderStyle = {
                color: '#ff0000',
                width:
                  card.get('spanX') * cellWidth - (paddingLeft + paddingRight),
                textAlign: 'center',
                marginTop: `${marginTop}px`,
              }
              content = <div style={loaderStyle}>Error loading chart</div>
            } else {
              const CurrentComponent =
                components[card.getIn(['content', 'type'])]
              content = (
                <CurrentComponent
                  id={`chart_${cardId}`}
                  key={`card_component_${cardId}`}
                  width={this.props.grid.width - (paddingLeft + paddingRight)}
                  height={
                    Math.round(this.props.grid.width * cardHeightRate) -
                    (paddingTop + paddingBottom + headerHeight + 15)
                  }
                  data={card.getIn(['content', 'data'])}
                  text={card.getIn(['content', 'text'])}
                />
              )
            }

            if (card.getIn(['content', 'type']) === 'TextCard') {
              headerHeight = 0
            }

            return (
              <Card
                cardId={cardId}
                id={`card_${cardId}`}
                key={`card_${cardId}`}
                width={this.props.width}
                height={Math.round(this.props.width * cardHeightRate)}
                title={card.get('title')}
                showFilters={showFilters}
                chartId={card.getIn(['content', 'chartId'])}
                filters={card.get('filters')}
                showFilterPopup={card.get('showFilterPopup')}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                headerHeight={headerHeight}
                marginBottom={20}
              >
                {content}
              </Card>
            )
          })}
      </div>
    )
  }
}

export default ListView
