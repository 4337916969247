import Immutable from 'immutable'

import {
  createInternalTextCardStructFromCard,
  createInternalCardStructFromCard,
} from '../../helpers'

/**
 * Called when a card fetch has succeeded.
 */
export default (state, action) => {
  const cards = action.cards.map((card) => {
    if (card.contentType === 'TextCard') {
      return createInternalTextCardStructFromCard(card)
    }
    return createInternalCardStructFromCard(card)
  })

  return state
    .set('isInit', true)
    .set('isFetching', true)
    .set('cards', Immutable.fromJS(cards))
}
