import Immutable from 'immutable'

const defaultState = Immutable.Map({
  from: 2000,
  to: 2025,
  type: 'year',
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'FILTERS:RANGE:INIT':
      return state
        .set('from', action.range.from || defaultState.get('from'))
        .set('to', action.range.to || defaultState.get('to'))
        .set('type', action.range.type || defaultState.get('type'))

    case 'FILTERS:RANGE:CHANGE':
      return state.set('from', action.range.from).set('to', action.range.to)

    default:
      return state
  }
}
