import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { serverConst } from 'services/server'
import { Route, useHistory } from 'react-router-dom'
import WindowResize from '../widgets/windowResize'
import Header from '../widgets/header'
import { store } from '../../store'
import { loginRedirectSetAction } from '../../modules/user/actions'
import MasterDataLoader from '../widgets/masterDataLoader'

function mapStateToProps(state) {
  return {
    appStore: state.app,
  }
}

const MainLayout = (props) => {
  const history = useHistory()

  store.history = history
  useEffect(() => {
    const loggedInUser = store.getState().user.get('loggedInUser')
    const isAuthenticated = loggedInUser !== null
    if (props.requireAuth && !isAuthenticated) {
      store.dispatch(loginRedirectSetAction(props.path))
      history.replace('/login')
      return
    }
  }, [history, props.path, props.requireAuth])

  let resizeWindow = false
  const Component = props.Component
  if (serverConst.deviceType === 'phone') {
    resizeWindow = <WindowResize />
  }

  const Loader = props.useLoader
    ? MasterDataLoader
    : ({ children }) => <>{children}</>

  return (
    <Route {...props}>
      {resizeWindow}
      <Header />
      <Loader>
        <Component />
      </Loader>
    </Route>
  )
}

export default connect(mapStateToProps, null)(MainLayout)
