import React from 'react'
import { Card as BSCard } from 'react-bootstrap'
import Immutable from 'immutable'
// import {
//   RepoTemplateIcon,
//   PencilIcon,
//   DiffRemovedIcon,
// } from '@primer/octicons-react'
import Glyphicon from '@strongdm/glyphicon'
import ResizableAndMovable from './resizableAndMovable'

const iconSize = 25
const moveHandlerStyle = {
  width: `${iconSize}px`,
  height: `${iconSize}px`,
  position: 'absolute',
  transition: 'opacity linear 0.3s',
}
const removeHandlerStyle = {
  width: `${iconSize}px`,
  height: `${iconSize}px`,
  position: 'absolute',
  right: 5,
  transition: 'opacity linear 0.3s',
}
const editHandlerStyle = {
  width: `${iconSize}px`,
  height: `${iconSize}px`,
  position: 'absolute',
  right: 35,
  transition: 'opacity linear 0.3s',
}

export class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showActions: false }
  }

  handleRemoveCard() {
    if (this.props.onRemoveCard) {
      this.props.onRemoveCard()
    }
  }

  handleEditCard() {
    if (this.props.onEditCard) {
      this.props.onEditCard()
    }
  }

  handleStart(event, ui) {
    // console.log('Event: ', event)
    // console.log('Position: ', ui.position)
  }

  handleDrag(event, ui) {
    // console.log('Event: ', event)
    // console.log('Position: ', ui.position)
  }

  handleStop(event, ui) {
    // console.log('Event: ', event)
    // console.log('Position: ', ui.position)
  }

  render() {
    const padding = iconSize + 10
    const titleStyle = {
      height: `${this.props.headerHeight}px`,
      position: 'absolute',
      left: padding,
      width: this.props.width - padding * 2 - 25,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
    let header = false
    const filterHeight = 30
    if (this.props.headerHeight !== 0) {
      const CardFilter = this.props.cardFilterComponent
      header = (
        <div
          style={{
            position: 'absolute',
            height: `${this.props.headerHeight + filterHeight}px`,
          }}
        >
          <div style={titleStyle}>{this.props.title}</div>
          <div
            style={{
              top: `${this.props.headerHeight + 5}px`,
              position: 'absolute',
            }}
          >
            <CardFilter
              chartId={this.props.card.content.chartId}
              cardId={this.props.card.id}
              localFilters={Immutable.fromJS(this.props.card.filters)}
              showFilterPopup={this.props.card.showFilterPopup}
              width={this.props.width - 15}
              height={filterHeight}
            />
          </div>
        </div>
      )
    }

    const { headerHeight, ...rest } = this.props
    return (
      <ResizableAndMovable
        key={`box_${this.props.id}`}
        positionX={this.props.positionX}
        positionY={this.props.positionY}
        width={this.props.width}
        height={this.props.height}
        start={this.props.start}
        handleMove=".move-me"
        handleSize=".size-me"
        zIndex={100}
        {...rest}
      >
        <BSCard
          style={{ height: '100%', width: '100%', overflow: 'hidden' }}
          className="grid-component-panel"
        >
          <div className="move-handler" style={moveHandlerStyle}>
            {/*<div className="move-me">*/}
            {/*  <RepoTemplateIcon />*/}
            {/*</div>*/}
            <Glyphicon
              glyph="move"
              style={{ fontSize: `${iconSize}px` }}
              className="move-me"
            />
          </div>
          <div
            className="edit-handler"
            style={editHandlerStyle}
            onClick={this.handleEditCard.bind(this)}
          >
            {/*<PencilIcon />*/}
            <Glyphicon glyph="pencil" style={{ fontSize: `${iconSize}px` }} />
          </div>
          <div
            className="remove-handler"
            style={removeHandlerStyle}
            onClick={this.handleRemoveCard.bind(this)}
          >
            {/*<DiffRemovedIcon />*/}
            <Glyphicon glyph="trash" style={{ fontSize: `${iconSize}px` }} />
          </div>
          {header}
          <div
            style={{ marginTop: `${this.props.headerHeight + filterHeight}px` }}
          >
            {this.props.children}
          </div>
        </BSCard>
      </ResizableAndMovable>
    )
  }
}

export default Card
