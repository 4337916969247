import React from 'react'
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutAction } from 'modules/user/actions'

import './header.scss'
import { store } from '../../store'

const menuItems = [
  { label: 'Home', path: '/profile', category: 'profile' },
  { label: 'Facts', path: '/facts', category: 'facts' },
  { label: 'Dashboards', path: '/dashboards', category: 'dashboards' },
]

class Header extends React.Component {
  render() {
    const loggedInUser = this.props.userStore.get('loggedInUser')
    const menuItemCategory = this.props.appStore.get('menuItemCategory')
    let navItems = []
    if (loggedInUser) {
      navItems = [
        ...navItems,
        ...menuItems.map(({ label, path, category }, i) => {
          if (category === menuItemCategory) {
            // return <Nav.Item key={i + 1} eventKey={i + 1} href={path} active>{label}</Nav.Item>;
            return (
              <Nav.Item key={i + 1}>
                <Nav.Link as={Link} to={path} key={i + 1} active>
                  {label}
                </Nav.Link>
              </Nav.Item>
            )
          }
          // return <Nav.Item key={i + 1} eventKey={i + 1} href={path}>{label}</Nav.Item>;
          return (
            <Nav.Item key={i + 1}>
              <Nav.Link as={Link} to={path} key={i + 1}>
                {label}
              </Nav.Link>
            </Nav.Item>
          )
        }),
      ]
      navItems.push(
        <NavDropdown
          key={'20.3'}
          title={loggedInUser.get('username')}
          id="basic-nav-dropdown"
        >
          <Dropdown.Item key={'20.3'} onClick={this.props.onLogout}>
            Logout
          </Dropdown.Item>
        </NavDropdown>
      )
    } else {
      // navItems.push(
      //     <Nav.Item variant="primary" size="sm" onClick={this.props.onLogin}>Login</Nav.Item>
      // );
      navItems.push(
        <Nav.Item>
          <Nav.Link onClick={this.props.onLogin}>Login</Nav.Link>
        </Nav.Item>
      )
    }

    return (
      <div className="app-header">
        <Navbar variant="dark">
          <Navbar.Brand>
            <Link to="/">Leverix</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ml-auto" variant="pills">
              {navItems}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    appStore: state.app,
    userStore: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(logoutAction()),
    // onLogin: () => dispatch(routeActions.replace('/login', {})),
    onLogin: () => store.history.push(`/login`),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
