import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import Range from 'components/widgets/range'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { changeAction } from '../../../../../actions/filters/range'

export class RangeModal extends React.Component {
  handleChangeRange(values) {
    this.props.onChange(values)
  }

  handleOnClose() {
    this.props.onClose()
  }

  handleOnCancel() {
    this.props.onCancel()
  }

  render() {
    return (
      <Modal
        dialogClassName="range-panel"
        show={true}
        onHide={this.handleOnCancel.bind(this)}
      >
        <Modal.Header>
          <Modal.Title>Range filter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Range
            limitStart={2000}
            limitStop={2025}
            from={this.props.range.get('from')}
            to={this.props.range.get('to')}
            type={this.props.range.get('type')}
            onChange={this.handleChangeRange.bind(this)}
          />
        </Modal.Body>

        <Modal.Footer>
          <ButtonGroup>
            <Button
              size="sm"
              variant="warning"
              onClick={this.handleOnCancel.bind(this)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={this.handleOnClose.bind(this)}
            >
              Update
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    )
  }
}

RangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  range: ImmutablePropTypes.contains({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

function mapStateToProps(state) {
  return {
    range: state.filters.range,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (newRange) => dispatch(changeAction(newRange)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RangeModal)
