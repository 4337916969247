import { store } from 'services/store'

import { fetchChartData } from 'modules/masterdata/helpers/fetchChartData'
import { getInformationItemById } from 'modules/masterdata/helpers'
import { createFilters } from '../../helpers/createFilters'
import { union } from 'lodash'
/**
 * Refreshes one card with new data from the API.
 */
export function refreshCardAction(card) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD',
      cardId: card.get('id'),
    })

    if (card.getIn(['content', 'type']) === 'TextCard') {
      dispatch({
        type: 'DASHBOARDS:CURRENT_GRID:REFRESH_TEXT_CARD:SUCCESS',
        cardId: card.get('id'),
        chartId: null,
        chartData: [],
      })
      return
    }

    const chartId = card.getIn(['content', 'chartId'])

    const informationItem = getInformationItemById(chartId)

    const allowedFilters = union(
      informationItem.localFilters,
      informationItem.globalFilters
    )

    const struct = store.getState().masterdata.get('filters')
    const filters = createFilters(
      struct,
      store.getState().dashboard.currentGrid.get('filters'),
      card.get('filters'),
      allowedFilters
    )

    // const informationItem = getInformationItemById(chartId)
    // if (!filters[informationItem.chartY.group.toLowerCase()] || filters[informationItem.chartY.group.toLowerCase()].length === 0) {
    //   dispatch({
    //     type: 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD:SUCCESS',
    //     cardId: card.get('id'),
    //     chartId,
    //     chartData: []
    //   })
    //   return
    // }

    fetchChartData(chartId, filters)
      .then((result) => {
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD:SUCCESS',
          cardId: card.get('id'),
          chartId,
          chartData: result,
        })
      })

      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:REFRESH_CARD:FAIL',
          cardId: card.get('id'),
          ...result.response,
        })
      })
  }
}
