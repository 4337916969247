/**
 * Changes the range of the date filter.
 */
export function changeAction(newRange) {
  return (dispatch) => {
    dispatch({
      type: 'FILTERS:RANGE:CHANGE',
      range: newRange,
    })
  }
}
