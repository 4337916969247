import React from 'react'
import { connect } from 'react-redux'

import { Button, ButtonGroup, Col, Container, Form, Row } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

import Spinner from 'components/widgets/spinner'
import ErrorBlock from 'components/widgets/errorBlock'
import CenteredSpinner from 'components/widgets/centeredSpinner'

export class EditModal extends React.Component {
  handleOnClose() {
    this.props.onClose(this.props.id)
  }

  handleOnCancel() {
    this.props.onCancel(this.props.id)
  }

  handleTextChange() {
    this.props.onTextChange({
      title: this.refs.title.value,
      description: this.refs.description.value,
    })
  }

  render() {
    if (this.props.show !== 'dashboardDetails') {
      return false
    }
    if (this.props.isFetching) {
      return (
        <Container>
          <Row>
            <Col md={12}>
              <CenteredSpinner show={true} />
            </Col>
          </Row>
        </Container>
      )
    }

    const titleAttributes = {
      value: this.props.content.title,
    }

    const descriptionAttributes = {
      value: this.props.content.description,
    }

    let errorMessage

    if (!this.props.isSaving) {
      if (this.props.errors.title) {
        titleAttributes.variant = 'error'
        titleAttributes.help = this.props.errors.title
        titleAttributes.autoFocus = true
      }

      if (this.props.errors.description) {
        descriptionAttributes.variant = 'error'
        descriptionAttributes.help = this.props.errors.description
      }

      if (this.props.errors.generic) {
        errorMessage = this.props.errors.generic
      }
    }

    return (
      <form>
        <Modal show={this.props.show} onHide={this.handleOnCancel.bind(this)}>
          <Modal.Header>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Control
              ref="title"
              type="text"
              label="Title"
              placeholder="Title"
              {...titleAttributes}
              disabled={this.props.isSaving}
              onChange={this.handleTextChange.bind(this)}
            />
            <Form.Control
              ref="description"
              as="textarea"
              label="Description"
              rows="5"
              placeholder="Description"
              {...descriptionAttributes}
              disabled={this.props.isSaving}
              onChange={this.handleTextChange.bind(this)}
            />

            <ErrorBlock message={errorMessage} />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup>
              <Button
                onClick={this.handleOnCancel.bind(this)}
                size="sm"
                variant="warning"
                disabled={this.props.isSaving}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="primary"
                type="submit"
                disabled={this.props.isSaving}
                onClick={this.handleOnClose.bind(this)}
              >
                Update
              </Button>
            </ButtonGroup>
            <Spinner show={this.props.isSaving} />
          </Modal.Footer>
        </Modal>
      </form>
    )
  }
}

function mapStateToProps(state) {
  return {
    show: state.textEditor.get('isOpened'),
    content: state.textEditor.get('content'),
    errors: state.textEditor.get('errors'),
    isSaving: state.textEditor.get('isSaving'),
    id: state.textEditor.get('id'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onTextChange: (value) => dispatch({ type: 'TEXT_EDITOR:SET_VALUE', value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModal)
