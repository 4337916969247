import registry from 'services/registry'

/**
 * Gets the size of the window or returns a default.
 */
export function getWindowSize() {
  if (global.window) {
    return {
      width: global.window.innerWidth,
      height: global.window.innerHeight,
    }
  }
  return { width: 1024, height: 1024 }
}

registry.register('helpers/metrics/getWindowSize', getWindowSize)
