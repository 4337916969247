import { connect } from 'react-redux'
import _component from '../filters/component'

import {
  showPanelAction,
  closeAndSaveAction,
  changeRangeAction,
  closeAction,
} from '../../actions/fact'

function mapStateToProps(state) {
  return {
    globalFilters: state.dashboard.fact.get('filters'),
    show: state.dashboard.globalFilters.get('show'),
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    showPanel: (idPanel) => dispatch(showPanelAction(idPanel)),
    closeAndSave: (idPanel, type) =>
      dispatch(closeAndSaveAction(props.chartId, idPanel, type)),
    close: () => dispatch(closeAction()),
    changeRange: (range) => dispatch(changeRangeAction(props.chartId, range)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
