import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * Sets the title on a card.
 */
export function setCardTitleAction(dashboardId, cardId) {
  return (dispatch) => {
    const newText = store.getState().textEditor.get('content')
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_TITLE',
      cardId,
    })
    dispatch({
      type: 'TEXT_EDITOR:ON_SAVING',
    })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')
    const url = `${config.server.url}dashboard/${dashboardId}/card/${cardId}/title?auth_token=${token}`

    request
      .put(url, { title: newText }, { responseType: 'json', dataType: 'json' })
      .then((result) => {
        const text = result.response.text
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_TITLE:SUCCESS',
          cardId,
          text,
        })
        dispatch({
          type: 'TEXT_EDITOR:ON_SAVING:SUCCESS',
        })
        dispatch({
          type: 'TEXT_EDITOR:CLOSE',
        })
      })

      .catch((result) => {
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:SET_CARD_TITLE:FAIL',
          errors: result.response,
        })
        dispatch({
          type: 'TEXT_EDITOR:ON_SAVING:FAILED',
          errors: result.response,
        })
      })
  }
}
