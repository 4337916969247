import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { ButtonLabelList } from './buttonLabelList'

const filtersButtonStyle = {
  color: '#3e3f3a',
  border: '1px solid #dfd7ca',
  borderRadius: '2px',
  backgroundColor: '#f8f5f0',
  marginBottom: '0px',
  padding: '3px',
  cursor: 'pointer',
  height: '1.2em',
  display: 'inline-block',
}

const filtersReviewStyle = {
  marginLeft: '0px',
  display: 'inline-block',
  fontSize: '0.7em',
  verticalAlign: 'top',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '110%',
}

export class ButtonHierarchyFilter extends React.Component {
  handleOnClick() {
    this.props.onClick()
  }

  render() {
    if (!this.props.width) {
      return false
    }
    const reviewStyle = Object.assign({}, filtersReviewStyle, {
      width: this.props.width !== null ? this.props.width - 10 + 'px' : null,
    })

    const containerStyle = Object.assign({}, filtersButtonStyle, {
      width: this.props.width !== null ? this.props.width - 10 + 'px' : null,
    })

    return (
      <div style={containerStyle} onClick={this.handleOnClick.bind(this)}>
        <div style={reviewStyle}>
          <ButtonLabelList
            list={this.props.list.get('selectedNodes')}
            type={this.props.type}
            maxLabel={3}
            defaultLabel={this.props.defaultLabel}
            width={this.props.width}
          />
        </div>
      </div>
    )
  }
}

ButtonHierarchyFilter.propTypes = {
  defaultLabel: PropTypes.string,
  lang: PropTypes.string,
  icon: PropTypes.bool,
  list: ImmutablePropTypes.contains({
    selectedNodes: ImmutablePropTypes.set.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
