/**
 * Transform the hierarchy structure in a flat structure
 * with al the parameter set to correctly manage the selection in the hierarchy struct
 * @param tree
 * @param labelResolver
 * @param {Immutable.Set} openedNodes
 * @return {Array}
 */
export function getFlatStruct(
  pageId,
  tree,
  labelResolver,
  openedNodes,
  minOpenedLevel,
  searchQuery
) {
  const returnStruct = []
  parse(
    pageId,
    tree,
    0,
    returnStruct,
    labelResolver,
    openedNodes,
    minOpenedLevel,
    true,
    searchQuery
  )
  return returnStruct
}

function parse(
  pageId,
  item,
  level,
  returnStruct,
  labelResolver,
  openedNodes,
  minOpenedLevel,
  isParentOpen,
  searchQuery
) {
  const currentNode = {
    id: item.id,
    toggleId: pageId + ':' + item.id,
    level: level + 1,
    isOpen: false,
    hasChild: false,
    isParentOpen,
  }

  currentNode.isOpen =
    currentNode.level <= minOpenedLevel || openedNodes.has(currentNode.toggleId)
      ? true
      : false

  returnStruct.push(currentNode)

  if (item.data.infoObject === 'NODE') {
    currentNode.title = item.data.nodeName
  } else {
    currentNode.title = labelResolver('infitem', item.id, 'en').shortText
    if (
      searchQuery &&
      searchQuery.length > 0 &&
      currentNode.title.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
    ) {
      currentNode.isParentOpen = true
    }
  }

  if (item.childs && item.childs.length) {
    currentNode.hasChild = true
    item.childs.forEach((childItem) => {
      const hasChildsChecked = parse(
        pageId,
        childItem,
        level + 1,
        returnStruct,
        labelResolver,
        openedNodes,
        minOpenedLevel,
        currentNode.isOpen && currentNode.isParentOpen,
        searchQuery
      )

      if (hasChildsChecked) {
        currentNode.hasChildsChecked = true
      }
    })
  }

  return (
    currentNode.hasChildsChecked ||
    currentNode.isNodeChecked ||
    currentNode.isChildrenChecked
  )
}
