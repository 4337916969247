import React, { useState, useMemo } from 'react'

import DataGrid from 'react-data-grid'
import './tablechart.css'

const TableChart = (props) => {
  const [transposedColumnsSet, setTransopesColumnSet] = useState(new Set())
  const data = useMemo(() => {
    const columnSet = new Set()
    const transposedRowsObject = {}
    for (const row of props.data.data) {
      const nameValue = row.name
      columnSet.add(nameValue)
      Object.keys(row)
        .filter((key) => key !== 'name')
        .forEach((key) => {
          transposedRowsObject[key] = transposedRowsObject[key] || {}
          transposedRowsObject[key][nameValue] = row[key]
        })
    }
    setTransopesColumnSet(columnSet)
    return Object.keys(transposedRowsObject)
      .map((key) => ({
        name: key,
        ...transposedRowsObject[key],
      }))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
  }, [props.data.data])

  const columns = useMemo(
    () => [
      {
        name: '',
        key: 'name',
        frozen: true,
      },
      ...[...transposedColumnsSet].map((name) => ({
        key: name,
        name,
      })),
    ],
    [transposedColumnsSet]
  )

  return (
    <div style={{ width: '100%', height: props.height }}>
      <DataGrid columns={columns} rows={data} />
    </div>
  )
}

TableChart.defaultProps = {
  id: 'chart',
  height: 600,
  useInteractiveGuideline: true,
  showLegend: false,
  data: [],
}

export default TableChart
