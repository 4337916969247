import { combineReducers } from 'redux'
import hierarchyReducer from './hierarchy'
import rangeReducer from './range'

export default combineReducers(
  Object.assign(
    {},
    {
      hierarchy: hierarchyReducer,
      range: rangeReducer,
    }
  )
)
