import { connect } from 'react-redux'

import {
  changeViewAction,
  fetchSharedAction,
  importDashboardAction,
} from '../../actions'

import { exportExcelAction } from '../../actions/grid'

import _component from './component'

function mapStateToProps(state) {
  return {
    cards: state.dashboard.currentGrid.get('cards'),
    usedRows: state.dashboard.currentGrid.get('usedRows'),
    forceCurrentView: state.dashboard.current.get('forceCurrentView'),
    currentView: state.dashboard.current.get('currentView'),
    dashboard: state.dashboard.current.get('dashboard').toJS(),
    grid: state.dashboard.currentGrid.get('grid').toJS(),
    isFetched: state.dashboard.current.get('isFetched'),
    isFetching: state.dashboard.current.get('isFetching'),
    isImporting: state.dashboard.current.get('isImporting'),
    prog: state.dashboard.currentGrid.get('prog'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchItem: (id) => dispatch(fetchSharedAction(id)),
    onChangeView: (newView) => dispatch(changeViewAction(newView)),
    onExportExcel: (idDashboard) => dispatch(exportExcelAction(idDashboard)),
    onImportDashboard: (id) => dispatch(importDashboardAction(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
