import React from 'react'
import { Switch } from 'react-router-dom'

import { loginRedirectSetAction } from 'modules/user/actions'
import DashboardCreateNewPage from './components/pages/dashboards/createNew'
// import DashboardEditPage from './components/pages/dashboards/edit';
import DashboardListPage from './components/pages/dashboards/list'
import DashboardShowPage from './components/pages/dashboards/show'
import DashboardShowSharedPage from './components/pages/dashboards/showShared'
import DashboardsNotFoundPage from './modules/dashboard/components/notFound'
import FactsListPage from './components/pages/facts/list'
import FactsNotFoundPage from './modules/dashboard/components/fact/notFound'
import FactsShowPage from './components/pages/facts/show'
import FactsSharedPage from './components/pages/facts/shared'
import GenericNotAuthorizedPage from './components/pages/notAuthorized'
import GenericNotFoundPage from './components/pages/notFound'
import MainLayoutRoute from './components/layouts/mainLayout'
import ProfilePage from './components/pages/profile'
import { HomePage } from './components/pages/home'
import { LoginPage } from './components/pages/login'
import { changeMenuItemCategory } from './actions/changeMenuItemCategory'
import { store } from './store'

function getValuesFromRoutes(routes, attribute) {
  for (let i = routes.length - 1; i >= 0; i--) {
    if (routes[i][attribute] !== undefined) {
      return routes[i][attribute]
    }
  }
}

function _enter(nextState, replaceState) {
  const requireAuth = getValuesFromRoutes(nextState.routes, 'requireAuth')
  const loggedInUser = store.getState().user.get('loggedInUser')
  const isAuthenticated = loggedInUser !== null
  if (requireAuth && !isAuthenticated) {
    store.dispatch(loginRedirectSetAction(nextState.location))
    replaceState(null, '/login')
    return
  }
  store.dispatch(
    changeMenuItemCategory(
      getValuesFromRoutes(nextState.routes, 'menuItemCategory')
    )
  )
}

export default (
  <Switch>
    {/*<MainLayoutRoute path="/">Hello</MainLayoutRoute>*/}

    <MainLayoutRoute
      exact
      path="/"
      Component={HomePage}
      menuItemCategory="home"
    />
    <MainLayoutRoute
      exact
      path="/profile"
      Component={ProfilePage}
      requireAuth={true}
      menuItemCategory="profile"
    />

    <MainLayoutRoute
      exact
      path="/login"
      Component={LoginPage}
      onEnter={_enter}
    />

    <MainLayoutRoute
      exact
      path="/fact/:hash"
      Component={FactsSharedPage}
      menuItemCategory="facts"
      requireAuth={true}
    />
    <MainLayoutRoute
      exact
      path="/facts"
      menuItemCategory="facts"
      requireAuth={true}
      Component={FactsListPage}
      onEnter={_enter}
      useLoader
    />

    <MainLayoutRoute
      exact
      path="/facts-not-found"
      menuItemCategory="facts"
      requireAuth={true}
      Component={FactsNotFoundPage}
      useLoader
    />

    <MainLayoutRoute
      exact
      path="/facts/:id"
      menuItemCategory="facts"
      requireAuth={true}
      Component={FactsShowPage}
      useLoader
    />

    {/*<MainLayoutRoute exact path="/facts/**" menuItemCategory="facts" requireAuth={true} Component={FactsNotFoundPage}*/}
    {/*                 useLoader/>*/}

    <MainLayoutRoute
      exact
      path="/dashboards"
      Component={DashboardListPage}
      useLoader
      menuItemCategory="dashboards"
      requireAuth
    />

    <MainLayoutRoute
      exact
      path="/dashboards-new"
      Component={DashboardCreateNewPage}
      useLoader
      menuItemCategory="dashboards"
      requireAuth
    />

    <MainLayoutRoute
      exact
      path="/dashboards/shared/:hash"
      Component={DashboardShowSharedPage}
      menuItemCategory="dashboards"
      requireAuth
      useLoader
    />

    <MainLayoutRoute
      exact
      path="/dashboards-not-found"
      Component={DashboardsNotFoundPage}
      menuItemCategory="dashboards"
      requireAuth
      useLoader
    />

    <MainLayoutRoute
      path="/dashboards/:id"
      Component={DashboardShowPage}
      menuItemCategory="dashboards"
      requireAuth
      useLoader
    />

    {/*<MainLayoutRoute exact path="/dashboards/**" Component={DashboardsNotFoundPage} menuItemCategory="dashboards" requireAuth useLoader/>*/}

    <MainLayoutRoute
      path="/not-authorized"
      Component={GenericNotAuthorizedPage}
    />
    <MainLayoutRoute path="/not-found" Component={GenericNotFoundPage} />
    {/*<MainLayoutRoute path="/**" Component={GenericNotFoundPage}/>*/}
  </Switch>
)
