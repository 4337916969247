import React from 'react'
import { Jumbotron } from 'react-bootstrap'

export class NoCardsPanel extends React.Component {
  render() {
    return (
      <Jumbotron>
        <h2>The dashboard is empty.</h2>
      </Jumbotron>
    )
  }
}
