import Immutable from 'immutable'

const defaultState = Immutable.Map({
  content: '',
  isOpened: null,
  isSaving: false,
  errors: Immutable.List(),
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'TEXT_EDITOR:INIT':
      return state.set('content', action.content).set('id', action.id)

    case 'TEXT_EDITOR:OPEN':
      return state.set('isOpened', action.popUpType)

    case 'TEXT_EDITOR:SET_VALUE':
      return state.set('content', action.value)

    case 'TEXT_EDITOR:ON_SAVING':
      return state.set('isSaving', true).set('errors', Immutable.List())

    case 'TEXT_EDITOR:ON_SAVING:SUCCESS':
      return state.set('isSaving', false)

    case 'TEXT_EDITOR:ON_SAVING:FAILED':
      return state.set('errors', action.errors).set('isSaving', false)

    case 'TEXT_EDITOR:CLOSE':
      return state.set('isOpened', null).set('content', '').set('id', null)

    default:
      return state
  }
}
