import Immutable from 'immutable'
import { getInformationItemById } from 'modules/masterdata/helpers'
import DefaultGlobalFilter from './createFilters/defaultGlobalFilters'

/**
 * TODO: Document properly.
 * I just have no clue what this is for.
 */
export function createInternalCardStructFromCard(card) {
  const informationItem = getInformationItemById(card.customData.chartId)

  let filters = DefaultGlobalFilter

  if (card.filters) {
    if (card.filters.range) {
      filters = filters.set('range', Immutable.Map(card.filters.range))
    }

    Object.entries(card.filters)
      .filter(([key]) => key !== 'range')
      .forEach(([key, value]) => {
        filters = filters.setIn(
          [key, 'selectedNodes'],
          Immutable.Set(value.selectedNodes)
        )
      })
  }

  return {
    id: card.id,
    cellX: card.cellX,
    cellY: card.cellY,
    spanX: card.spanX,
    spanY: card.spanY,
    isInit: false,
    isUpdating: false,
    isFetching: false,
    title: card.title,
    content: {
      type: card.contentType,
      chartId: card.customData.chartId,
      data: [],
      xTitle: informationItem.chartX.label,
      yTitle: informationItem.chartY.label,
      scalingFactor: informationItem.scalingFactor,
    },
    filters,
    showFilterPopup: null,
  }
}
