import { store } from 'services/store'

/**
 * Saves the filters and closes the modal.
 */
export function closeAndSaveAction(chartId, panelId, type) {
  return (dispatch) => {
    if (type === 'range') {
      dispatch({
        type: 'DASHBOARDS:FACT:UPDATE_RANGE_FILTERS',
        filters: store.getState().filters.range,
      })
    } else {
      dispatch({
        type: 'DASHBOARDS:FACT:UPDATE_FILTERS',
        name: panelId,
        filters: store.getState().filters.hierarchy,
      })
    }
    dispatch({ type: 'DASHBOARD:GLOBAL_FILTERS:SHOW', show: null })
  }
}
