import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * Gets a list of the dashboards from the API for the Dashboard List page.
 */
export function listAction() {
  return (dispatch) => {
    dispatch({ type: 'DASHBOARDS:LIST_START' })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    const url = `${config.server.url}dashboard?auth_token=${token}`

    request
      .get(url, {}, { responseType: 'json' })
      .then((result) => {
        dispatch({
          type: 'DASHBOARDS:LIST:SUCCESS',
          dashboards: result.response.items,
        })
      })

      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:LIST:FAIL',
          ...result.response,
        })
      })
  }
}
