import config from 'config'
import request from 'services/request'
import { store } from 'services/store'
import { store as reduxStore } from '../../../store'

function isPresent(value) {
  return value !== null && value !== undefined && value !== ''
}

function getFormErrors({ title, description }) {
  let titleError
  let descriptionError

  if (!isPresent(title)) {
    titleError = 'Title may not be empty.'
  } else if (title.length > 50) {
    titleError = 'Title may not be longer then 50 characters.'
  }

  if (!isPresent(description)) {
    descriptionError = 'Description may not be empty.'
  }

  if (titleError || descriptionError) {
    return {
      title: titleError,
      description: descriptionError,
    }
  }
}

/**
 * Creates a dashboard.
 */
export function createAction(data) {
  return (dispatch) => {
    dispatch({ type: 'DASHBOARDS:CREATE_START' })

    const errors = getFormErrors(data)

    if (errors) {
      dispatch({
        type: 'DASHBOARDS:CREATE:FAIL',
        errors,
      })
      return
    }

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    const url = `${config.server.url}dashboard?auth_token=${token}`

    request
      .post(url, data, { responseType: 'json' })
      .then(() => {
        dispatch({
          type: 'DASHBOARDS:CREATE:SUCCESS',
        })

        // dispatch(routeActions.replace(`/dashboards`));
        reduxStore.history.push(`/dashboards`)
      })

      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:CREATE:FAIL',
          errors: result.response,
        })
      })
  }
}
