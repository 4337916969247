import React from 'react'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

const HorizontalBarChart = (props) => {
  const data = props.data.data.map((item) => {
    return {
      name: item.name,
      value: Number(item['amount']),
    }
  })

  return (
    <div style={{ width: '100%', height: props.height }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tick={{ fontSize: '0.8em' }} />
          <YAxis
            dataKey="name"
            type="category"
            tick={{ fontSize: '0.8em' }}
            tickFormatter={(item) =>
              String(item)
                .split(' ')
                .map((word) => word.substring(0, 10))
                .join(' ')
            }
          />
          <Tooltip />
          <Bar key="name" dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

HorizontalBarChart.defaultProps = {
  id: 'chart',
  height: 600,
  useInteractiveGuideline: true,
  showLegend: false,
  data: [],
}

export default HorizontalBarChart
