import React from 'react'
import { Container, Row, Col, Jumbotron } from 'react-bootstrap'

class NotAuthorized extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Jumbotron>
              <h1>Not Authorized</h1>
              <p>You do not have the required authorization.</p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default NotAuthorized
