import { connect } from 'react-redux'
import { store } from '../../../../store'

import { listAction, removeAction } from '../../actions'

import _component from './component'

function mapStateToProps(state) {
  return {
    dashboards: state.dashboard.list.get('dashboards'),
    isFetching: state.dashboard.list.get('isFetching'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListData: () => dispatch(listAction()),
    removeListEntry: (id) => dispatch(removeAction(id, listAction())),
    // navigateToDashboardsEdit: (id) => dispatch(routeActions.push(`/dashboards/${id}/edit`, {})),
    // navigateToDashboardsShow: (id) => dispatch(routeActions.push(`/dashboards/${id}`, {}))
    navigateToDashboardsEdit: (id) =>
      store.history.push(`/dashboards/${id}/edit`),
    navigateToDashboardsShow: (id) => store.history.push(`/dashboards/${id}`),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
