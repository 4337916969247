import './console.polyfill'
import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
// import injectTapEventPlugin from 'react-tap-event-plugin';
import { Provider } from 'react-redux'

import { BrowserRouter } from 'react-router-dom'
import './services/metrics'

import { initAppAction } from './actions/initApp'

import { store, history } from './store'

import routes from './routes'

import './styles.scss'
import './components/widgets/reactToggle.scss'

ReactDOM.render(
  <Provider store={store}>
    <div>
      {/*<Router history={history}>*/}
      {/*<MainLayout path='/'>*/}
      {/*  <InitPage/>*/}
      {/*</MainLayout>*/}
      {/*</Router>*/}
    </div>
  </Provider>,
  document.getElementById('root')
)

initAppAction(store.dispatch).then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <div>
        <BrowserRouter history={history}>{routes}</BrowserRouter>
      </div>
    </Provider>,
    document.getElementById('root')
  )
})
