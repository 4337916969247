/**
 * Returns the index and card of a card in the cardList.
 */
export function getCardAndIndexFromCardList(cardList, cardId) {
  const cardFound = cardList.findEntry((item) => {
    return item.get('id') === cardId
  })
  if (!cardFound || cardFound[0] < 0) {
    return { index: -1, card: null }
  }
  return { index: cardFound[0], card: cardFound[1] }
}
