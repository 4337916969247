import { useEffect, useState } from 'react'
import { store } from '../store'
import { createFilters } from '../modules/dashboard/helpers/createFilters'
import { fetchChartData } from '../modules/masterdata/helpers'

export default (chartId, filtersStruct, factFilters) => {
  const [isFetching, setIsFetching] = useState(false)
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    async function fetch() {
      setIsFetching(true)
      setChartData({})

      const filters = createFilters(filtersStruct, factFilters)

      try {
        const result = await fetchChartData(chartId, filters)
        setChartData(result)
        setIsFetching(false)
      } catch (e) {
        // TODO Instead of redirect on `fact not found`, show a toast with the error
        console.log(e)
        setIsFetching(false)
        store.history.push('/facts-not-found')
      }
    }

    fetch()
  }, [chartId, filtersStruct, factFilters])

  return {
    isFetching,
    chartData,
  }
}
