import { connect } from 'react-redux'
import _component from 'components/widgets/draggableGrid'
import CardFilter from '../cardFilters'

import {
  addCardRequestAction,
  removeCardAction,
  editCardAction,
  editCardCancelAction,
  editCardTextChangeAction,
  setCardPositionAction,
  setCardSizeAction,
  setCardTextAction,
  setCardTitleAction,
} from '../../actions/grid'

function mapStateToProps(state) {
  return {
    grid: state.dashboard.currentGrid.get('grid').toJS(),
    usedRows: state.dashboard.currentGrid.get('usedRows'),
    cards: state.dashboard.currentGrid.get('cards'),
    prog: state.dashboard.currentGrid.get('prog'),
    cardFilterComponent: CardFilter,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onDragStop: (dashboardId, index, event, ui) =>
      dispatch(setCardPositionAction(dashboardId, index, ui)),
    onResizeStop: (dashboardId, index, event, ui) =>
      dispatch(setCardSizeAction(dashboardId, index, ui)),
    onRemoveCard: (dashboardId, cardId) =>
      dispatch(removeCardAction(dashboardId, cardId)),
    onEditCard: (dashboardId, cardId) =>
      dispatch(editCardAction(dashboardId, cardId)),
    onEditCardOk: (dashboardId, cardId) =>
      dispatch(setCardTextAction(dashboardId, cardId)),
    onEditCardTitleOk: (dashboardId, cardId) =>
      dispatch(setCardTitleAction(dashboardId, cardId)),
    onEditCardCancel: (dashboardId, cardId) =>
      dispatch(editCardCancelAction(dashboardId, cardId)),
    onEditCardTextChange: (newValue) =>
      dispatch(editCardTextChangeAction(newValue)),
    onAddClick: (row, col) => dispatch(addCardRequestAction(row, col)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
