import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { ButtonLabelList } from './buttonLabelList'

const filtersReviewStyle = {
  marginLeft: '5px',
  display: 'inline-block',
  fontSize: '0.7em',
  verticalAlign: 'top',
}

export class ButtonHierarchyFilter extends React.Component {
  handleOnClick() {
    this.props.onClick()
  }

  render() {
    const reviewStyle = Object.assign({}, filtersReviewStyle, {
      width: this.props.width + 'px',
      margin: '0 5px',
    })
    let list = this.props.list.get('selectedNodes')
    if (list.size === 0) {
      list = this.props.list.get('selectedChildren')
    }

    /* eslint-disable no-nested-ternary */
    const maxLabel = this.props.width < 120 ? 3 : this.props.width < 170 ? 4 : 6
    /* eslint-enable no-nested-ternary */

    return (
      <div style={reviewStyle} className="filter-button">
        <Button ref="container" onClick={this.handleOnClick.bind(this)} block>
          <div>
            <ButtonLabelList
              list={list}
              type={this.props.type}
              maxLabel={maxLabel}
              defaultLabel={this.props.defaultLabel}
              width={this.props.width}
            />
          </div>
        </Button>
      </div>
    )
  }
}

ButtonHierarchyFilter.propTypes = {
  lang: PropTypes.string,
  list: ImmutablePropTypes.contains({
    selectedNodes: ImmutablePropTypes.set.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
