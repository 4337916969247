import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

import { refreshAllCardsAction } from './'

/**
 * Switch views in the dashboard show page.
 */
export function goToView(dashboardId) {
  return (dispatch) => {
    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS',
    })

    dispatch({
      type: 'DASHBOARDS:CURRENT_GRID:GO_TO_VIEW',
    })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    request
      .get(
        `${config.server.url}dashboard/${dashboardId}/card?auth_token=${token}`,
        {},
        { responseType: 'json' }
      )

      .then((result) => {
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:REFRESH_GRID',
          grid: result.response.grid || { cols: 6, rows: 6 },
          filters: result.response.filters,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS:SUCCESS',
          cards: result.response.items,
        })
        dispatch({
          type: 'DASHBOARDS:CURRENT_CARD_RECALC_USED_CELLS',
        })
        refreshAllCardsAction()(dispatch)
      })

      .catch((result) => {
        return dispatch({
          type: 'DASHBOARDS:CURRENT_GRID:FETCH_CARDS:FAIL',
          ...result.response,
        })
      })
  }
}
