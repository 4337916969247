import Immutable from 'immutable'
import { store } from 'services/store'

/**
 * Given a page id, fetches the page.
 */
export function getPageById(id) {
  return store
    .getState()
    .masterdata.get('pages')
    .find((item) => item.get('key') === id, null, Immutable.Map())
    .toJS()
}
