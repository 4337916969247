/**
 * Called when a card remove has succeeded.
 */
export default (state, action) => {
  const cardId = action.cardId

  const result = state.get('cards').findEntry((item) => {
    return item.get('id') === cardId
  })
  if (!result || result[0] < 0) {
    return state
  }
  const [index] = result
  return state
    .set('cards', state.get('cards').delete(index))
    .update('prog', (value) => value + 1)
}
