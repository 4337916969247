import React from 'react'

import LineChart from 'components/widgets/charts/lineChart'
import ColumnChart from 'components/widgets/charts/multiBarChart'
import TextCard from 'components/widgets/charts/text'
import HorizontalBarChart from 'components/widgets/charts/horizontalBarChart'
import TreemapChart from 'components/widgets/charts/treemapChart'
import TableChart from 'components/widgets/charts/tableChart'
import HtmlTextEdit from '../htmlTextEdit'
import Card from './card'
import BackgroundPanel from './backgroundPanel'
import CardTitleEdit from './cardTitleEdit'

const components = {
  LineChart,
  TextCard,
  ColumnChart,
  BarChart: HorizontalBarChart,
  TreemapChart,
  TableChart,
}

export class Container extends React.Component {
  render() {
    const backgroundGrid = Object.assign({}, this.props.grid)
    const rowsCount = Math.min(
      this.props.grid.rows,
      Math.max(this.props.usedRows, 7) + 1
    )
    const containerStyle = {
      width: `${this.props.grid.width}px`,
      height: `${
        Math.round(
          (this.props.grid.height / this.props.grid.rows) * rowsCount
        ) + 10
      }px`,
      position: 'relative',
      boxSizing: 'border-box',
      margin: '0 auto',
    }

    return (
      <div style={containerStyle}>
        {this.props.cards.map((card) => {
          card = card.toJS()
          const paddingLeft = 5
          const paddingRight = 5
          const paddingTop = 5
          const paddingBottom = 5
          let headerHeight = 20
          const cellWidth = Math.round(
            this.props.grid.width / this.props.grid.cols
          )
          const cellHeight = Math.round(
            this.props.grid.height / this.props.grid.rows
          )

          let content

          if (!card.isInit || card.isFetching) {
            const loaderStyle = {
              width: card.spanX * cellWidth - (paddingLeft + paddingRight),
              textAlign: 'center',
              marginTop: `${
                (card.spanY * cellHeight -
                  (paddingTop + paddingBottom + headerHeight)) /
                  2 -
                20
              }px`,
            }
            content = <div style={loaderStyle}>Loading</div>
          } else if (card.hasErrored) {
            const loaderStyle = {
              color: '#ff0000',
              width: card.spanX * cellWidth - (paddingLeft + paddingRight),
              textAlign: 'center',
              marginTop: `${
                (card.spanY * cellHeight -
                  (paddingTop + paddingBottom + headerHeight)) /
                  2 -
                20
              }px`,
            }
            content = <div style={loaderStyle}>Error loading chart</div>
          } else {
            const CurrentComponent = components[card.content.type]

            const filterHeight = 30
            content = (
              <CurrentComponent
                id={`chart_${card.id}`}
                key={`card_component_${card.id}`}
                width={card.spanX * cellWidth - (paddingLeft + paddingRight)}
                height={
                  card.spanY * cellHeight -
                  (paddingTop + paddingBottom + headerHeight + filterHeight)
                }
                {...card.content}
              />
            )
          }
          if (card.content.type === 'TextCard') {
            headerHeight = 20
          }

          return (
            <Card
              id={`card_${card.id}`}
              key={`card_${card.id}`}
              positionX={card.cellX * cellWidth}
              positionY={card.cellY * cellHeight}
              width={card.spanX * cellWidth}
              height={card.spanY * cellHeight}
              title={card.title}
              paddingLeft={paddingLeft}
              paddingRight={paddingRight}
              paddingTop={paddingTop}
              paddingBottom={paddingBottom}
              headerHeight={headerHeight}
              onDragStop={this.props.onDragStop.bind(this, card.id)}
              onResizeStop={this.props.onResizeStop.bind(this, card.id)}
              onRemoveCard={this.props.onRemoveCard.bind(this, card.id)}
              onEditCard={this.props.onEditCard.bind(this, card.id)}
              cardFilterComponent={this.props.cardFilterComponent}
              card={card}
            >
              {content}
            </Card>
          )
        })}
        <BackgroundPanel
          {...backgroundGrid}
          usedRows={this.props.usedRows}
          onAddClick={this.props.onAddClick}
        />
        <HtmlTextEdit
          onClose={this.props.onEditCardOk}
          onCancel={this.props.onEditCardCancel}
          onTextChange={this.props.onEditCardTextChange}
        />
        <CardTitleEdit
          onClose={this.props.onEditCardTitleOk}
          onCancel={this.props.onEditCardCancel}
          onTextChange={this.props.onEditCardTextChange}
        />
      </div>
    )
  }
}

export default Container
