import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Button, ButtonGroup } from 'react-bootstrap'
import Hierarchy from 'components/widgets/hierarchy'
import registry from 'services/registry'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
export class FilterModal extends React.Component {
  handleOnClose() {
    this.props.onClose()
  }

  handleOnCancel() {
    this.props.onCancel()
  }

  render() {
    const getLabel = registry.get('helpers/masterdata/getLabel')
    const type = this.props.type
    const title = this.props.title
    const isRecursive = this.props.isRecursive

    const items = this.props.masterdata.getIn(['hierarchies', type]).toJS().tree

    return (
      <Modal
        dialogClassName={`hierarchy-panel`}
        show={true}
        onHide={this.handleOnCancel.bind(this)}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Hierarchy
            type={type}
            items={items}
            label={getLabel.bind(null, type)}
            isRecursive={isRecursive}
            enabledNodes={this.props.enabledNodes}
            side={this.props.side || 'both'}
            openedNodes={
              this.props.hierarchy.get(type) || new Immutable.List([])
            }
          />
        </Modal.Body>

        <Modal.Footer>
          <ButtonGroup>
            <Button
              size="sm"
              variant="warning"
              onClick={this.handleOnCancel.bind(this)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={this.handleOnClose.bind(this)}
            >
              Update
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    )
  }
}

FilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  masterdata: PropTypes.object.isRequired,
  hierarchy: ImmutablePropTypes.map.isRequired,
  enabledNodes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isRecursive: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    masterdata: state.masterdata,
    hierarchy: state.filters.hierarchy,
  }
}

export default connect(mapStateToProps, null)(FilterModal)
