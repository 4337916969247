import { connect } from 'react-redux'
import Immutable from 'immutable'
import { toggleItemAction } from '../../actions'

import _component from './component'

function mapStateToProps(state) {
  return {
    // TODO Get the current page from the reducer
    currentPage: 0,
    pages: state.masterdata.get('pages').toJS(),
    itemOpened: state.masterdata.get('infitemOpened') || Immutable.Set(),
    isFetching: state.masterdata.get('isFetching'),
    isInit: state.masterdata.get('isInit'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleItemClick: (type, id) => dispatch(toggleItemAction(type, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
