import React from 'react'
import { Container, Row, Col, Jumbotron } from 'react-bootstrap'

class NotFound extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Jumbotron>
              <h1>Not Found</h1>
              <p>
                Uh oh. Looks like the page you tried to visit does not exist.
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default NotFound
