/**
 *
 */
export function toggleNodeAction(itemId, side) {
  return (dispatch) => {
    dispatch({
      type: 'FILTERS:HIERARCHY:NODES:TOGGLE',
      side,
      itemId,
    })
  }
}
