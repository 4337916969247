import { store } from 'services/store'

/**
 * Gets an information item by key.
 */
export function getInformationItemsByKey(key) {
  return store
    .getState()
    .masterdata.get('informationItems')
    .filter((item) => String(item.key) === String(key))
}
