import { getCardAndIndexFromCardList } from './../../helpers'

/**
 * Called when a card's size has been set successfully.
 */
export default (state, action) => {
  const cardId = action.cardId

  const cardFound = getCardAndIndexFromCardList(state.get('cards'), cardId)
  if (cardFound.index < 0) {
    return state
  }
  const { index, card } = cardFound

  return state
    .setIn(
      ['cards', index],
      card.set('spanX', action.spanX).set('spanY', action.spanY)
    )
    .update('prog', (value) => value + 1)
    .set('isUpdating', false)
}
