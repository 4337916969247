/**
 * Initialize the user form.
 */
export function initFormAction() {
  return (dispatch) => {
    dispatch({
      type: 'USER:INIT_FORM',
    })
  }
}
