;(function () {
  // Union of Chrome, Firefox, IE, Opera, and Safari console methods
  const methods = [
    'assert',
    'cd',
    'clear',
    'count',
    'countReset',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'select',
    'table',
    'time',
    'timeEnd',
    'timeStamp',
    'timeline',
    'timelineEnd',
    'trace',
    'warn',
  ]
  let length = methods.length
  const console = (window.console = window.console || {})
  let method
  const noop = function () {}
  while (length--) {
    method = methods[length]
    // define undefined methods as noops to prevent errors
    if (!console[method]) {
      console[method] = noop
    }
  }
})()
