import React from 'react'
import { Container, Col, Row, Button, ButtonGroup, Form } from 'react-bootstrap'

import Spinner from 'components/widgets/spinner'
import ErrorBlock from 'components/widgets/errorBlock'

export class DashboardCreateNew extends React.Component {
  componentWillMount() {
    this.props.initForm()
  }

  handleCancel(event) {
    event.preventDefault()

    this.props.navigateToDashboardsList()
  }

  handleFormSubmit(event) {
    event.preventDefault()

    const page = this.refs.page.value
    const title = this.refs.title.value
    const description = this.refs.description.value

    this.props.createEntry({
      page,
      title,
      description,
    })
  }

  render() {
    let pageAttributes
    let titleAttributes
    let descriptionAttributes
    let errorMessage

    const pageOptions = this.props.pages.map((page) => (
      <option key={page.key} value={page.key}>
        {page.title}
      </option>
    ))

    if (!this.props.isSaving) {
      pageAttributes = {
        variant: this.props.formErrors.page ? 'error' : null,
        help: this.props.formErrors.page,
        autoFocus: true,
      }

      titleAttributes = {
        variant: this.props.formErrors.title ? 'error' : null,
        help: this.props.formErrors.title,
      }

      descriptionAttributes = {
        variant: this.props.formErrors.description ? 'error' : null,
        help: this.props.formErrors.description,
      }

      errorMessage = this.props.formErrors.generic
    }

    return (
      <Container>
        <Row>
          <Col md={12} mdOffset={6}>
            <form onSubmit={this.handleFormSubmit.bind(this)}>
              <Form.Group>
                <Form.Label>Page</Form.Label>
                <Form.Control
                  ref="page"
                  as="select"
                  label="Page"
                  placeholder="Select a Page"
                  {...pageAttributes}
                  disabled={this.props.isSaving}
                >
                  {pageOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  ref="title"
                  type="text"
                  placeholder="Title"
                  {...titleAttributes}
                  disabled={this.props.isSaving}
                />
                {titleAttributes && titleAttributes.help && (
                  <Form.Text id="titleHelpBlock" muted>
                    {titleAttributes.help}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  ref="description"
                  as="textarea"
                  placeholder="Description"
                  rows="5"
                  {...descriptionAttributes}
                  disabled={this.props.isSaving}
                />
                {descriptionAttributes && descriptionAttributes.help && (
                  <Form.Text id="descriptionHelpBlock" muted>
                    {descriptionAttributes.help}
                  </Form.Text>
                )}
              </Form.Group>

              <ErrorBlock message={errorMessage} />

              <div className="pull-right">
                <ButtonGroup>
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={this.handleCancel.bind(this)}
                    disabled={this.props.isSaving}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="primary"
                    type="submit"
                    disabled={this.props.isSaving}
                  >
                    Add a new dashboard
                  </Button>
                </ButtonGroup>
              </div>
              <Spinner show={this.props.isSaving} />
            </form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default DashboardCreateNew
