import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

function isPresent(value) {
  return value !== null && value !== undefined && value !== ''
}

function getFormErrors({ title, description }) {
  let titleError
  let descriptionError

  if (!isPresent(title)) {
    titleError = 'Title may not be empty.'
  } else if (title.length > 50) {
    titleError = 'Title may not be longer then 50 characters.'
  }

  if (!isPresent(description)) {
    descriptionError = 'Description may not be empty.'
  }

  if (titleError || descriptionError) {
    return {
      title: titleError,
      description: descriptionError,
    }
  }
}

/**
 * Updates a dashboard with new data to the API.
 */
export function updateAction(id) {
  return (dispatch) => {
    const content = store.getState().textEditor.get('content')
    dispatch({
      type: 'TEXT_EDITOR:ON_SAVING',
    })

    const errors = getFormErrors(content)

    if (errors) {
      dispatch({
        type: 'TEXT_EDITOR:ON_SAVING:FAILED',
        errors,
      })
      return
    }

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')

    const url = `${config.server.url}dashboard/${id}?auth_token=${token}`

    request
      .put(url, content, { responseType: 'json' })
      .then(() => {
        dispatch({
          type: 'DASHBOARDS:UPDATE_DETAILS:SUCCESS',
          id,
          data: content,
        })
        dispatch({
          type: 'TEXT_EDITOR:ON_SAVING:SUCCESS',
        })
        dispatch({
          type: 'TEXT_EDITOR:CLOSE',
        })
      })
      .catch((result) =>
        dispatch({
          type: 'TEXT_EDITOR:ON_SAVING:FAILED',
          errors: result.response,
        })
      )
  }
}
