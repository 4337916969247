import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { ShareIcon } from '@primer/octicons-react'

export class DropDownShare extends React.Component {
  render() {
    if (!this.props.show) {
      return false
    }

    const shareItems = []
    if (!this.props.isShared) {
      shareItems.push(
        <Dropdown.Item key="1" eventKey="1" onClick={this.props.onPublish}>
          Publish
        </Dropdown.Item>
      )
    } else {
      shareItems.push(
        <Dropdown.Item key="2" eventKey="2" onClick={this.props.onPublish}>
          Republish
        </Dropdown.Item>
      )
      shareItems.push(
        <Dropdown.Item key="3" eventKey="3" onClick={this.props.onShowLink}>
          Show link
        </Dropdown.Item>
      )
      shareItems.push(<Dropdown.Item key="4" divider />)
      shareItems.push(
        <Dropdown.Item key="5" eventKey="4" onClick={this.props.onUnpublish}>
          Unpublish
        </Dropdown.Item>
      )
    }
    return (
      <Dropdown id="share" size="sm">
        <Dropdown.Toggle>
          <ShareIcon /> Share
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          {shareItems.filter((item) => item !== null)}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default DropDownShare
