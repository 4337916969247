import config from 'config'
import request from 'services/request'
import { store } from 'services/store'

/**
 * Publishes a dashboard.
 */
export function publishAction(id) {
  return (dispatch) => {
    dispatch({ type: 'DASHBOARDS:PUBLISH' })

    const user = store.getState().user
    const token = user.get('loggedInUser').get('token')
    const url = `${config.server.url}dashboard/${id}/publish/?auth_token=${token}`

    request
      .post(url, { responseType: 'json' })
      .then((result) => {
        dispatch({
          type: 'DASHBOARDS:PUBLISH:SUCCESS',
          share: result.response,
        })
      })

      .catch((error) => {
        return dispatch({
          type: 'DASHBOARDS:PUBLISH:FAIL',
          ...error.response,
        })
      })
  }
}
