import config from 'config'
import request from 'services/request'
import { store } from 'services/store'
import { refreshAllCardsAction } from '../grid'

/**
 * Saves and closes the current grid.
 */
export function closeAndSaveAction(panelId, type, writeOnUpdate) {
  return (dispatch) => {
    if (type === 'range') {
      dispatch({
        type: 'DASHBOARDS:CURRENT_GRID:UPDATE_RANGE_FILTERS',
        filters: store.getState().filters.range,
      })
    } else {
      dispatch({
        type: 'DASHBOARDS:CURRENT_GRID:UPDATE_FILTERS',
        name: panelId,
        filters: store.getState().filters.hierarchy,
      })
    }
    dispatch({ type: 'DASHBOARD:GLOBAL_FILTERS:SHOW', show: null })

    if (writeOnUpdate) {
      const data = store.getState().dashboard.currentGrid.get('filters').toJS()
      const dashboardId = store
        .getState()
        .dashboard.current.getIn(['dashboard', 'id'])

      const user = store.getState().user
      const token = user.get('loggedInUser').get('token')
      const url = `${config.server.url}dashboard/${dashboardId}/filters?auth_token=${token}`

      request
        .put(url, data, { responseType: 'json', dataType: 'json' })
        .then(() => {})
        .catch((result) => {
          return dispatch({
            type: 'DASHBOARDS:UPDATE:FAIL',
            ...result.response,
          })
        })
    }
    dispatch(refreshAllCardsAction())
  }
}
