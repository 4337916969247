import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import { DashboardList } from 'modules/dashboard/components'
import { useHistory } from 'react-router'

function mapStateToProps(state) {
  return {
    dashboardStore: state.dashboard,
  }
}

const DashboardListPage = (props) => {
  const history = useHistory()

  const handleAddButtonClick = (event) => {
    event.preventDefault()

    history.push('/dashboards-new')
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <DashboardList />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Button
            // onClick={this.handleAddButtonClick.bind(this)}
            onClick={handleAddButtonClick}
            variant="primary"
            size="sm"
          >
            Add a dashboard
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default connect(mapStateToProps)(DashboardListPage)
