import { getChildIdList } from 'modules/masterdata/helpers'
import { store } from '../../../services/store'

/**
 * Toggles the children collapsed state for the children in the filters.
 */
export function toggleChildAction(type, itemId, side) {
  const startItem = store
    .getState()
    .masterdata.getIn(['hierarchiesFlat', type])
    .find((item) => itemId === item.id)
  return (dispatch) => {
    dispatch({
      type: 'FILTERS:HIERARCHY:CHILDREN:TOGGLE',
      itemId,
      side,
      children: getChildIdList(startItem),
    })
  }
}
