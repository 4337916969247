import React from 'react'

class TextCard extends React.Component {
  render() {
    const containerStyle = {
      width: this.props.width,
      height: this.props.height,
    }

    return (
      <div id={this.props.id} style={containerStyle}>
        <div dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
      </div>
    )
  }
}
export default TextCard
