import { connect } from 'react-redux'

import _component from './component'

function mapStateToProps(state) {
  return {
    isFetching: state.dashboard.fact.get('isFetching'),
    isSharedInfoOpened: state.dashboard.fact.get('isSharedInfoOpened'),
    isRetrievingSharedKey: state.dashboard.fact.get('isRetrievingSharedKey'),
    shareKey: state.dashboard.fact.get('shareKey'),
    chartData: state.dashboard.fact.get('chartData').toJS(),
    filtersStruct: state.masterdata.get('filters'),
    factFilters: state.dashboard.fact.get('filters'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    shareInfoOpen: (id) =>
      dispatch({ type: 'DASHBOARDS:FACT:SHARE:START', chartId: id }),
    shareInfoClose: () => dispatch({ type: 'DASHBOARDS:FACT:SHARE:CLOSE' }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
