import Immutable from 'immutable'

const defaultState = Immutable.Map({
  isSaving: false,
  formErrors: Immutable.Map(),
})

/**
 * Called when the dashboard has been created.
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DASHBOARDS:INIT_FORM':
      return state.set('isSaving', false).set('formErrors', Immutable.Map())

    case 'DASHBOARDS:CREATE_START':
      return state.set('isSaving', true)

    case 'DASHBOARDS:CREATE:SUCCESS':
      return state.set('isSaving', false).set('formErrors', Immutable.Map())

    case 'DASHBOARDS:CREATE:FAIL':
      return state
        .set('isSaving', false)
        .set('formErrors', Immutable.Map(action.errors))

    default:
      return state
  }
}
