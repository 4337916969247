import Immutable from 'immutable'
import { getFlatHierarchy, getLabelsFromList } from './helpers'

const defaultState = Immutable.Map({
  isFetching: false,
  isInit: false,
  infitemOpened: Immutable.Set(),
  // constrd: null,
  // geoloc: null,
  infitem: null,
  currency: null,
  chart: null,
  informationItems: null,
  pages: Immutable.List(),
  labels: Immutable.Map(),
})

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'MASTERDATA:INIT_START':
      return state.set('isFetching', true)

    case 'MASTERDATA:INIT:SUCCESS':
      const list = {}
      // getNodeLabelsFromTree(action.items.constrdHierarchy.tree, list, 'constrd')

      // getLabelsFromList(action.items.constrd, list, 'constrd')
      // getLabelsFromList(action.items.geoloc, list, 'geoloc')
      // getLabelsFromList(action.items.currency, list, 'currency')
      // getLabelsFromList(action.items.chart, list, 'chart')
      // getLabelsFromList(action.items.infitem, list, 'infitem')

      for (let [key, value] of Object.entries(action.items.labels)) {
        getLabelsFromList(value, list, key)
      }
      const labels = Immutable.Map(list)

      const hierarchiesFlat = Immutable.Map(
        Object.entries(action.items.hierarchies).reduce((acc, [key, value]) => {
          acc[key] = getFlatHierarchy(value.tree)
          return acc
        }, {})
      )

      return state
        .set('isFetching', false)
        .set('isInit', true)
        .set('infitem', action.items.infitem)
        .set('currency', action.items.currency)
        .set('chart', action.items.chart)
        .set('informationItems', action.items.informationItems)
        .set('pages', Immutable.fromJS(action.items.pages))
        .set('hierarchies', Immutable.fromJS(action.items.hierarchies))
        .set('hierarchiesFlat', hierarchiesFlat)
        .set('labels', labels)
        .set('filters', [
          {
            name: 'hum_promo_relevant',
            type: 'nonRecursive',
            shortTitle: 'Promo relevant',
            modalTitle: 'Promo relevant',
          },
          {
            name: 'hum_brand_preference',
            type: 'nonRecursive',
            shortTitle: 'Brand preference',
            modalTitle: 'Brand preference',
          },
          {
            name: 'hum_color_relevant',
            type: 'nonRecursive',
            shortTitle: 'Color relevant',
            modalTitle: 'Color relevant',
          },
          {
            name: 'hum_color_preference',
            type: 'nonRecursive',
            shortTitle: 'Color preference',
            modalTitle: 'Color preference',
          },
          {
            name: 'constrd',
            type: 'nonRecursive',
            shortTitle: 'Construction domains',
            modalTitle: 'Construction domain',
          },
          {
            name: 'geoloc',
            type: 'recursive',
            shortTitle: 'Geographics',
            modalTitle: 'Geoloc list',
          },
        ])

    case 'MASTERDATA:INIT:FAIL':
      return state.set('isFetching', false)

    case 'MASTERDATA:TOGGLE':
      const key = action.value.key.toLowerCase()
      const value = action.value.value
      let localState = state
      if (!localState.get(`${key}Opened`)) {
        localState = localState.set(`${key}Opened`, Immutable.Set())
      }

      if (key === 'infitem') {
        const infitemOpened = localState.get('infitemOpened')
        if (infitemOpened.has(value)) {
          return localState.set('infitemOpened', infitemOpened.delete(value))
        }
        return localState.set('infitemOpened', infitemOpened.add(value))
      } else {
        if (localState.get(`${key}Opened`).has(value)) {
          return localState.set(
            `${key}Opened`,
            localState.get(`${key}Opened`).delete(value)
          )
        }
        return localState.set(
          `${key}Opened`,
          localState.get(`${key}Opened`).set(value)
        )
      }

    default:
      return state
  }
}
