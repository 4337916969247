import { connect } from 'react-redux'
import { store } from '../../../../store'

import { initFormAction, createAction } from '../../actions'

import _component from './component'

function mapStateToProps(state) {
  return {
    pages: state.masterdata.get('pages').toJS(),
    isSaving: state.dashboard.create.get('isSaving'),
    formErrors: state.dashboard.create.get('formErrors').toJS(),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    initForm: () => dispatch(initFormAction()),
    createEntry: (data) => dispatch(createAction(data)),
    // navigateToDashboardsList: () => dispatch(routeActions.push(`/dashboards`, {}))
    navigateToDashboardsList: () => store.history.push(`/dashboards`),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(_component)
